import React from "react";
import { useHistory } from "react-router-dom";

// Custom styles
import "../scss/pages/404.styles.scss";

function NotFound() {
  const history = useHistory();

  return (
    <section className="not-found__wrapper">
      <div className="not-found__container">
        <h4>Por el momento esta página no está disponible</h4>
        <p>
          Lamentamos los inconvenientes que esto puede causar. Te invitamos a
          seguir explorando nuestros productos en el catálogo y a contactarnos
          al <a href="tel:+525536402250">(55)36 402 250</a> si quieres realizar
          una compra con los productos que deseas.
        </p>
        <button
          onClick={() => {
            history.goBack();
          }}
        >
          Regresar
        </button>
      </div>
    </section>
  );
}

export default NotFound;
