import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

// Custom styles
import "../scss/pages/home.styles.scss";

// API
import {
  getSeasonProducts,
  getBestSellingProducts,
  getAccessoriesProducts,
} from "../api/product";
import { getPopUpContent } from "../api/pop-up";

// Custom components
import ProductCard from "../components/ProductCard/ProductCard";
import Slider from "react-slick";

// Utils
import formatMoney from "../utils/formatMoney";

// Assets
import presentIcon from "../assets/img/present-icon.svg";
// import shoppingBagIcon from "../assets/img/shopping-bag-icon.svg";
// import aboutUs from "../assets/img/about-us.jpg";

// Slick Carousel CSS files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {
  const [loading, setLoading] = useState(false);
  const [seasonalPromotions, setSeasonalPromotions] = useState([]);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [accessoriesProducts, setAccessoriesProducts] = useState([]);
  const [popUp, setPopUp] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getSeasonProducts();
      if (response.data) {
        setSeasonalPromotions(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getBestSellingProducts();
      if (response.data) {
        setBestSellingProducts(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getAccessoriesProducts();
      if (response.data) {
        setAccessoriesProducts(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getPopUpContent();
      if (response.data) {
        setPopUp(response.data);
      } else {
        setPopUp({});
      }
    })();
  }, []);

  useEffect(() => {
    console.log(Object.keys(popUp).length);
    if (!loading && Object.keys(popUp).length !== 0) {
      setTimeout(() => {
        setShow(true);
      }, 5000);
    }
  }, [loading]);

  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsBestSeller = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsAccessories = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="home__wrapper">
      <div className="home__hero__wrapper">
        <div className="home__hero">
          <h1>creador de tés y tisanas</h1>
          <div className="home__hero__br"></div>
          <h2>paris</h2>
          <Link to="/catalogue/tes">Conócenos</Link>
        </div>
      </div>
      <div className="season-promotions">
        <Container fluid>
          <div className="season-promotions__header">
            <h3>promociones de temporada</h3>
            <div className="season-promotions__header__br"></div>
          </div>
          <div className="season-promotions__products">
            {loading ? (
              <p>Cargando productos...</p>
            ) : (
              <Slider {...settings}>
                {seasonalPromotions?.map((product, key) => (
                  <ProductCard key={key} product={product} />
                ))}
                <div className="season-promotions__products__all">
                  <Link to="/catalogue/tes">ver más</Link>
                </div>
              </Slider>
            )}
            {/* <Row xs={12} md={12} lg={4} xl={4} xxl={6} className="g-3">
              {seasonalPromotions.map((product, key) => (
                <ProductCard key={key} product={product} />
              ))}
            </Row> */}
          </div>
        </Container>
      </div>
      <div className="about-us">
        <Container>
          <Row>
            <Col xs="12" lg="6" className="about-us__media"></Col>
            <Col
              xs="12"
              lg="6"
              className="d-flex flex-column justify-content-center pt-3 pb-3 p-lg-0 ps-lg-5"
            >
              <h2>DAMMANN EN TU CASA</h2>
              <p>
                Disfruta de la gran variedad de sabores de Dammann Frères, no
                sólo en tus restaurantes y hoteles favoritos, si no en la
                comodidad de tu casa.
              </p>
              <p>
                Degusta cada día un nuevo sabor con la variedad que ofrecen
                nuestros cofres.
              </p>
              <Link to="/catalogue/accesorios">Ver todos los accesorios</Link>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="best-seller">
        <Container fluid>
          <div className="best-seller__header">
            <h3>sabores más vendidos</h3>
            <div className="best-seller__header__br"></div>
          </div>
          <div className="best-seller__products">
            {loading ? (
              <p>Cargando productos...</p>
            ) : (
              <Slider {...settings}>
                {bestSellingProducts?.map((product, key) => (
                  <Col key={key} className="h-100">
                    <div className="product-card">
                      <Link
                        to={`/product/${product.attributes.producto.data.attributes.slug}`}
                        key={key}
                      >
                        <div className="product-card__media">
                          <Slider {...settingsBestSeller}>
                            {product.attributes?.producto.data.attributes.imagenes.data?.map(
                              (image, key) => (
                                // <img
                                //   key={key}
                                //   src={image.attributes?.url}
                                //   // src={`${process.env.REACT_APP_API_URL}${image.attributes?.url}`}
                                //   alt={product.attributes?.nombre}
                                // />
                                <img
                                  key={key}
                                  src={image.attributes?.url}
                                  // src={`${product.attributes?.imagenes.data.attributes.url}`}
                                  alt={image.attributes?.nombre}
                                />
                              )
                            )}
                          </Slider>
                          {/* <img
                            // src={
                            //   product.attributes?.producto.data.attributes
                            //     .imagenes.data[0].attributes.url
                            // }
                            src={`${product.attributes?.producto.data.attributes.imagenes.data[0].attributes.url}`}
                            alt={
                              product.attributes?.producto.data.attributes
                                .nombre
                            }
                          /> */}
                        </div>
                        <div className="product-card__body">
                          <h5 className="product-card__body__product-name">
                            {
                              product.attributes?.producto.data.attributes
                                .nombre
                            }
                          </h5>
                          <p className="product-card__body__product-description">
                            {
                              product.attributes?.producto.data.attributes
                                .descripcion
                            }
                          </p>
                          {product.attributes?.producto.data.attributes
                            .tipoProducto !== "Accesorios" &&
                          product.attributes?.producto.data.attributes
                            .InformacionTe !== null &&
                          product.attributes?.producto.data.attributes
                            .InformacionTe.NotasDominantes !== null &&
                          product.attributes?.producto.data.attributes
                            .InformacionTe.SaborPrincipal !== null ? (
                            <div className="product-card__body__product-details">
                              <p>
                                <span>Notas dominantes:</span>
                                {
                                  product.attributes?.producto.data.attributes
                                    .InformacionTe.NotasDominantes
                                }
                              </p>
                              <p>
                                <span>
                                  {product.attributes?.producto.data.attributes
                                    .tipoProducto !== "Té"
                                    ? "Tipo de tisana:"
                                    : "Tipo de té:"}
                                </span>
                                {
                                  product.attributes?.producto.data.attributes
                                    .subcategoria.data.attributes.nombre
                                }
                              </p>
                              <p>
                                <span>Sabor principal:</span>
                                {
                                  product.attributes?.producto.data.attributes
                                    .InformacionTe.SaborPrincipal
                                }
                              </p>
                            </div>
                          ) : null}
                          {/* <div className="product-card__body__product-price">
                            <p>
                              {formatMoney(
                                product.attributes?.producto.data.attributes
                                  .PresentacionesDisponibles[0].precio
                              )}
                              <span>24 sachets</span>
                            </p>
                          </div> */}
                        </div>
                        <div className="product-card__cta mt-lg-3">
                          {/* <button
                            onClick={() => console.log("Yo this was clicked")}
                          >
                          <span>Añadir a la bolsa </span>
                          <img
                              src={shoppingBagIcon}
                              alt="Añadir a la bolsa"
                            />
                            </button>
                            <button
                            onClick={() => console.log("Yo this was clicked")}
                            >
                            <span> Agregar a mi lista </span>
                            <img src={favoriteIcon} alt="Agregar a mi lista" />
                          </button> */}
                          <p className="product-card__cta__toProduct">
                            Ver producto
                          </p>
                        </div>
                      </Link>
                    </div>
                  </Col>
                ))}
                <div className="season-promotions__products__all">
                  <Link to="/catalogue/tisanas">ver más</Link>
                </div>
              </Slider>
            )}
          </div>
        </Container>
      </div>
      <div id="accesories" className="accessories">
        <Container fluid>
          <div className="accessories__header">
            <h3>accesorios</h3>
            <div className="accessories__header__br"></div>
          </div>
          <div className="accessories__products">
            {loading ? (
              <p>Cargando productos...</p>
            ) : (
              <Slider {...settings}>
                {accessoriesProducts?.map((product, key) => (
                  <Col key={key} className="h-100">
                    <div className="product-card">
                      {product.attributes?.descuento > 0 ? (
                        // <span className="product-card__discount-label">
                        //   producto en descuento
                        // </span>
                        <></>
                      ) : (
                        <></>
                      )}
                      <Link
                        to={`/product/${product.attributes.slug}`}
                        key={key}
                      >
                        <div className="product-card__media">
                          <Slider {...settingsAccessories}>
                            {product.attributes?.imagenes.data?.map(
                              (image, key) => (
                                // <img
                                //   key={key}
                                //   src={image.attributes?.url}
                                //   // src={`${process.env.REACT_APP_API_URL}${image.attributes?.url}`}
                                //   alt={product.attributes?.nombre}
                                // />
                                <img
                                  key={key}
                                  src={image.attributes?.url}
                                  // src={`${product.attributes?.imagenes.data.attributes.url}`}
                                  alt={image.attributes?.nombre}
                                />
                              )
                            )}
                          </Slider>
                        </div>
                        <div className="product-card__body">
                          <div className="accessory-border"></div>
                          {/* {product.attributes?.tipoProducto === "Accesorio" ? (
                        ) : null} */}
                          <h5 className="product-card__body__product-name">
                            {product.attributes?.nombre}
                          </h5>
                          <p className="product-card__body__product-description">
                            {product.attributes?.descripcion}
                          </p>
                          {/* <div className="product-card__body__product-price">
                            {product.attributes?.descuento > 0 ? (
                              <div className="product-card__body__product-price-prices">
                                <div className="d-flex">
                                  <p style={{ textDecoration: "line-through" }}>
                                    {formatMoney(
                                      product.attributes
                                        ?.PresentacionesDisponibles[0].precio
                                    )}{" "}
                                  </p>
                                  <span>{`- ${
                                    product.attributes
                                      ?.PresentacionesDisponibles[
                                      product.attributes?.PresentacionesDisponibles.findIndex(
                                        (presentation) =>
                                          presentation.Presentacion ===
                                          "Accesorio"
                                      )
                                    ]?.descuento
                                  } % descuento`}</span>
                                </div>

                                <p className="mt-lg-2">
                                  {formatMoney(
                                    product.attributes
                                      ?.PresentacionesDisponibles[0].precio -
                                      (product.attributes
                                        ?.PresentacionesDisponibles[
                                        product.attributes?.PresentacionesDisponibles.findIndex(
                                          (presentation) =>
                                            presentation.Presentacion ===
                                            "Accesorio"
                                        )
                                      ]?.precio *
                                        product.attributes
                                          ?.PresentacionesDisponibles[
                                          product.attributes?.PresentacionesDisponibles.findIndex(
                                            (presentation) =>
                                              presentation.Presentacion ===
                                              "Accesorio"
                                          )
                                        ]?.descuento) /
                                        100
                                  )}{" "}
                                </p>
                              </div>
                            ) : (
                              <p>
                                {formatMoney(
                                  product.attributes
                                    ?.PresentacionesDisponibles[0].precio -
                                    (product.attributes
                                      ?.PresentacionesDisponibles[0].precio *
                                      product.attributes.descuento) /
                                      100
                                )}{" "}
                              </p>
                            )}
                          </div> */}
                        </div>
                        <div className="product-card__cta">
                          {/* <button
                            onClick={() => console.log("Yo this was clicked")}
                          >
                            <span>Añadir a la bolsa </span>
                            <img
                              src={shoppingBagIcon}
                              alt="Añadir a la bolsa"
                            />
                          </button>
                          <button
                            onClick={() => console.log("Yo this was clicked")}
                          >
                            <span> Agregar a mi lista </span>
                            <img src={favoriteIcon} alt="Agregar a mi lista" />
                          </button> */}
                          <p className="product-card__cta__toProduct">
                            Ver producto
                          </p>
                        </div>
                      </Link>
                    </div>
                  </Col>
                ))}
                <div className="season-promotions__products__all">
                  <Link to="/catalogue/accesorios">ver más</Link>
                </div>
              </Slider>
            )}
          </div>
        </Container>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="g-0 h-md-100">
            <Col lg="6">
              <div
                className="modal-body__media"
                style={{
                  backgroundImage: `url(${popUp?.attributes?.imagen.data.attributes.url})`,
                }}
              ></div>
            </Col>
            <Col lg="6">
              <div className="modal-body__content">
                <img src={presentIcon} alt="Dammann" />
                <h3>{popUp?.attributes?.titulo}</h3>
                <p>{popUp?.attributes?.descripcion}</p>
                <Link to={`/${popUp?.attributes?.link}`}>
                  {popUp?.attributes?.tituloBoton}
                </Link>
                <span onClick={() => setShow(false)}>No, gracias</span>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Home;
