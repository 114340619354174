import { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

// Stripe
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

// API
import { createOrder, createAuthOrder } from "../../api/order";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// Context
import AlertContext from "../../contexts/AlertContext";

// Utils
import formatMoney from "../../utils/formatMoney";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

const initialValues = {
  clientName: "",
  // lastName: "",
  clientEmail: "",
  phoneNumber: "",
  address: "",
  streetNumber: "",
  neighborhood: "",
  reference: "",
  postalCode: 0,
  municipality: "",
  city: "",
  state: "",
  invoice: false,
  noInvoice: true,
  socialName: "",
  rfc: "",
  legalAddress: "",
  cfdi_use: "",
};

const phoneRegExp = /^[0-9]{10}$/;

const schema = yup.object().shape({
  clientName: yup.string().required("Este campo es requerido"),
  // lastName: yup.string().required("Este campo es requerido"),
  clientEmail: yup
    .string()
    .email("Ingresa un email válido.")
    .required("Este campo es requerido"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Por favor proporciona tu número a 10 dígitos")
    .required("Este campo es requerido"),
  address: yup.string().required("Este campo es requerido"),
  streetNumber: yup.string().required("Este campo es requerido"),
  neighborhood: yup.string().required("Este campo es requerido"),
  reference: yup.string().required("Este campo es requerido"),
  // lastName: yup.string().required("Este campo es requerido"),
  postalCode: yup
    .number()
    .required("Este campo es requerido")
    // .moreThan(9999, "Ingresa un Código Postal válido"),
    .min(5, "Ingresa un Código Postal válido"),
  municipality: yup.string().required("Este campo es requerido"),
  city: yup.string().required("Este campo es requerido"),
  state: yup.string().required("Este campo es requerido"),
  invoice: yup.bool(),
  noInvoice: yup.bool(),
  // socialName: yup
  //   .string()
  //   .required()
  //   .oneOf([yup.ref("invoice"), null], "Este campo es requerido"),
  socialName: yup.string().when("invoice", (invoiceValue) => {
    if (invoiceValue === false) {
      return yup.string();
    } else {
      return yup.string().required("Este campo es requerido");
    }
  }),
  rfc: yup
    .string()

    .when("invoice", (invoiceValue) => {
      if (invoiceValue === false) {
        return yup.string();
      } else {
        return yup
          .string()
          .min(13, "Ingresa un RFC válido")
          .required("Este campo es requerido");
      }
    }),
  legalAddress: yup.string().when("invoice", (invoiceValue) => {
    if (invoiceValue === false) {
      return yup.string();
    } else {
      return yup.string().required("Este campo es requerido");
    }
  }),
  cfdi_use: yup.string().when("invoice", (invoiceValue) => {
    if (invoiceValue === false) {
      return yup.string();
    } else {
      return yup.string().required("Este campo es requerido");
    }
  }),
});

const CFDI_USES = [
  {
    id: 1,
    name: "Adquisición de mercancías",
  },
  {
    id: 2,
    name: "Devoluciones, descuentos o bonificaciones",
  },
  {
    id: 3,
    name: "Gastos en general",
  },
  {
    id: 4,
    name: "Construcciones",
  },
  {
    id: 5,
    name: "Mobiliario y equipo de oficina por inversiones",
  },
  {
    id: 6,
    name: "Equipo de transporte",
  },
  {
    id: 7,
    name: "Equipo de cómputo y accesorios",
  },
  {
    id: 8,
    name: "Dados, troqueles, moldes, matrices y herramental",
  },
  {
    id: 9,
    name: "Comunicaciones telefónicas",
  },
  {
    id: 10,
    name: "Comunicaciones satelitales",
  },
  {
    id: 11,
    name: "Otra maquinaria y equipo",
  },
  {
    id: 12,
    name: "Honorarios médicos, dentales y gastos hospitalarios",
  },
  {
    id: 13,
    name: "Gastos médicos por incapacidad o discapacidad",
  },
  {
    id: 14,
    name: "Gastos funerales",
  },
  {
    id: 15,
    name: "Donativos",
  },
  {
    id: 16,
    name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
  },
  {
    id: 17,
    name: "Aportaciones voluntarias al SAR",
  },
  {
    id: 18,
    name: "Primas por seguros de gastos médicos",
  },
  {
    id: 19,
    name: "Gastos de transportación escolar",
  },
  {
    id: 20,
    name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
  },
  {
    id: 21,
    name: "Pagos por servicios educativos",
  },
  {
    id: 22,
    name: "Sin efectos fiscales",
  },
  {
    id: 23,
    name: "Pagos",
  },
  {
    id: 24,
    name: "Nómina",
  },
];

function PaymentForm({
  cartItems,
  loading,
  setLoading,
  totalPriceCart,
  shipment,
  setShipment,
  currentUser,
  emptyUserCart,
  guestEmptyCart,
  authEmptyCart,
  hasAchievedNationalPromotion,
  coupon,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [clientPostalCode, setClientPostalCode] = useState(0);
  const [invoiceRequired, setInvoiceRequired] = useState(false);
  const [noInvoiceRequired, setNoInvoiceRequired] = useState(true);

  const alert = useContext(AlertContext);

  const orderTotal = parseFloat(totalPriceCart) + shipment;

  const history = useHistory();
  // console.log("hasachieved", hasAchievedNationalPromotion || orderTotal > 1300);

  useEffect(() => {
    if (hasAchievedNationalPromotion && orderTotal > 1300) {
      setShipment(0);
    }
  }, [hasAchievedNationalPromotion, orderTotal]);

  useEffect(() => {
    let zipCode = clientPostalCode.toString();
    if (
      zipCode.startsWith("08") ||
      zipCode.startsWith("09") ||
      zipCode.startsWith("07") ||
      zipCode.startsWith("01") ||
      zipCode.startsWith("06") ||
      zipCode.startsWith("10") ||
      zipCode.startsWith("14") ||
      zipCode.startsWith("15") ||
      zipCode.startsWith("11") ||
      zipCode.startsWith("16") ||
      zipCode.startsWith("12") ||
      zipCode.startsWith("13") ||
      zipCode.startsWith("02") ||
      zipCode.startsWith("05") ||
      zipCode.startsWith("07") ||
      zipCode.startsWith("03") ||
      zipCode.startsWith("04") ||
      zipCode.startsWith("52") ||
      zipCode.startsWith("53") ||
      zipCode.startsWith("54") ||
      zipCode.startsWith("55") ||
      zipCode.startsWith("56") ||
      zipCode.startsWith("57")
    ) {
      setShipment(120);
      if (orderTotal > 800) {
        setShipment(0);
      }
    } else {
      if (orderTotal > 1300) {
        setShipment(0);
      } else {
        setShipment(250);
      }
      setShipment(250);
    }
  }, [clientPostalCode]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        const cardNumber = elements.getElement(CardElement);
        const isEmpty = cardNumber._empty;
        const isValid = !isEmpty;
        values.invoice = invoiceRequired;
        if (!isValid) {
          alert.error("Ingresa un método de pago válido.");
          document
            .getElementById("card-element")
            .scrollIntoView({ block: "center" });
          setLoading(false);
          return;
        }
        setLoading(true);
        const orderId = uuidv4().slice(0, 7);
        const today = new Date();
        const date =
          today.getDate() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getFullYear();
        ReactGA.event({
          category: "Ecommerce",
          action: "Checkout Progress",
          label: "checkout_progress	",
        });
        const { error: stripeError, paymentMethod } =
          await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
              email: values.clientEmail,
              name: values.clientName,
              phone: values.phoneNumber,
              address: {
                city: values.city,
                country: "MX",
                line1: values.address,
                line2: values.neighborhood,
                state: values.state,
              },
            },
          });
        if (stripeError) {
          alert.error(stripeError.message);
          setLoading(false);
        }
        if (paymentMethod) {
          // console.log(paymentMethod);
          // console.log(currentUser.user);
          ReactPixel.trackCustom("Purchase", { content_name: "Purchase" });
          ReactGA.event({
            category: "Ecommerce",
            action: "Purchase",
            label: "Purchase",
          });
          if (currentUser.user) {
            // console.log("es de user registrado", !currentUser.user);
            try {
              const response = await createAuthOrder(
                {
                  data: {
                    token: paymentMethod.id,
                    orderId,
                    clientName: values.clientName,
                    clientEmail: values.clientEmail,
                    phoneNumber: values.phoneNumber,
                    address: values.address,
                    streetNumber: values.streetNumber,
                    neighborhood: values.neighborhood,
                    postalCode: parseInt(values.postalCode),
                    reference: values.reference,
                    municipality: values.municipality,
                    city: values.city,
                    state: values.state,
                    country: "Mexico",
                    total: orderTotal,
                    orderItems: cartItems,
                    shipping: shipment,
                    usuario: [currentUser.user.id],
                    invoice: invoiceRequired,
                    socialName: values.socialName,
                    rfc: values.rfc,
                    legalAddress: values.legalAddress,
                    cfdi_use: values.cfdi_use,
                  },
                },
                currentUser.jwt
              );
              // console.log(response);
              if (response.status === 500) {
                setLoading(false);
                alert.error(
                  "Tu compra fue realizada pero tuvimos problemas en guardar tu orden. Por favor contáctanos para poder asistirte. Error 500"
                );
              } else if (response.status === 406) {
                setLoading(false);
                response.json().then((json) => {
                  alert.error(
                    `El producto ${json.product} presentación en ${json.presentation} no tiene el stock suficiente para completar tu orden. Elimina el producto y consulta el stock disponible`
                  );
                });
              } else if (response.status === 409) {
                console.log("409");
                setLoading(false);
                response.json().then((json) => {
                  // console.log(json);
                  alert.error(json.error);
                });
              } else if (response.status === 404) {
                alert.error(
                  "Hubo un error al completar tu orden. Por favor, vuelve a intentarlo."
                );
                setLoading(false);
              } else if (response.ok) {
                alert.success("Compra realizada con éxito");
                axios.all([
                  axios.post(`${process.env.REACT_APP_API_URL}/api/email`, {
                    to: [
                      "rodrigo.zenteno7@gmail.com",
                      "yazminmoralesmor90@gmail.com",
                      "berenice@dammannmexique.com",
                      "testing@dammannmexique.com",
                    ],
                    subject: "Nueva orden de compra",
                    html: `
                    <html lang="es">
                      <head>
                        <meta charset="UTF-8" />
                        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <link rel="preconnect" href="https://fonts.gstatic.com" />
                        <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@200;400;700&display=swap" rel="stylesheet" />
                      </head>

                      <body style="margin: 0; padding: 0;">
                        <div
                          style="font-family: 'Ubuntu', sans-serif; background-color: rgba(239, 227, 214, 0.37); padding: 2rem 1rem; height: auto;">
                          <div
                            style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 15px solid #000; padding: 0; overflow: hidden;">
                            <div style="padding: 2rem 1rem;">
                              <h2 style="text-transform: uppercase; font-weight: bold; margin-bottom: 1rem; color: #000;">
                                Nueva orden de compra de sitio web Dammann</h2>
                              <p style="margin-bottom: 1rem; color: #000;">${
                                values.clientName
                              } envío una orden de compra.</p>
                              <h3 style="font-weight: bold; margin-bottom: 1rem; color: #000;">
                                Datos de contacto:</h3>
                              <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Fecha:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${date}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Nombre:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.clientName}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Email:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.clientEmail}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Teléfono:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.phoneNumber}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Dirección:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.address}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Número:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.streetNumber}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Colonia:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.neighborhood}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Referencia:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.reference}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Municipio:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.municipality}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Ciudad:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.city}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Estado:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.state}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Código Postal:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.postalCode}</td>
                                </tr>
                              </table>
                              <h3 style="font-weight: bold; margin-bottom: 1rem; color: #000">
                                Orden de compra: ${orderId}</h3>
                              <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                                ${cartItems.map(
                                  (item) => `
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Producto:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${
                                      item.attributes.productos.data[0]
                                        .attributes.nombre
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Cantidad:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${item.attributes.cantidad}
                                  </td>

                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Presentacion:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${
                                      item.attributes.productos.data[0]
                                        .attributes.PresentacionesDisponibles[
                                        item.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                          (presentation) =>
                                            presentation.Presentacion ===
                                            item.attributes.selectedPresentation
                                        )
                                      ].Presentacion
                                    }
                                  </td>

                                </tr>
                                `
                                )}
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Total:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${formatMoney(orderTotal)} MNX
                                  </td>
                                </tr>
                              </table>
                              <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                                <tr>
                                    <td valign="top"
                                      style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                      <strong>Factura:</strong>
                                    </td>
                                    <td valign="top"
                                      style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                      ${
                                        invoiceRequired
                                          ? "Con factura"
                                          : "Sin factura"
                                      }</td>
                                  </tr>
                                  ${
                                    invoiceRequired
                                      ? `<tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Razón Social:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.socialName}</td> 
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>RFC:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.rfc}</td> 
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Domicilio fiscal:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.legalAddress}</td> 
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Uso de CFDI:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.cfdi_use}</td> 
                                    </tr>
                                    `
                                      : ""
                                  }
                                  
                                 
                              </table>
                            </div>
                            <img src="https://dammannmexique.com/static/media/logo_paris--black.0c0862be83907ea12a2c.png" alt="Dammann Mexico" title="Dammann Mexico"
                              style="display: block; margin: 1rem auto;" width="205px" height="auto" />
                          </div>
                        </div>
                      </body>

                      </html>
                  `,
                  }),
                  axios.post(`${process.env.REACT_APP_API_URL}/api/email`, {
                    to: [`${values.clientEmail}`],
                    subject: "Confirmación de compra",
                    html: `<html lang="es">
                  <head>
                    <meta charset="UTF-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@200;400;700&display=swap" rel="stylesheet" />
                  </head>

                  <body style="margin: 0; padding: 0; ">
                    <div
                      style="font-family: 'Ubuntu', sans-serif; background-color: rgba(239, 227, 214, 0.37); padding: 2rem 1rem; height: auto;">
                      <div
                        style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 10px solid #000; padding:0; overflow: hidden;">
                        <div class="heading" style="background-color: #F5F5F6; padding: 2rem;">
                          <img src="https://dammannmexique.com/static/media/logo_paris--black.0c0862be83907ea12a2c.png" style="height: 60px;" alt="Dammann Mexico">
                        </div>
                        <div style="padding: 1rem 1.5rem;">
                          <h2 style="text-transform: uppercase; font-weight: bold; margin-bottom: 1rem; color: #000; text-align: center;">
                            Confirmación de orden</h2>
                          <div style="text-align: center;">
                            <img src="https://dammannmexique.com/static/media/success-icon.e5f725de30538582b280eb5e276da5dd.svg" alt="Dammann Mexico" title="Dammann Mexico"
                              style="display: block; margin: 1rem auto;" width="80px" height="auto" />
                            <h3 style="margin-bottom: .5rem;">¡Gracias por tu compra!</h3>
                            <p style="margin-bottom: 1rem; margin-top: 0; color: #000;">Número de orden #${orderId}</p>
                          </div>
                          <h3 style="font-weight: bold; margin-bottom: 1rem; color: #000;">
                            Detalles de envío</h3>
                          <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0;">
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Fecha:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${date}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Nombre:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.clientName}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Email:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.clientEmail}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Teléfono:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.phoneNumber}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Dirección:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.address}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Número:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.streetNumber}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Colonia:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.neighborhood}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Referencia:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.reference}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Municipio:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.municipality}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Ciudad:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.city}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Estado:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.state}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Código Postal:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.postalCode}</td>
                            </tr>
                          </table>
                          <h3 style="font-weight: bold; margin-bottom: 1rem; color: #000">
                            Detalles de la orden:</h3>
                          <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">

                            ${cartItems.map(
                              (item) => `
                            <div style="display: flex; justify-content: space-between;">
                              <div style="display: flex; margin-top: .8rem;">
                                <p style="margin: 0; margin-right: 1rem;">${
                                  item.attributes.cantidad
                                } x</p>
                                <p style=" margin: 0; font-weight: bolder;">${
                                  item.attributes.productos.data[0].attributes
                                    .nombre
                                }</p>
                              </div>
                              <p style="margin-bottom: 0; font-size: 18px; color: #74889D; margin-top: .8rem;">${formatMoney(
                                item.attributes.productos.data[0].attributes
                                  .PresentacionesDisponibles[
                                  item.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                    (presentation) =>
                                      presentation.Presentacion ===
                                      item.attributes.selectedPresentation
                                  )
                                ].precio
                              )} MNX
                              </p>
                              <p>${
                                item.attributes.productos.data[0].attributes
                                  .PresentacionesDisponibles[
                                  item.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                    (presentation) =>
                                      presentation.Presentacion ===
                                      item.attributes.selectedPresentation
                                  )
                                ].Presentacion
                              }</p>
                            </div>
                            `
                            )}
                          </div>
                          <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">
                            <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
                              <p style="margin: 0; font-size: 1.1rem;">Envío</p>
                              <p style="margin-bottom: 0; font-size: 18px; color: #74889D; margin-top: .8rem;">${formatMoney(
                                shipment
                              )} MNX</p>
                            </div>
                          </div>
                          <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
                            <h5 style="margin: 0; font-size: 1.1rem;">Total</h5>
                            <p style="margin: 0; color: #000; font-size: 1.4rem;">${formatMoney(
                              orderTotal
                            )} MNX</p>
                          </div>
                          <div><p style="margin: 0; font-size: .9rem; text-align:center">Tu producto se entregará dentro de 5 a 7 días hábiles.</p></div>
                        </div>
                      </div>
                    </div>
                  </body>

                  </html>
                  `,
                  }),
                ]);
                cartItems.forEach((cartItem) => {
                  authEmptyCart(cartItem.id, currentUser.jwt);
                  // console.log(cartItem.id);
                });
                history.push(`/confirmation/${orderId}`);
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
              console.log(error);
              alert.error(
                "Hubo un error en tu método de pago. Verifica que los datos sean correctos o comunícate con tu institución bancaria."
              );
            }
          } else if (currentUser.guest) {
            // console.log("es de user guest");
            try {
              const response = await createOrder({
                data: {
                  token: paymentMethod.id,
                  orderId,
                  clientName: values.clientName,
                  clientEmail: values.clientEmail,
                  phoneNumber: values.phoneNumber,
                  address: values.address,
                  streetNumber: values.streetNumber,
                  neighborhood: values.neighborhood,
                  postalCode: parseInt(values.postalCode),
                  reference: values.reference,
                  municipality: values.municipality,
                  city: values.city,
                  state: values.state,
                  country: "Mexico",
                  total: orderTotal,
                  orderItems: cartItems,
                  shipping: shipment,
                  invoice: invoiceRequired,
                  socialName: values.socialName,
                  rfc: values.rfc,
                  legalAddress: values.legalAddress,
                  cfdi_use: values.cfdi_use,
                },
              });
              if (response.status === 500) {
                setLoading(false);
                alert.error(
                  "Tu compra fue realizada pero tuvimos problemas en guardar tu orden. Por favor contáctanos para poder asistirte. Error 500"
                );
              } else if (response.status === 406) {
                setLoading(false);
                response.json().then((json) => {
                  alert.error(
                    `El producto ${json.product} presentación en ${json.presentation} no tiene el stock suficiente para completar tu orden. Elimina el producto y consulta el stock disponible`
                  );
                });
              } else if (response.status === 409) {
                console.log("409");
                setLoading(false);
                response.json().then((json) => {
                  // console.log(json);
                  alert.error(json.error);
                });
              } else if (response.status === 404) {
                // alert.success("Compra realizada con éxito");
                setLoading(false);
              } else if (response.ok) {
                alert.success("Compra realizada con éxito");
                axios.all([
                  axios.post(`${process.env.REACT_APP_API_URL}/api/email`, {
                    to: [
                      "rodrigo.zenteno7@gmail.com",
                      "yazminmoralesmor90@gmail.com",
                      "berenice@dammannmexique.com",
                      "testing@dammannmexique.com",
                    ],
                    subject: "Nueva orden de compra",
                    html: `
                    <html lang="es">
                      <head>
                        <meta charset="UTF-8" />
                        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <link rel="preconnect" href="https://fonts.gstatic.com" />
                        <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@200;400;700&display=swap" rel="stylesheet" />
                      </head>

                      <body style="margin: 0; padding: 0;">
                        <div
                          style="font-family: 'Ubuntu', sans-serif; background-color: rgba(239, 227, 214, 0.37); padding: 2rem 1rem; height: auto;">
                          <div
                            style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 15px solid #000; padding: 0; overflow: hidden;">
                            <div style="padding: 2rem 1rem;">
                              <h2 style="text-transform: uppercase; font-weight: bold; margin-bottom: 1rem; color: #000;">
                                Nueva orden de compra de sitio web Dammann</h2>
                              <p style="margin-bottom: 1rem; color: #000;">${
                                values.clientName
                              } envío una orden de compra.</p>
                              <h3 style="font-weight: bold; margin-bottom: 1rem; color: #000;">
                                Datos de contacto:</h3>
                              <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Fecha:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${date}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Nombre:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.clientName}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Email:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.clientEmail}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Teléfono:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.phoneNumber}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Dirección:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.address}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Número:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.streetNumber}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Colonia:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.neighborhood}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Referencia:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.reference}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Municipio:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.municipality}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Ciudad:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.city}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Estado:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.state}</td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Código Postal:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.postalCode}</td>
                                </tr>
                              </table>
                              <h3 style="font-weight: bold; margin-bottom: 1rem; color: #000">
                                Orden de compra: ${orderId}</h3>
                              <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                                ${cartItems.map(
                                  (item) => `
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Producto:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${
                                      item.attributes.productos.data[0]
                                        .attributes.nombre
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Cantidad:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${item.attributes.cantidad}
                                  </td>
                                  
                                </tr>
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Presentacion:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${
                                      item.attributes.productos.data[0]
                                        .attributes.PresentacionesDisponibles[
                                        item.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                          (presentation) =>
                                            presentation.Presentacion ===
                                            item.attributes.selectedPresentation
                                        )
                                      ].Presentacion
                                    }
                                  </td>
                                  
                                </tr>
                                `
                                )}
                                <tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Total:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:100%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${formatMoney(orderTotal)} MNX
                                  </td>
                                </tr>
                              </table>
                              <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                                <tr>
                                    <td valign="top"
                                      style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                      <strong>Factura:</strong>
                                    </td>
                                    <td valign="top"
                                      style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                      ${
                                        invoiceRequired
                                          ? "Con factura"
                                          : "Sin factura"
                                      }</td>
                                  </tr>
                                  ${
                                    invoiceRequired
                                      ? `<tr>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                    <strong>Razón Social:</strong>
                                  </td>
                                  <td valign="top"
                                    style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                    ${values.socialName}</td> 
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>RFC:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.rfc}</td> 
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Domicilio fiscal:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.legalAddress}</td> 
                                    </tr>
                                    <tr>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                        <strong>Uso de CFDI:</strong>
                                      </td>
                                      <td valign="top"
                                        style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                        ${values.cfdi_use}</td> 
                                    </tr>
                                    `
                                      : ""
                                  }
                              </table>
                            </div>
                            <img src="https://dammannmexique.com/static/media/logo_paris--black.0c0862be83907ea12a2c.png" alt="Dammann Mexico" title="Dammann Mexico"
                              style="display: block; margin: 1rem auto;" width="205px" height="auto" />
                          </div>
                        </div>
                      </body>

                      </html>
                  `,
                  }),
                  axios.post(`${process.env.REACT_APP_API_URL}/api/email`, {
                    to: [`${values.clientEmail}`],
                    subject: "Confirmación de compra",
                    html: `<html lang="es">
                  <head>
                    <meta charset="UTF-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@200;400;700&display=swap" rel="stylesheet" />
                  </head>

                  <body style="margin: 0; padding: 0; ">
                    <div
                      style="font-family: 'Ubuntu', sans-serif; background-color: rgba(239, 227, 214, 0.37); padding: 2rem 1rem; height: auto;">
                      <div
                        style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 10px solid #000; padding:0; overflow: hidden;">
                        <div class="heading" style="background-color: #F5F5F6; padding: 2rem;">
                          <img src="https://dammannmexique.com/static/media/logo_paris--black.0c0862be83907ea12a2c.png" style="height: 60px;" alt="Dammann Mexico">
                        </div>
                        <div style="padding: 1rem 1.5rem;">
                          <h2 style="text-transform: uppercase; font-weight: bold; margin-bottom: 1rem; color: #000; text-align: center;">
                            Confirmación de orden</h2>
                          <div style="text-align: center;">
                            <img src="https://dammannmexique.com/static/media/success-icon.e5f725de30538582b280eb5e276da5dd.svg" alt="Dammann Mexico" title="Dammann Mexico"
                              style="display: block; margin: 1rem auto;" width="80px" height="auto" />
                            <h3 style="margin-bottom: .5rem;">¡Gracias por tu compra!</h3>
                            <p style="margin-bottom: 1rem; margin-top: 0; color: #000;">Número de orden #${orderId}</p>
                          </div>
                          <h3 style="font-weight: bold; margin-bottom: 1rem; color: #000;">
                            Detalles de envío</h3>
                          <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0;">
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Fecha:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${date}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Nombre:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.clientName}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Email:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.clientEmail}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Teléfono:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.phoneNumber}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Dirección:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.address}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Número:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.streetNumber}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Colonia:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.neighborhood}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Referencia:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.reference}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Municipio:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.municipality}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Ciudad:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.city}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Estado:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.state}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Código Postal:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.postalCode}</td>
                            </tr>
                          </table>
                          <h3 style="font-weight: bold; margin-bottom: 1rem; color: #000">
                            Detalles de la orden:</h3>
                          <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">

                            ${cartItems.map(
                              (item) => `
                            <div style="display: flex; justify-content: space-between;">
                              <div style="display: flex; margin-top: .8rem;">
                                <p style="margin: 0; margin-right: 1rem;">${
                                  item.attributes.cantidad
                                } x</p>
                                <p style=" margin: 0; font-weight: bolder;">${
                                  item.attributes.productos.data[0].attributes
                                    .nombre
                                }</p>
                              </div>
                              <p style="margin-bottom: 0; font-size: 18px; color: #74889D; margin-top: .8rem;">${formatMoney(
                                item.attributes.productos.data[0].attributes
                                  .PresentacionesDisponibles[
                                  item.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                    (presentation) =>
                                      presentation.Presentacion ===
                                      item.attributes.selectedPresentation
                                  )
                                ].precio
                              )} MNX
                              </p>
                              <p>${
                                item.attributes.productos.data[0].attributes
                                  .PresentacionesDisponibles[
                                  item.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                    (presentation) =>
                                      presentation.Presentacion ===
                                      item.attributes.selectedPresentation
                                  )
                                ].Presentacion
                              }</p>
                            </div>
                            `
                            )}
                          </div>
                          <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">
                            <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
                              <p style="margin: 0; font-size: 1.1rem;">Envío</p>
                              <p style="margin-bottom: 0; font-size: 18px; color: #74889D; margin-top: .8rem;">${formatMoney(
                                shipment
                              )} MNX</p>
                            </div>
                          </div>
                          <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
                            <h5 style="margin: 0; font-size: 1.1rem;">Total</h5>
                            <p style="margin: 0; color: #000; font-size: 1.4rem;">${formatMoney(
                              orderTotal
                            )} MNX</p>
                          </div>
                          <div><p style="margin: 0; font-size: .9rem; text-align:center">Tu producto se entregará dentro de 5 a 7 días hábiles.</p></div>
                        </div>
                      </div>
                    </div>
                  </body>

                  </html>
                  `,
                  }),
                ]);
                history.push(`/confirmation/${orderId}`);
                guestEmptyCart();
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
              console.log(error);
              alert.error(
                "Hubo un error en tu método de pago. Verifica que los datos sean correctos o comunícate con tu institución bancaria."
              );
            }
          }
          // setLoading(false);
        }

        // setTimeout(() => {
        //   history.push("/confirmation/12ffbb");
        //   setLoading(false);
        // }, 3000);
        // console.log(paymentMethod);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Form onSubmit={handleSubmit} className="payment-form">
            {/* {console.log(
              cartItems.map(
                (item) =>
                  item.attributes.productos.data[0].attributes
                    .PresentacionesDisponibles[
                    item.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                      (presentation) =>
                        presentation.Presentacion ===
                        item.attributes.selectedPresentation
                    )
                  ].precio
              )
            )} */}
            <div className="payment-form__contact-info">
              <div className="payment-form__header">
                <h4>
                  <span>1</span>Información de contacto
                </h4>
              </div>
              <div className="payment-form__contact-info__container">
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>
                    Nombre completo: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="clientName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientName}
                    isInvalid={!!errors.clientName && touched.clientName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.clientName || touched.clientName}
                  </Form.Control.Feedback>
                  {/* <span
                  style={{
                    color: "#c93030",
                    fontWeight: "bolder",
                    marginTop: ".6rem",
                    padding: "0",
                    fontSize: ".9rem",
                  }}
                >
                  {errors.clientName && touched.clientName && errors.clientName}
                </span> */}
                </Form.Group>
                <Row>
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    lg="6"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Email: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="clientEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.clientEmail}
                      isInvalid={!!errors.clientEmail && touched.clientEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.clientEmail || touched.clientEmail}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.clientEmail &&
                      touched.clientEmail &&
                      errors.clientEmail}
                  </span> */}
                  </Form.Group>
                  <Form.Group as={Col} lg="6" controlId="formGridAddress1">
                    <Form.Label>
                      Teléfono: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      name="phoneNumber"
                      // value={values.phoneNumber}
                      // onChange={handleInputChange}
                      // required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber || touched.phoneNumber}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.phoneNumber &&
                      touched.phoneNumber &&
                      errors.phoneNumber}
                  </span> */}
                  </Form.Group>
                </Row>
              </div>
            </div>
            <div className="payment-form__contact-info mt-4">
              <div className="payment-form__header">
                <h4>
                  <span>2</span>Información de envío
                </h4>
              </div>
              <div className="payment-form__contact-info__container">
                <Row>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Calle: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      isInvalid={!!errors.address && touched.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address || touched.address}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.address && touched.address && errors.address}
                  </span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Número: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="streetNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.streetNumber}
                      isInvalid={!!errors.streetNumber && touched.streetNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.streetNumber || touched.streetNumber}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.streetNumber &&
                      touched.streetNumber &&
                      errors.streetNumber}
                  </span> */}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Referencia: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="reference"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reference}
                      isInvalid={!!errors.reference && touched.reference}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.reference || touched.reference}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.reference && touched.reference && errors.reference}
                  </span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Colonia: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="neighborhood"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.neighborhood}
                      isInvalid={!!errors.neighborhood && touched.neighborhood}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.neighborhood || touched.neighborhood}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.neighborhood &&
                      touched.neighborhood &&
                      errors.neighborhood}
                  </span> */}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Código Postal: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="postalCode"
                      onChange={(e) => {
                        handleChange(e);
                        setClientPostalCode(e.target.value);
                        // console.log(e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.postalCode}
                      isInvalid={!!errors.postalCode && touched.postalCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.postalCode || touched.postalCode}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.postalCode &&
                      touched.postalCode &&
                      errors.postalCode}
                  </span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Municipio: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="municipality"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.municipality}
                      isInvalid={!!errors.municipality && touched.municipality}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.municipality || touched.municipality}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.municipality &&
                      touched.municipality &&
                      errors.municipality}
                  </span> */}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Ciudad: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      isInvalid={!!errors.city && touched.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city || touched.city}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.city && touched.city && errors.city}
                  </span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    lg="6"
                    className="mb-3"
                    controlId="formGridAddress1"
                  >
                    <Form.Label>
                      Estado: <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      isInvalid={!!errors.state && touched.state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state || touched.state}
                    </Form.Control.Feedback>
                    {/* <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.state && touched.state && errors.state}
                  </span> */}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <p className="ms-2">¿Requerirás factura con tu compra?</p>
                  <Form.Group as={Col} lg="2">
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        isValid={false}
                        name="invoice"
                        checked={invoiceRequired}
                        onChange={(e) => {
                          handleChange(e);
                          setInvoiceRequired(!invoiceRequired);
                          setFieldValue("invoice", e.target.checked);
                          setFieldValue("noInvoice", false);
                          setNoInvoiceRequired(false);
                          if (
                            noInvoiceRequired === false &&
                            e.target.checked === false
                          ) {
                            setInvoiceRequired(false);
                            setFieldValue("invoice", true);

                            setNoInvoiceRequired(true);
                          }
                          if (
                            noInvoiceRequired === false &&
                            e.target.checked === true
                          ) {
                            setInvoiceRequired(false);
                            setFieldValue("invoice", false);
                            setNoInvoiceRequired(true);
                          }
                          // if (invoiceRequired === false) {
                          //   setInvoiceRequired(false);
                          //   setNoInvoiceRequired(true);
                          //   // setNoInvoiceRequired(true);
                          // }
                          // console.log(e.target.value);
                        }}
                        isInvalid={!!errors.invoice && touched.invoice}
                        feedback={errors.invoice}
                        // feedbackType="invalid"
                      />
                      <Form.Check.Label>Sí</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.invoice}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group as={Col} lg="2">
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        isValid={false}
                        checked={noInvoiceRequired}
                        name="noInvoice"
                        onChange={(e) => {
                          handleChange(e);
                          // console.log("1728", e.target.checked);
                          setFieldValue("invoice", false);
                          setFieldValue("noInvoice", e.target.checked);
                          setNoInvoiceRequired(!noInvoiceRequired);
                          setInvoiceRequired(false);
                          if (
                            invoiceRequired === false &&
                            e.target.checked === false
                          ) {
                            // console.log("1735");
                            setInvoiceRequired(true);
                            setNoInvoiceRequired(false);
                            setFieldValue("noInvoice", false);
                          }
                          if (
                            invoiceRequired === false &&
                            e.target.checked === true
                          ) {
                            // console.log("1744");
                            setInvoiceRequired(true);
                            setNoInvoiceRequired(false);
                            setFieldValue("noInvoice", false);
                          }
                          // console.log(e.target.value);
                        }}
                        isInvalid={!!errors.noInvoice && touched.noInvoice}
                        feedback={errors.noInvoice}
                        // feedbackType="invalid"
                      />
                      <Form.Check.Label>No</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.noInvoice}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                </Row>
                {invoiceRequired ? (
                  <>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        lg="6"
                        controlId="formGridSocialName"
                      >
                        <Form.Label>
                          Razón Social: <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="socialName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.socialName}
                          isInvalid={!!errors.socialName && touched.socialName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.socialName || touched.socialName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} lg="6" controlId="formGridRFC">
                        <Form.Label>
                          RFC: <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="rfc"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.rfc}
                          isInvalid={!!errors.rfc && touched.rfc}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.rfc || touched.rfc}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group>
                        <Form.Label>
                          Domicilio: <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="legalAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.legalAddress}
                          isInvalid={
                            !!errors.legalAddress && touched.legalAddress
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.legalAddress || touched.legalAddress}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mt-3">
                        <Form.Label>
                          Uso de CFDI: <span className="required">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Uso de CFDI"
                          name="cfdi_use"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cfdi_use}
                          isInvalid={!!errors.cfdi_use && touched.cfdi_use}
                        >
                          <option value="">Selecciona una opción</option>
                          {CFDI_USES.sort((a, b) =>
                            a.name.localeCompare(b.name)
                          ).map((usage, key) => (
                            <option key={key} value={usage.name}>
                              {usage.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.cfdi_use || touched.cfdi_use}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </>
                ) : null}
              </div>
            </div>
            <div className="payment-form__contact-info">
              <div className="payment-form__header d-flex">
                <h4 className="me-2">
                  <span>3</span>Pago
                </h4>
                {/* <img src={creditCard1} alt="Gamerscode" />
                <img src={creditCard2} className="mx-2" alt="Gamerscode" />
                <img src={creditCard3} alt="Gamerscode" /> */}
              </div>

              {/* <CardElement /> */}
              <div className="payment-form__card-section">
                <div>
                  <fieldset style={{ border: "none" }}>
                    <div className="form-row">
                      <div id="card-element">
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: "17px",
                                height: "60px",
                              },
                            },
                          }}
                        />
                      </div>
                      {/* {props.stripeError ? <div>{props.stripeError.toString()}</div> : null} */}
                      {/* <div id="card-errors" role="alert">
                        {stripeError !== "" && <p>{stripeError}</p>}
                      </div> */}
                    </div>
                  </fieldset>
                </div>
              </div>
              {/* {console.log(typeof totalPriceCart + shipment === "number")} */}
              {cartItems.length === 0 ? (
                <h6 className="text-center mt-2">
                  No hay elementos en tu carrito
                </h6>
              ) : (
                <button
                  type="submit"
                  disabled={loading || isNaN(totalPriceCart)}
                >
                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">
                        Procesando compra...
                      </span>
                    </Spinner>
                  ) : (
                    `Completar compra ${formatMoney(totalPriceCart + shipment)}`
                  )}
                </button>
              )}
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default PaymentForm;
