/*
  @route    GET https://REACT_APP_API_URL/api/products
  @desc     Get all products
  @access   Public
*/
export async function getCategoryProducts(categoryName) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/productos?_sort=updatedAt:desc&[populate]=*&filters[categoria][slug][$eq]=${categoryName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/api/promociones-temporadas?[populate]=*
  @desc     Get all products
  @access   Public
*/
export async function getSeasonProducts() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/promociones-temporadas?populate[producto][populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/api/promociones-temporadas?[populate]=*
  @desc     Get all products
  @access   Public
*/
export async function getProduct(id) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/productos/?filters[slug][$eq]=${id}&_sort=updatedAt:&[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/api/sabores-mas-vendidos?[populate]=*
  @desc     Get all products
  @access   Public
*/
export async function getBestSellingProducts() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/sabores-mas-vendidos?populate[producto][populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/api/productos?_sort=updatedAt:desc&[populate]=*&filters[tipoProducto][$eq]=Accesorio
  @desc     Get all products
  @access   Public
*/
export async function getAccessoriesProducts() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/productos?_sort=updatedAt:desc&[populate]=*&filters[tipoProducto][$eq]=Accesorio`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/api/productos?_sort=updatedAt:desc&[populate]=*&filters[subcategoria][slug][$eq]=te-verde
  @desc     Get all products
  @access   Public
*/
export async function getSubCategoryProducts(subcategoryName) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/productos?_sort=updatedAt:desc&[populate]=*&filters[subcategoria][slug][$eq]=${subcategoryName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/api/subcategorias?_sort=updatedAt:desc&populate[categoria][populate]=*
  @desc     Get all subcategories
  @access   Public
*/
export async function getSubCategories() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/subcategorias?_sort=updatedAt:desc&populate[categoria][populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/api/categorias?_sort=updatedAt:desc&populate[populate]=*
  @desc     Get all subcategories
  @access   Public
*/
export async function getCategories() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/categorias?_sort=updatedAt:desc&populate[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
