import { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";

// Context
import AlertContext from "../contexts/AlertContext";
import { useAuth } from "../contexts/AuthContext";

// Bootstrap components
import Form from "react-bootstrap/Form";

// Custom styles
import "../scss/pages/login.styles.scss";

// API
// import { login } from "../api/auth";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

const initialValues = {
  identifier: "",
  password: "",
};

const schema = yup.object().shape({
  identifier: yup
    .string()
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
  password: yup.string().required("Este campo es requerido"),
});

function Login() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const alert = useContext(AlertContext);

  const { loginUser } = useAuth();

  return (
    <section className="login__wrapper">
      <div className="login__container">
        <h4>Iniciar sesión</h4>
        <p className="mt-2 mb-3 m-lg-0">
          Ingresa el correo electrónico y contraseña en tu registro para
          ingresar a tu perfil.
        </p>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            const response = await loginUser(values);
            if (response.jwt) {
              ReactPixel.trackCustom("Lead", { content_name: "Login" });
              ReactGA.event({
                category: "Engagement",
                action: "Login",
                label: "login",
              });
              alert.success("Inicio de sesión exitoso.");
              history.push("/");
              resetForm(initialValues);
            } else {
              alert.error("Error al iniciar sesión.");
              setLoading(false);
              resetForm(initialValues);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Escribe aquí tu correo"
                  name="identifier"
                  value={values.identifier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.identifier && !!errors.identifier}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.identifier || touched.identifier}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contraseña:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password || touched.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Link to="/forgot-password" className="d-block text-center">
                ¿Olvidaste tu contraseña?
              </Link>
              <button type="submit">
                {loading ? "Iniciando sesión..." : "Iniciar sesión"}
              </button>
              <p>
                ¿No tienes cuenta? <Link to="/register">Regístrate aquí</Link>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default Login;
