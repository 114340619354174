import React from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/policy.styles.scss";

function Policies() {
  return (
    <section className="policy__wrapper">
      <div className="policy__hero">
        <Container fluid>
          <h3>Aviso de privacidad y políticas de servicio</h3>
        </Container>
      </div>
      <div className="policy__container">
        <Container>
          <p>
            Gracias por acceder y utilizar alguna de nuestras plataformas web de
            DAMMANN MEXIQUE. El uso de ellas implica el acceso a tus datos
            personales y la aceptación de este Aviso de Privacidad. Te
            recomendamos que lo leas detenidamente.
          </p>
          <ol>
            <li>
              DENOMINACIÓN DE LAS PARTES INTERESADAS EN EL PRESENTE AVISO DE
              PRIVACIDAD.{" "}
              <p>
                En el contenido del presente aviso de privacidad, se referirá a
                las partes interesadas, como sigue:
              </p>
              <p>La responsable: CKN de México, S.A de C.V.</p>
              <p>
                El Titular: La persona física, usuario de esta y cualquier otra
                plataforma web relacionada a la responsable.
              </p>
            </li>
            <li>
              RESPONSABLE DE RECABAR Y RESGUARDAR SUS DATOS PERSONALES.
              <p>
                Según lo establecido por la Ley Federal de Protección de Datos
                Personales en Posesión de Particulares, la responsable de
                recabar sus datos (señalada en la tabla inmediata siguiente) le
                informa sobre la política de privacidad y manejo de datos
                personales, la cual, en todo momento velará que el tratamiento
                de los mismos sea legítimo, controlado e informado, a efecto de
                garantizar la privacidad de los mismos.
              </p>
              <p>La responsable: CKN de México, S.A de C.V.</p>
              <p>Conocida como: CKN de México.</p>
              <p>
                Domicilio: Cerrada de Constituyentes 25 Col. Lomas Altas C.P
                1950.
              </p>
              <p>Página web: www.dammannmexique.com</p>
              <p>Giro principal: </p>
            </li>
            <li>
              ALCANCE DE ESTE AVISO DE PRIVACIDAD.
              <p>
                La responsable es una persona moral, en pleno ejercicio de sus
                derechos y con todas las capacidades o facultades legales para
                brindar servicios y vincularse a todas sus obligaciones,
                incluidas las suscitadas en este instrumento. Dicha responsable
                tiene el respaldo de una empresa seguramente estructurada con
                procesos comerciales, estructuras de administración y sistemas
                técnicos que pueden abarcar distintas entidades federativas
                dentro de la República Mexicana y el mundo y cumplir con todas
                sus funciones. Este aviso de privacidad se aplica a todas las
                plataformas web, incluyendo los sitios web, dominios, servicios,
                plataformas web, aplicaciones y productos pertenecientes a la
                responsable y a sus subsidiarias de propiedad absoluta (“sitios
                o servicios de la responsable”), excepto que una política o
                declaración de privacidad específica para un producto o servicio
                de la responsable en particular pueda dejar sin efecto o
                complementar esta declaración o aviso de privacidad.
              </p>
              <p>
                El alcance de este Aviso de Privacidad, el acceso y sobre todo
                la obtención de los datos personal puede llevarse a cabo por los
                siguientes medios: e-commerce, página web, cuentas en redes
                sociales, teléfono y/o cámaras de teléfonos móviles.
              </p>
            </li>
            <li>
              INFORMACIÓN Y DATOS QUE SE RECABAN:
              <p>
                La responsable recopila y utiliza información personal para
                brindarle un mejor servicio y personalizar su experiencia e
                interacción. Dicha recopilación se realiza con su consentimiento
                y de acuerdo a las leyes de protección de datos personales
                vigentes dentro de la República Mexicana así como con el
                presente aviso de privacidad.
              </p>
              <p>
                La responsable también recaba información de fuentes públicas o
                comerciales disponibles que sean dignas de su confianza o de la
                recopilación o extracción de datos por su navegación el utilizar
                la plataforma web o derivada del acceso que le otorga a la
                misma. Dicha información puede incluir su nombre, dirección,
                dirección de correo electrónico, edad, sexo, fecha de
                nacimiento, preferencias, intereses y datos demográficos o de su
                perfil. La información que la responsable obtiene de sus fuentes
                públicas o comerciales puede utilizarse junto con la información
                que la misma recopila cuando el titular visita nuestro sitio
                web. Por ejemplo, la responsable puede comparar la información
                geográfica adquirida de sus fuentes comerciales con la dirección
                IP recabada por las herramientas de recopilación de datos
                automática para concluir cuál es su zona geográfica general.
              </p>
              <p>
                Por otro lado la responsable podría acceder a datos personales
                de el titular, es decir usted, a través de sus redes sociales.
                En la mayoría de las situaciones se solicitará acceder a la
                misma a través de alguna plataforma web y su aceptación.
              </p>
              <p>
                Los tipos de datos personales (por lo general no solicitaremos
                datos denominados como “sensibles” por la Ley Federal de
                Protección de Datos Personales en Posesión de los Particulares,
                salvo que así lo expresemos) obtenidos a través de este sitio
                web, redes sociales o cualquier otra plataforma web podrán ser
                los siguientes:
              </p>
              <ul>
                <li>
                  Información de contacto, nombre, edad, sexo, fecha de
                  nacimiento, municipio, ciudad y/o Estado en el que habita o se
                  encuentra, dirección particular, número de teléfono fijo y/o
                  de oficina, número de teléfono celular y dirección de correo
                  electrónico.
                </li>
                <li>
                  Profesión, empresa en la que labora, puesto, nombre de la
                  empresa o nombre de su proyecto, giro de la empresa o del
                  proyecto.
                </li>
                <li>
                  Información financiera o fiscal, como número y datos de
                  tarjeta de crédito/débito, información de facturación u otra
                  información.
                </li>
                <li>
                  Otra información exclusiva, como ID de usuario y contraseñas,
                  preferencias de productos y servicios, preferencias de
                  contacto, antecedentes educativos y de empleo, datos de
                  intereses laborales.
                </li>
                <li>
                  Ubicación, posición geográfica, geolocalización, y cualquier
                  otra dirección o zona de presencia.
                </li>
                <li>
                  Información relacionada con el giro comercial y actividades
                  y/o servicios a las que dedica el titular, la empresa donde
                  labora o sobre su negocio.
                </li>
                <li>
                  Sobre su o sus productos podríamos solicitarle información,
                  descripción, precios, imágenes, fotografías, lugar de venta y
                  entrega, si pertenece a alguna alianza o asociación comercial
                  de los mismos.
                </li>
              </ul>
            </li>
            <li>
              USO DE LA INFORMACIÓN Y/O DATOS RECABADOS.
              <p>
                Los datos personales que proporcione directa o indirectamente
                cualquier persona física, al ingresar a este sitio web o debido
                a su vínculo comercial, laboral, de cliente, o de cualquier otra
                índole con esta empresa, tendrán el uso que en forma enunciativa
                pero no limitativa se describe a continuación:
              </p>
              <p>
                Para identificarle, ubicarle, comunicarle, contactarle, proveer
                servicio, dar seguimiento a las obligaciones contraídas,
                enviarle información y/o dar seguimiento a las relaciones
                comerciales, laborales y/o de cliente que motiven la transmisión
                de los relativos datos personales. El uso de los datos
                personales tendrá relación estricta con el tipo de interacción
                jurídica que usted tenga con la responsable, ya sea simplemente
                para regular la relación únicamente como usuario o visor de la
                página o plataformas web de la responsable o mediante la
                suscripción previa de contratos verbales o escritos e incluso,
                la solicitud de información o contacto que sostenga a través de
                este sitio web.
              </p>
              <p>
                La temporalidad de la autorización del manejo y uso de los datos
                personales será indefinida a partir de la fecha en que usted los
                proporcionó a la responsable a través de cualquier medio impreso
                y/o electrónico. En todo momento la responsable informará al
                titular sobre cualquier cambio en el presente aviso de
                privacidad.
              </p>
              <p>
                La responsable puede recopilar información personal para
                completar distintas transacciones, por ejemplo:
              </p>
              <ul>
                <li>Solicitudes de compra de productos o servicios.</li>
                <li>Cotizar servicios o productos.</li>
                <li>Identificación de titulares o personas.</li>
                <li>
                  Procesar pagos bancarios (nacionales e internacionales).
                </li>
                <li>Obtener la ubicación del usuario.</li>
                <li>Creación de perfiles y verificación de usuarios.</li>
                <li>Solicitudes de información de productos o servicios.</li>
                <li>Suscripciones de asistencia técnica o marketing.</li>
                <li>
                  Solicitudes de cualquier naturaleza relacionadas con los
                  productos o servicios que proporciona la responsable.
                </li>
              </ul>
              <p>
                {" "}
                Adicionalmente, la responsable podrá utilizar su información
                para:
              </p>
              <ul>
                <li>
                  Brindarle información actualizada sobre nuevos servicios,
                  productos y beneficios.
                </li>
                <li>
                  Brindarle información a través de un boletín de noticias;
                  inclusive enviarle correos electrónicos con dicho boletín.
                </li>
                <li>
                  Compartir ciertas información con terceros para el
                  cumplimiento de los servicios o para los demás usos de su
                  información que se mencionan en el presente; como lo podría
                  ser a un servicio o empresa de paquetería y/o logística o
                  gestores de cobro para el envío de mercancías.
                </li>
                <li>
                  Comunicarse con usted acerca de productos y servicios que de
                  acuerdo a su perfil de cliente puedan interesarle; y control
                  interno de clientes.
                </li>
                <li>Cerciorase de que sea mayor de edad.</li>
                <li>
                  Ayudarlo a completar una transacción o solicitud de un
                  producto o servicio.
                </li>
                <li>
                  Evitar y detectar amenazas a la seguridad, comportamientos
                  fraudulentos u otras actividades maliciosas que pudieran
                  afectar al titular o a la responsable.
                </li>
                <li>Ofrecer y mejorar el servicio y la asistencia técnica.</li>
                <li>Ofrecer promociones personalizadas.</li>
                <li>Seleccionar el contenido que se le comunicará.</li>
                <li>Personalizar los sitios web de la responsable.</li>
                <li>Permitirle participar en concursos y encuestas.</li>
                <li>
                  Ponerse en contacto con usted en relación con productos o
                  servicios de la responsable.
                </li>
                <li>
                  Investigaciones de cualquier índole relativa a la prospección
                  que como cliente se pueda hacer del titular de los datos
                  personales.
                </li>
                <li>
                  Perfeccionamiento de los servicios contratados con la
                  responsable.
                </li>
                <li>
                  Ofrecer o publicar oportunidades y ofertas laborales, de
                  orientación y/o capacitación.
                </li>
                <li>
                  Elaborar estudios, campañas publicitarias o de mercadotecnia,
                  programas y estadísticas que son necesarios para determinar la
                  preferencia de nuestros servicios y hábitos de consumo de los
                  usuarios y para ofertar nuestros productos y servicios a
                  nuestros clientes.
                </li>
                <li>
                  Brindar sus servicios, a título persona o de terceros, como a
                  las áreas de diseño y mercadotecnia; dichos terceros se acatan
                  a las mismas obligaciones y compromisos que la responsable
                </li>
                <li>
                  La evaluación, análisis, uso, manejo, aprovechamiento,
                  transferencia, disposición, divulgación, acceso y
                  almacenamiento por cualquier medio de los datos personales
                  proporcionados a través de cualquier instrumento jurídico, o
                  manifestación verbal o expresa de la voluntad, incluida la
                  interacción efectuada a través de este sitio web.
                </li>
              </ul>
              <p>
                Si publica, comenta, indica interés, se queja o comparte
                información personal, incluidas fotografías, en cualquier foro
                público de un sitio, perfil de red social, blog u otro foro de
                este tipo de la responsable, debe tener en cuenta que cualquier
                información que publique la podrán leer, ver, recabar o utilizar
                otros usuarios de dichos foros, y se podría utilizar para
                ponerse en contacto con usted, enviarle mensajes no solicitados
                o con fines que ni usted ni la responsable pueden controlar. La
                responsable no responderá por el uso o destino de la información
                personal que decida proporcionar en estos foros.
              </p>
            </li>
            <li>
              RECOPILACIÓN DE INFORMACIÓN Y/O DATOS A TRAVÉS DE MEDIOS
              AUTOMÁTICOS.
              <p>
                Además de la información que nos proporcione, la responsable
                también podrá recabar información durante su visita a nuestro
                sitio web a través de registros en diversas redes sociales como
                Facebook o Google, procesadores o prestadores de servicio de
                pago, pago fácil o a sistemas de pago electrónico, y de sus
                herramientas de recopilación de datos automática, entre las que
                se incluyen formularios, balizas web, cookies, web beacons,
                Google Analytics o Ads, cotizadores web y enlaces o aplicaciones
                web integradas. Dichas herramientas recopilan cierta información
                de comportamiento o tráfico que su navegador envía a un sitio
                web, como puede ser el tipo e idioma del navegador, las horas de
                acceso y la dirección del sitio web del que procede. También
                pueden recabar información acerca de su dirección de Protocolo
                de Internet (IP), de su identificador único de dispositivo, de
                dónde hace clic (es decir, las páginas web que visita, los
                enlaces en los que hace clic y otras acciones que lleva a cabo
                en relación con los sitios web de la responsable o administrados
                por ésta. La responsable puede utilizar también algunas de estas
                herramientas de recopilación de datos automática en relación con
                determinados mensajes de correo electrónico y/o comunicaciones
                enviadas desde este sitio web y por lo tanto, puede recopilar
                información mediante dichas herramientas cuando abra el correo
                electrónico o cuando haga clic en un enlace que aparezca en
                dicho correo. Los datos personales que obtenemos de estas
                tecnologías de rastreo son los siguientes: horario de
                navegación, tiempo de navegación en nuestra página de Internet,
                secciones consultadas, y páginas de Internet accedidas previo a
                la nuestra. De igual manera al visitar nuestro sitio web puede
                que le solicitemos y recopilemos otros datos personales como:
                edad, sexo o fecha de nacimiento. Con esta información
                elaboramos estadísticas sobre cuántos usuarios visitaron
                nuestros sitios web y a qué páginas accedieron. Al recopilar
                esta información, aprendemos a adaptar de la mejor manera
                nuestros productos y servicios.
              </p>
              <p>
                Los datos obtenidos mediante los archivos de registro descritos
                previamente no podrán ser transferidos a persona alguna distinta
                de esta empresa o de su personal laboral o directivo, para tal
                caso, estos últimos deberán ajustarse en el manejo de su
                información, al trato establecido en el presente aviso de
                privacidad.
              </p>
              <p>¿Cómo controlo qué cookies se colocan en mi dispositivo?</p>
              <p>
                Usted puede decidir si acepta o no cookies. Una manera de
                hacerlo es a través de la configuración de su navegador de
                Internet. La mayoría de los navegadores de Internet permiten
                cierto control de la mayor parte de los cookies por medio de la
                configuración del navegador (tenga presente que si utiliza la
                configuración de su navegador para bloquear todos los cookies,
                es posible que no pueda acceder a partes o funciones de nuestro
                cierto web.) Los siguientes sitios web brindan información sobre
                cómo ajustar la configuración de cookies en algunos navegadores
                populares:
              </p>
              <ul>
                <li>
                  <a
                    href="https://support.apple.com/es-es/HT201265"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apple Safari
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.google.com/accounts/answer/61416?hl=es-419"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.microsoft.com/es-es/windows"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Microsoft Internet Explorer
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mozilla Firefox
                  </a>
                </li>
              </ul>
              <p>
                Adobe Flash Player es un software para ver multimedios en una
                computadora. Los sitios web que utilizan Adobe Flash pueden
                almacenar cookies flash en los dispositivos del usuario para
                recordar parámetros, preferencias y usos similares a los de
                otros tipos de cookies. La responsable puede usar Flash para
                ofrecer contenidos especiales como clips de video o animaciones.
              </p>
              <p>
                Puede acceder a herramientas de administración de Flash
                directamente desde el sitio web de Adobe.
              </p>
              <p>
                Para rechazar los cookies de análisis de Google Analytics y
                Adobe Analytics en los sitios web de Intel, visite:
              </p>
              <p>Google Analytics:</p>
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>
              <p>Adobe Analytics:</p>
              <a
                href="https://www91.intel.com/optout.htmlt"
                target="_blank"
                rel="noreferrer"
              >
                https://www91.intel.com/optout.html
              </a>
              <p>
                Para obtener más información acerca de los cookies en general,
                lo que incluye qué cookies se han establecido y cómo
                administrarlos y eliminarlos, puede visitar a su discreción y
                completa responsabilidad:{" "}
                <a href="www.allaboutcookies.org" target="_blank">
                  www.allaboutcookies.org
                </a>
              </p>
            </li>
            <li>
              Derechos ARCO.
              <p>
                Usted tiene derecho a conocer qué datos personales tenemos
                registrados, para qué los utilizamos y las condiciones del uso
                que les damos (Acceso). Asimismo, es su derecho solicitar la
                corrección de su información personal en caso de que esté
                desactualizada, sea inexacta o incompleta (Rectificación); que
                la eliminemos de nuestros registros o bases de datos cuando
                considere que la misma no está siendo utilizada conforme a los
                principios, deberes y obligaciones previstas en la normativa
                (Cancelación); así como oponerse al uso de sus datos personales
                para fines específicos (Oposición). Estos derechos se conocen
                como derechos ARCO.
              </p>
              <p>
                Usted puede revocar el consentimiento que, en su caso, nos haya
                otorgado para el tratamiento de sus datos personales. Sin
                embargo, es importante que tenga en cuenta que no en todos los
                casos podremos atender su solicitud o concluir el uso de forma
                inmediata, ya que es posible que por alguna obligación legal
                requiramos seguir tratando sus datos personales. Asimismo, usted
                deberá considerar que para ciertos fines, la revocación de su
                consentimiento implicará que no le podamos seguir prestando el
                servicio que nos solicitó, o la conclusión de su relación con
                nosotros.
              </p>
              <p>
                En caso que dese ejercer sus derechos de acceso, rectificación,
                cancelación u oposición (ARCO) respecto de cualquier dato o
                información personal que nos hubiese proporcionado, estos podrán
                ejercerse en todo momento dirigiendo su solicitud a alguna de
                las siguientes direcciones o medios de contacto:
              </p>
              <p>
                Correo electrónico:{" "}
                <a href="mailto:info@dammannmexique.com">
                  info@dammannmexique.com
                </a>
              </p>
              <p>
                Por correo al siguiente domicilio: Cerrada de Constituyentes 25
                Col. Lomas Altas C.P 1950 CDMX.
              </p>
              <p>
                Días y horario de atención: Lunes a viernes de 7:30AM a 5:00PM
              </p>
              <p>
                Para lo anterior, deberá hacernos saber fehacientemente los
                datos personales que usted desea sean rectificados, cancelados o
                revisados, así como el propósito para el cual los aportó y en su
                caso el nombre del Responsable a quien se los entregó y en
                general cumplir los requisitos mencionados en el artículo 29 de
                la Ley Federal de Protección de Datos Personales en Posesión de
                Particulares.
              </p>
              <p>
                Para el ejercicio de sus derechos de acceso, rectificación,
                cancelación u oposición, deberá integrar su petición con los
                siguientes requisitos:
              </p>
              <ul>
                <li>
                  Petición firmada de su puño y letra, clara y concisa sobre los
                  datos que desea modificar, ya sea en su versión impresa o en
                  su caso digitalizada.
                </li>
                <li>
                  Copia de una identificación oficial, en versión impresa o
                  digitalizada.
                </li>
              </ul>
              <p>
                Cualquier situación, aclaración o duda sobre la interpretación o
                cumplimiento de este aviso de privacidad, así mismo cualquier
                situación relacionada con el ejercicio de sus derechos puede
                hacérnosla saber a través de los medios que han quedado
                previamente señalados.
              </p>
              <p>
                Le informamos que la responsable no realiza ordinariamente
                transferencia de datos con algún tercero salvo para los casos en
                lo que sea estrictamente necesario para la realización de algún
                servicio o producto solicitado a la responsable. Dicho tercero
                estará obligado independientemente y, en su coso, solidariamente
                a dar el tratamiento correspondiente a los datos personales que
                a los cuales tengo acceso.
              </p>
              <p>
                ¿Cómo puede limitar el uso o divulgación de su información
                personal?
              </p>
              <p>
                Con objeto de que usted pueda limitar el uso y divulgación de su
                información personal, le ofrecemos los siguientes medios:
              </p>
              <p>
                Su inscripción en el Registro Público para Evitar Publicidad,
                que está a cargo de la Procuraduría Federal del Consumidor, con
                la finalidad de que sus datos personales no sean utilizados para
                recibir publicidad o promociones de empresas de bienes o
                servicios. Para mayor información sobre este registro, usted
                puede consultar el portal de Internet de la PROFECO, o bien
                ponerse en contacto directo con ésta.
              </p>
              <p>
                La responsable podrá modificar el presente aviso de privacidad
                en cualquier tiempo y lo mantendrá siempre a disposición por
                este conducto para su consulta.
              </p>
            </li>
            <li>
              CONTROL Y SEGURIDAD
              <p>
                La responsable se compromete a tomar las medidas necesarias para
                proteger la información recopilada, utilizando tecnologías de
                seguridad y procedimientos de control en el acceso, uso o
                divulgación de su información personal sin autorización, por
                ejemplo, almacenando la información personal proporcionada en
                servidores ubicados en Centros de Datos que cuentan con
                controles de acceso limitado. Para solicitudes en línea,
                utilizamos también tecnologías de seguridad que protegen la
                información personal que nos sea transmitida a través de los
                diversos medios electrónicos; sin embargo, ningún sistema de
                seguridad o de transmisión de datos del cual el Responsable no
                tenga el control absoluto y/o tenga dependencia con internet
                puede garantizar que sea totalmente seguro.
              </p>
              <p className="mt-2">
                De igual modo, la responsable, sus empleados, representantes,
                subcontratistas, consultores y/o los terceros que intervengan en
                cualquier fase del tratamiento de los Datos Personales y/o Datos
                Personales Patrimoniales y Financieros del Titular deberán
                guardar confidencialidad respecto de éstos, obligación que
                subsistirá aún después de finalizar la relación entre la
                organización con el Titular.
              </p>
            </li>
            <li>
              MODIFICACIONES AL PRESENTE AVISO DE PRIVACIDAD
              <p>
                La responsable se reserva el derecho de efectuar en cualquier
                momento modificaciones o actualizaciones al presente Aviso de
                Privacidad, en atención a novedades legislativas o políticas
                internas.
              </p>
              <p>
                Sin embargo persiste el compromiso de mantenerlo informado sobre
                los cambios que pueda sufrir el presente aviso de privacidad, en
                caso de que esto suceda, será publicado en la propia página web.
              </p>
            </li>
            <li>
              ACEPTACIÓN E INFORMACION ADICIONAL.
              <p>
                Mediante la aceptación del presente Aviso de Privacidad usted,
                el titular, otorga su consentimiento para que sus datos
                personales, financieros y/o patrimoniales sean tratados conforme
                a lo señalado en el presente documento.
              </p>
              <p>
                Si usted considera que su derecho a la protección de sus datos
                personales ha sido lesionado por alguna conducta u omisión de
                nuestra parte, o presume alguna violación a las disposiciones
                previstas en la Ley Federal de Protección de Datos Personales en
                Posesión de los Particulares, su Reglamento y demás
                ordenamientos aplicables, podrá interponer su inconformidad o
                denuncia ante el Instituto Federal de Acceso a la Información y
                Protección de Datos (IFAI). Para mayor información, le sugerimos
                visitar su página oficial de Internet{" "}
                <a href=" www.ifai.org.mx" target="_blank" rel=" noreferrer">
                  {" "}
                  www.ifai.org.mx
                </a>
                .
              </p>
            </li>
          </ol>
        </Container>
      </div>
    </section>
  );
}

export default Policies;
