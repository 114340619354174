import { useState, useContext } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom components
import PaymentForm from "../components/PaymentForm/PaymentForm";

// Context
import { useCart } from "../contexts/CartContext";
import { useAuth } from "../contexts/AuthContext";
import AlertContext from "../contexts/AlertContext";

// Custom styles
import "../scss/pages/checkout.styles.scss";

// Utils
import formatMoney from "../utils/formatMoney";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Assets
// import productTest from "../assets/img/product1.jpg";
import deleteIcon from "../assets/img/delete-icon--grey.svg";

const stripePromise = loadStripe(
  "pk_live_51M4wnQHRRaTSVto0287oq5CpesppY2A8YlxpCNPJjniQQxGkmel5UAmlkQ9pTCqCjVMUOFuxZXkMKLxzXShxZB4l00hZTj0KDY",
  // "pk_test_51M4wnQHRRaTSVto0mTgypLo1XLGKFYCkqOLjBA9Xp1UHxIQCOzo8M5HJ1jHgIJgcgrGWR6K2imev126FUz5NLg7x0091xckgdN",
  {
    locale: "es-419",
  }
);

function Checkout() {
  const [loading, setLoading] = useState(false);
  const {
    cartItems,
    totalItems,
    totalPriceCart,
    authRemoveProduct,
    setCartItems,
    guestRemoveProduct,
    emptyUserCart,
    guestEmptyCart,
    authEmptyCart,
    setTotalPriceCart,
    hasAchievedNationalPromotion,
    setHasAppliedCoupon,
    hasAppliedCoupon,
    originalPriceCart,
  } = useCart();
  const { currentUser } = useAuth();

  const [shipment, setShipment] = useState(0);

  const alert = useContext(AlertContext);

  const [coupon, setCoupon] = useState(0);
  const [couponValue, setCouponValue] = useState("");
  const [success, setSuccess] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  // useEffect(() => {

  // }, [categories.length, subcategories.length]);
  return (
    <section className="checkout__wrapper">
      <div className={`overlay ${loading ? "show" : null}`}></div>
      <div className="checkout__container">
        <Container fluid>
          <Row>
            <Col
              xs="12"
              lg="7"
              xl="8"
              className="order-2 order-lg-1 mt-3 mt-lg-0"
            >
              <div className="checkout__container__form">
                <h5>completar pago</h5>
                <p>
                  Para completar tu orden, por favor llena todos los campos a
                  continuación:
                </p>
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    cartItems={cartItems}
                    loading={loading}
                    setLoading={setLoading}
                    totalPriceCart={totalPriceCart}
                    shipment={shipment}
                    setShipment={setShipment}
                    currentUser={currentUser}
                    emptyUserCart={emptyUserCart}
                    guestEmptyCart={guestEmptyCart}
                    authEmptyCart={authEmptyCart}
                    hasAchievedNationalPromotion={hasAchievedNationalPromotion}
                    coupon={coupon}
                    setHasAppliedCoupon={setHasAppliedCoupon}
                    hasAppliedCoupon={hasAppliedCoupon}
                  />
                </Elements>
              </div>
            </Col>
            <Col xs="12" lg="5" xl="4" className="order-1 order-lg-2">
              <div className="checkout__container__details">
                <div className="checkout__container__summary">
                  <h5>
                    tu orden <span>{totalItems}</span>
                  </h5>
                  <div className="checkout__container__summary__products-list">
                    {cartItems.map((cartItem, key) => (
                      <div className="product" key={key}>
                        <div className="product__media">
                          <img
                            // src={
                            //   cartItem.attributes.productos.data[0].attributes
                            //     .imagenes.data[0].attributes.url
                            // }
                            src={`${cartItem.attributes.productos.data[0].attributes.imagenes.data[0].attributes.url}`}
                            alt={
                              cartItem.attributes.productos.data[0].attributes
                                .nombre
                            }
                          />
                        </div>
                        <div className="product__details">
                          <div className="d-flex flex-column justify-content-between">
                            <p>
                              {
                                cartItem.attributes.productos.data[0].attributes
                                  .nombre
                              }
                            </p>
                            <h6>
                              {formatMoney(
                                cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                                  (currentPresentation) =>
                                    currentPresentation.Presentacion ===
                                    cartItem.attributes.selectedPresentation
                                )?.precio -
                                  (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                                    (currentPresentation) =>
                                      currentPresentation.Presentacion ===
                                      cartItem.attributes.selectedPresentation
                                  )?.precio *
                                    cartItem.attributes.productos.data[0]
                                      .attributes.PresentacionesDisponibles[
                                      cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                        (presentation) =>
                                          presentation.Presentacion ===
                                          cartItem.attributes
                                            .selectedPresentation
                                      )
                                    ]?.descuento) /
                                    100
                              )}
                            </h6>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="product__details__quantity">
                              {/* <button>-</button>
                            <span>1</span>
                            <button>+</button> */}
                              <span>
                                Cantidad: {cartItem.attributes.cantidad}
                              </span>
                            </div>
                            <button
                              className="product__details__cta"
                              onClick={() => {
                                setLoading(true);
                                if (currentUser.jwt) {
                                  authRemoveProduct(
                                    currentUser.user.id,
                                    cartItem.attributes.productos.data[0].id,
                                    currentUser.jwt,
                                    cartItem.id
                                  );
                                  setCartItems((prev) =>
                                    prev.filter(
                                      (item) => item.id !== cartItem.id
                                    )
                                  );
                                  setLoading(false);
                                } else if (currentUser.guest === true) {
                                  // console.log("es de guest");
                                  guestRemoveProduct(cartItem);
                                  setLoading(false);
                                }
                              }}
                            >
                              <img src={deleteIcon} alt="Dammann" />
                            </button>
                          </div>
                          <div className="d-flex product__details__info">
                            <p className="cart-product__details__presentation">
                              {cartItem.attributes.selectedPresentation !==
                              "Accesorio" ? (
                                <span>Presentación seleccionada:</span>
                              ) : (
                                <></>
                              )}
                              {cartItem.attributes.selectedPresentation ===
                                "Sachet20" && "Sachet 20 piezas"}
                              {cartItem.attributes.selectedPresentation ===
                                "Sachet" && "Sachet 24 piezas"}
                              {cartItem.attributes.selectedPresentation ===
                                "Sachet25" && "Sachet 25 piezas"}
                              {cartItem.attributes.selectedPresentation ===
                                "Lata20" && "Lata 20 gr."}
                              {cartItem.attributes.selectedPresentation ===
                                "Lata35" && "Lata 35 gr."}
                              {cartItem.attributes.selectedPresentation ===
                                "Lata60" && "Lata 60 gr."}
                              {cartItem.attributes.selectedPresentation ===
                                "Lata90" && "Lata 90 gr."}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="checkout__container__summary__products__summary">
                    <div className="d-flex justify-content-between">
                      <span>Subtotal:</span>
                      <p>{formatMoney(totalPriceCart)}</p>
                    </div>

                    <div className="d-flex justify-content-between">
                      <span>Descuento(s):</span>
                      <p>
                        {cartItems.reduce(function (tally, cartItem) {
                          return (
                            tally +
                            (cartItem.attributes.cantidad *
                              (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                                (currentPresentation) =>
                                  currentPresentation.Presentacion ===
                                  cartItem.attributes.selectedPresentation
                              )?.precio *
                                cartItem.attributes.productos.data[0].attributes
                                  .PresentacionesDisponibles[
                                  cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                    (presentation) =>
                                      presentation.Presentacion ===
                                      cartItem.attributes.selectedPresentation
                                  )
                                ]?.descuento)) /
                              100
                          );
                        }, 0) > 0 ? (
                          <>
                            -
                            {formatMoney(
                              cartItems.reduce(function (tally, cartItem) {
                                return (
                                  tally +
                                  (cartItem.attributes.cantidad *
                                    (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                                      (currentPresentation) =>
                                        currentPresentation.Presentacion ===
                                        cartItem.attributes.selectedPresentation
                                    )?.precio *
                                      cartItem.attributes.productos.data[0]
                                        .attributes.PresentacionesDisponibles[
                                        cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                                          (presentation) =>
                                            presentation.Presentacion ===
                                            cartItem.attributes
                                              .selectedPresentation
                                        )
                                      ]?.descuento)) /
                                    100
                                );
                              }, 0)
                            )}
                          </>
                        ) : (
                          formatMoney(0)
                        )}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Envío:</span>
                      {/* {totalPriceCart > 800 ? (
                      <p>Tu envío es gratis</p>
                    ) : (
                      <p>{formatMoney(shipment)}</p>
                    )} */}
                      <p>{formatMoney(shipment)}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Total:</span>
                      <p>{formatMoney(totalPriceCart + shipment)}</p>
                    </div>
                    {hasAppliedCoupon && coupon > 0 ? (
                      <div className="d-flex justify-content-center">
                        <p
                          style={{ marginBottom: "-1rem" }}
                        >{`Descuento de -${coupon}% aplicado.`}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
                {hasAppliedCoupon === false ? (
                  <div className="checkout__container__coupon">
                    <p>¿Tienes un cupón de descuento?</p>
                    <div>
                      <input
                        type="text"
                        className="payment__summary__promo-code__input"
                        placeholder="Código de descuento"
                        value={couponValue.toUpperCase()}
                        onChange={(e) =>
                          setCouponValue(e.target.value.toUpperCase())
                        }
                      />
                      <button
                        className="payment__summary__promo-code__button"
                        onClick={async () => {
                          if (couponValue !== "") {
                            try {
                              const url = `${process.env.REACT_APP_API_URL}/api/cupones?filters[nombre][$eq]=${couponValue}`;
                              const response = await fetch(url);
                              const result = await response.json();
                              // return result;
                              if (result.data.length > 0) {
                                setCoupon(result.data[0].attributes.descuento);
                                setHasAppliedCoupon(true);
                                setTotalPriceCart(
                                  totalPriceCart -
                                    totalPriceCart *
                                      (result.data[0].attributes.descuento /
                                        100)
                                );
                                setSuccess(true);
                                alert.success("Cupón aplicado exitosamente.");
                                setIsSubmited(true);
                                // if (coupon > 0) {
                                // }
                              } else {
                                setIsSubmited(true);
                                setSuccess(false);
                                setCouponValue("");
                                setHasAppliedCoupon(false);
                                setTimeout(() => {
                                  setIsSubmited(false);
                                }, 3000);
                                return;
                              }

                              // setTimeout(() => {
                              //   setSuccess(false);
                              // }, 3000);
                            } catch (error) {
                              console.log(error);
                            }
                          } else {
                            setIsSubmited(true);
                            setSuccess(false);
                            setTimeout(() => {
                              setIsSubmited(false);
                            }, 3000);
                          }
                        }}
                      >
                        <p className="payment__summary__promo-code__button__text">
                          Aplicar
                        </p>
                      </button>
                    </div>
                    {!success && isSubmited ? (
                      <span className="coupon coupon-error">
                        Cupón no válido
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Checkout;
