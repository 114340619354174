import { useState, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

// Context
import AlertContext from "../contexts/AlertContext";

// Custom styles
import "../scss/pages/recoverPassword.scss";

// API
import { resetPassword } from "../api/auth";

// Custom components
import ReCAPTCHA from "react-google-recaptcha";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const initialValues = {
  password: "",
  passwordConfirmation: "",
};

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir")
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
});

function RecoverPassword() {
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [error, setError] = useState("");

  const captcha = useRef(null);
  const alert = useContext(AlertContext);
  const history = useHistory();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const code = query.get("code");

  const onChange = (value) => {
    setError(false);
    // console.log("Captcha value:", value);
  };
  return (
    <section className="recover-password__wrapper">
      <Container>
        <div className="recover-password__container">
          <h4>Restablecer contraseña</h4>
          <p className="mb-3 mb-lg-0">
            Ingresa a continuación la nueva contraseña para tu cuenta
          </p>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              values.code = code;
              const response = await resetPassword(values);
              if (response.error?.status === 403) {
                alert.error(
                  "Error en la solicitud. Por favor intenta de nuevo."
                );
              } else if (
                response.error?.status === 400 &&
                response.error?.message === "Incorrect code provided"
              ) {
                alert.error(
                  "El código del cambio ya fue utilizado, por favor solicita uno nuevo."
                );
                history.push("/forgot-password");
              } else if (response.error?.status === 400) {
                alert.error(
                  "El código del cambio no existe, por favor solicita uno nuevo."
                );
                history.push("/forgot-password");
              } else if (response.jwt) {
                alert.success(
                  "Tu contraseña ha sido cambiada. En unos segundos serás redirigido a la página de inicio de seión."
                );
                setTimeout(() => {
                  history.push("/login");
                }, 3000);
              }

              resetForm(initialValues);
              captcha.current.reset();
              // if (captcha.current.getValue()) {
              //   setResetPasswordLoading(true);
              //   const response = await resetPassword(
              //     oobCode,
              //     values.newPassword
              //   );
              //   // console.log(response);
              //   if (response.status === 200) {
              //     history.push("/login");
              //     alert.success(
              //       "Contraseña restablecida. Por favor inicia sesión."
              //     );
              //     setResetPasswordLoading(false);
              //     resetForm({
              //       newPassword: "",
              //     });
              //     captcha.current.reset();
              //     return;
              //   } else {
              //     response.json().then((json) => {
              //       // alert.error(json);
              //       if (json.error === "INVALID_OOB_CODE") {
              //         alert.error(
              //           "El código de recuperación ya expiró. Vuelve a solicitar el cambio de contraseña."
              //         );
              //       } else {
              //         alert.error(
              //           "Hubo un error en tu solicitud. Vuelve a solicitar el cambio de contraseña."
              //         );
              //       }
              //     });
              //     // console.log("error");
              //     // alert.error("Error en tu solicitud.");
              //     setResetPasswordLoading(false);
              //     resetForm({
              //       newPassword: "",
              //     });
              //     captcha.current.reset();
              //     return;
              //   }
              // } else {
              //   alert.error("Por favor completa el captcha");
              // }
            }}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mt-3" controlId="passwordInput">
                  <Form.Control
                    type="password"
                    placeholder="Escribe aquí la nueva contraseña"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password || touched.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-3" controlId="confirmPasswordInput">
                  <Form.Control
                    type="password"
                    placeholder="Repite la contraseña"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.passwordConfirmation &&
                      !!errors.passwordConfirmation
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passwordConfirmation ||
                      touched.passwordConfirmation}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="re-captcha text-center">
                  <ReCAPTCHA
                    sitekey="6Le97hwjAAAAABgehJBi8UGDYphi-P9GeKGT7QsG"
                    onChange={onChange}
                    ref={captcha}
                  />
                </div>
                <button disabled={resetPasswordLoading} type="submit">
                  {resetPasswordLoading ? "Validando..." : "Restablecer"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
}

export default RecoverPassword;
