import { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// Custom styles
import "../scss/pages/guest.styles.scss";

// Context
import AlertContext from "../contexts/AlertContext";
import { useAuth } from "../contexts/AuthContext";
import { useCart } from "../contexts/CartContext";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const initialValues = {
  identifier: "",
  password: "",
};

const schema = yup.object().shape({
  identifier: yup
    .string()
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
  password: yup.string().required("Este campo es requerido"),
});

function Guest() {
  const [loading, setLoading] = useState(false);
  const { setUser, loginUser, currentUser } = useAuth();
  const history = useHistory();

  const alert = useContext(AlertContext);
  const { addProductToCart, cartItems } = useCart();

  useEffect(() => {
    if (!!currentUser.jwt || !!currentUser.guest) {
      history.push("/cart");
      // console.log("no hay usuario");
    }
  }, [currentUser]);

  return (
    <section className="guest__wrapper">
      <div className="guest__container">
        <Container>
          <Row>
            <Col xs="12" md="6">
              <div className="guest__container__guest-option">
                <h4>Nuevos clientes</h4>
                <p>
                  Si todavía no tienes una cuenta, puedes continuar tu compra
                  sin registrarte para hacerlo después, tenemos muchos
                  beneficios para nuestros clientes registrados.
                </p>
                <button
                  onClick={() => {
                    setUser({ guest: true });
                    localStorage.setItem(
                      "user",
                      JSON.stringify({ guest: true })
                    );
                    // guestAddFuProductToCart(
                    //   cartItems[0].attributes.productos.data[0],
                    //   cartItems[0].attributes.productos.data[0].id,
                    //   cartItems[0].attributes.selectedPresentation,
                    //   cartItems[0].attributes.cantidad
                    // );
                    alert.success("Producto añadido a tu bolsa de compra");
                    history.push("/cart");
                    // history.goBack();
                  }}
                >
                  Continuar como invitado
                </button>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="guest__container__user-option">
                <h4>Clientes existentes</h4>
                <p>
                  Inicia sesión para acelerar el proceso de pago y guardar tu
                  compra en el historial de tu cuenta.
                </p>
                <Formik
                  validationSchema={schema}
                  initialValues={initialValues}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setLoading(true);
                    const response = await loginUser(values);
                    if (response.jwt) {
                      alert.success("Inicio de sesión exitoso.");
                      const addProductAuthResponse = await addProductToCart(
                        response.user.id,
                        cartItems[0].attributes.productos.data[0],
                        cartItems[0].attributes.cantidad,
                        response.jwt,
                        cartItems[0].attributes.selectedPresentation
                      );
                      if (addProductAuthResponse.status === 200) {
                        // setCartItems([]);
                        console.log("200");
                        history.push("/cart");
                        alert.success("Producto añadido a tu bolsa de compra");
                      } else {
                        alert.error(
                          "El producto no pudo ser añadido a tu bolsa de compra"
                        );
                      }

                      resetForm(initialValues);
                    } else if (
                      response.error.message ===
                      "Invalid identifier or password"
                    ) {
                      alert.error("Email o contraseña incorrectos.");
                      setLoading(false);
                      resetForm(initialValues);
                    } else {
                      alert.error("Error al iniciar sesión.");
                      setLoading(false);
                      resetForm(initialValues);
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Escribe aquí tu correo"
                          name="identifier"
                          value={values.identifier}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.identifier && !!errors.identifier}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.identifier || touched.identifier}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Contraseña:</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Contraseña"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.password && !!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password || touched.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Link
                        to="/forgot-password"
                        className="d-block text-center"
                      >
                        ¿Olvidaste tu contraseña?
                      </Link>
                      <button type="submit">
                        {loading ? "Iniciando sesión..." : "Iniciar sesión"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Guest;
