import { createContext, useContext, useState, useEffect } from "react";

import { login } from "../api/auth";

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export function AuthProvider({ children }) {
  // const user = {
  //   login: false,
  // };

  const [currentUser, setUser] = useState({ guest: false });
  // const [authError, setAuthError] = useState("");

  const loginUser = async (values) => {
    try {
      const response = await login(values);
      if (response.jwt) {
        delete response.user.blocked;
        delete response.user.confirmed;
        delete response.user.createdAt;
        delete response.user.provider;
        setUser(response);
        localStorage.setItem("user", JSON.stringify(response));
        // response.json().then((json) => {
        //   setUser(json);
        //   const token = json;
        //   if (token) {
        //     delete token.idToken;
        //     delete token.kind;
        //     delete token.refreshToken;
        //     localStorage.setItem("user", JSON.stringify(token));
        //   }
        // });
        return response;
      }
      // else {
      //   console.log("aquí es", response);
      //   response.json().then((json) => {
      //     setAuthError(json.error);
      //   });
      //   return authError;
      // }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.removeItem("user");
    setUser({ guest: false });
  };

  const isAuthenticated = () => {
    const currentUser = localStorage.getItem("user");
    if (!currentUser) {
      return {};
    }
    return JSON.parse(currentUser);
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      let cuser = isAuthenticated();
      if (cuser === null) {
        // localStorage.setItem("user", { guest: true });
        cuser = { guest: true };
      }

      setUser(cuser);
    };

    checkLoggedIn();
  }, []);

  // console.log("usercontext", user);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setUser,
        loginUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
