import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom styles
import "../scss/pages/catalogue.styles.scss";

// API
import { getCategoryProducts } from "../api/product";

// Custom components
// import ProductCard from "../components/ProductCard/ProductCard";

// Utils
import formatMoney from "../utils/formatMoney";
import Slider from "react-slick";

// Assets
// import productTest from "../assets/img/product1.jpg";
// import favoriteIcon from "../assets/img/favorite-icon.svg";
// import shoppingBagIcon from "../assets/img/shopping-bag-icon.svg";

const settings = {
  arrows: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function CatalogueCategory() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getCategoryProducts(slug);
      if (response.data) {
        setProducts(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, [slug]);

  return (
    <section className="catalogue__wrapper">
      <div className="catalogue__hero">
        <Container fluid>
          <h3>
            {slug === "accesorios" && "accesorios"}
            {slug === "tes" &&
              `nuestros ${slug.split("-").join(" ").replace("tes", "tés")}`}
            {slug === "tisanas" && `nuestras ${slug.split("-").join(" ")}`}
          </h3>
          <p>{slug === "tisanas" ? "Infusiones libres de teína." : null}</p>
        </Container>
      </div>
      <div className="catalogue__products">
        <Container fluid>
          <Row xs={12} md={12} lg={4} xl={4} xxl={5} className="g-3">
            {loading ? (
              <p>Cargando productos...</p>
            ) : (
              products?.map((product, key) => (
                <Col key={key} xs="6" lg="4" xxl="3">
                  <div className="product-card">
                    {product.attributes.descuento > 0 ? (
                      // <span className="product-card__discount-label">
                      //   producto en descuento
                      // </span>
                      <></>
                    ) : (
                      <></>
                    )}
                    {/* <span className="product-card__discount-label">
                      {product.attributes.descuento > 0 ? (
                        `-${product.attributes.descuento} % descuento`
                      ) : (
                        <></>
                      )}
                    </span> */}
                    <Link to={`/product/${product.attributes.slug}`} key={key}>
                      <div className="product-card__media">
                        {/* <img
                          src={
                            product.attributes?.imagenes.data[0].attributes.url
                          }
                          // src={`${process.env.REACT_APP_API_URL}${product.attributes?.imagenes.data[0].attributes.url}`}
                          alt={product.attributes?.nombre}
                        /> */}
                        <Slider {...settings}>
                          {product.attributes?.imagenes.data?.map(
                            (image, key) => (
                              // <img
                              //   key={key}
                              //   src={image.attributes?.url}
                              //   // src={`${process.env.REACT_APP_API_URL}${image.attributes?.url}`}
                              //   alt={product.attributes?.nombre}
                              // />
                              <img
                                key={key}
                                src={image.attributes.url}
                                // src={`${product.attributes?.imagenes.data.attributes.url}`}
                                alt={product.attributes?.nombre}
                              />
                            )
                          )}
                        </Slider>
                      </div>
                      <div className="product-card__body">
                        {product.attributes?.tipoProducto === "Accesorio" ? (
                          <div className="accessory-border"></div>
                        ) : null}
                        <h5 className="product-card__body__product-name">
                          {product.attributes?.nombre}
                        </h5>
                        <p className="product-card__body__product-description">
                          {product.attributes?.descripcion}
                        </p>
                        {product.attributes?.tipoProducto !== "Accesorio" &&
                        product.attributes?.InformacionTe?.NotasDominantes !==
                          null &&
                        product.attributes?.InformacionTe?.SaborPrincipal !==
                          null &&
                        product.attributes?.InformacionTe !== null ? (
                          <>
                            <div className="product-card__body__product-details">
                              <p>
                                <span>Notas dominantes:</span>
                                {
                                  product.attributes?.InformacionTe
                                    .NotasDominantes
                                }
                              </p>
                              <p>
                                <span>
                                  {product.attributes?.tipoProducto !== "Té"
                                    ? "Tipo de tisana:"
                                    : "Tipo de té:"}
                                </span>
                                {
                                  product.attributes?.subcategoria.data
                                    .attributes.nombre
                                }
                              </p>
                              <p>
                                <span>Sabor principal:</span>
                                {
                                  product.attributes?.InformacionTe
                                    .SaborPrincipal
                                }
                              </p>
                            </div>
                          </>
                        ) : null}
                        {/* <div className="product-card__body__product-price">
                          {product.attributes.descuento > 0 &&
                          product.attributes?.tipoProducto !== "Accesorio" &&
                          product.attributes?.PresentacionesDisponibles[0]
                            .descuento > 0 ? (
                            <div className="product-card__body__product-price-prices">
                              <div className="d-flex">
                                {product.attributes?.PresentacionesDisponibles.findIndex(
                                  (item) =>
                                    item.Presentacion === "Sachet" ||
                                    item.Presentacion === "Sachet25" ||
                                    item.Presentacion === "Sachet20"
                                ) !== -1 ? (
                                  <>
                                    <p
                                      style={{
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {formatMoney(
                                        product.attributes
                                          ?.PresentacionesDisponibles[0].precio
                                      )}{" "}
                                    </p>
                                    <span>{`- ${
                                      product.attributes
                                        ?.PresentacionesDisponibles[
                                        product.attributes?.PresentacionesDisponibles.findIndex(
                                          (presentation) =>
                                            presentation.Presentacion ===
                                              "Sachet" ||
                                            presentation.Presentacion ===
                                              "Sachet25" ||
                                            presentation.Presentacion ===
                                              "Sachet20"
                                        )
                                      ].descuento
                                    } % descuento`}</span>
                                  </>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {formatMoney(
                                        product.attributes
                                          ?.PresentacionesDisponibles[0].precio
                                      )}{" "}
                                    </p>
                                    <span>{`- ${product.attributes?.PresentacionesDisponibles[0].descuento} % descuento`}</span>
                                  </>
                                )}
                              </div>

                              <p className="mt-lg-2">
                                {product.attributes?.PresentacionesDisponibles.findIndex(
                                  (item) =>
                                    item.Presentacion === "Sachet" ||
                                    item.Presentacion === "Sachet25" ||
                                    item.Presentacion === "Sachet20"
                                ) !== -1 ? (
                                  <>
                                    {formatMoney(
                                      product.attributes
                                        ?.PresentacionesDisponibles[0].precio -
                                        (product.attributes
                                          ?.PresentacionesDisponibles[
                                          product.attributes?.PresentacionesDisponibles.findIndex(
                                            (presentation) =>
                                              presentation.Presentacion ===
                                                "Sachet" ||
                                              presentation.Presentacion ===
                                                "Sachet25" ||
                                              presentation.Presentacion ===
                                                "Sachet20"
                                          )
                                        ].precio *
                                          product.attributes
                                            ?.PresentacionesDisponibles[
                                            product.attributes?.PresentacionesDisponibles.findIndex(
                                              (presentation) =>
                                                presentation.Presentacion ===
                                                  "Sachet" ||
                                                presentation.Presentacion ===
                                                  "Sachet25" ||
                                                presentation.Presentacion ===
                                                  "Sachet20"
                                            )
                                          ].descuento) /
                                          100
                                    )}

                                    <span>
                                      {product.attributes?.PresentacionesDisponibles[0].Presentacion.includes(
                                        "Lata"
                                      )
                                        ? product.attributes
                                            ?.PresentacionesDisponibles[0]
                                            .Presentacion === "Lata20"
                                          ? "Lata 20 gr."
                                          : product.attributes
                                              ?.PresentacionesDisponibles[0]
                                              .Presentacion === "Lata45"
                                          ? "Lata 45 gr."
                                          : "Lata 100 gr."
                                        : product.attributes
                                            ?.PresentacionesDisponibles[0]
                                            .Presentacion === "Sachet25"
                                        ? "25 sachets"
                                        : product.attributes
                                            ?.PresentacionesDisponibles[0]
                                            .Presentacion === "Sachet20"
                                        ? "20 sachets"
                                        : "24 sachets"}
                                    </span>
                                  </>
                                ) : (
                                  formatMoney(
                                    product.attributes
                                      ?.PresentacionesDisponibles[0].precio -
                                      (product.attributes
                                        ?.PresentacionesDisponibles[0].precio *
                                        product.attributes
                                          ?.PresentacionesDisponibles[0]
                                          .descuento) /
                                        100
                                  )
                                )}
                              </p>
                            </div>
                          ) : product.attributes?.tipoProducto !==
                            "Accesorio" ? (
                            <p>
                              {formatMoney(
                                product.attributes?.PresentacionesDisponibles[0]
                                  .precio -
                                  (product.attributes
                                    ?.PresentacionesDisponibles[0].precio *
                                    product.attributes.descuento) /
                                    100
                              )}{" "}
                              <span>
                                {product.attributes?.PresentacionesDisponibles[0].Presentacion.includes(
                                  "Lata"
                                )
                                  ? product.attributes
                                      ?.PresentacionesDisponibles[0]
                                      .Presentacion === "Lata20"
                                    ? "Lata 20 gr."
                                    : product.attributes
                                        ?.PresentacionesDisponibles[0]
                                        .Presentacion === "Lata45"
                                    ? "Lata 45 gr."
                                    : "Lata 100 gr."
                                  : product.attributes
                                      ?.PresentacionesDisponibles[0]
                                      .Presentacion === "Sachet25"
                                  ? "25 sachets"
                                  : product.attributes
                                      ?.PresentacionesDisponibles[0]
                                      .Presentacion === "Sachet20"
                                  ? "20 sachets"
                                  : "24 sachets"}
                              </span>
                            </p>
                          ) : null}
                        </div> */}
                        {/* <p>
                                {formatMoney(
                                  product.attributes
                                    ?.PresentacionesDisponibles[0].precio -
                                    (product.attributes
                                      ?.PresentacionesDisponibles[0].precio *
                                      product.attributes.descuento) /
                                      100
                                )}{" "}
                                /sachet
                              </p> */}
                        {/* {product.attributes?.tipoProducto === "Accesorio" ? (
                          product.attributes.descuento > 0 ? (
                            <>
                              <div className="product-card__body__product-price">
                                <div className="product-card__body__product-price-prices">
                                  <div className="d-flex">
                                    <p
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {formatMoney(
                                        product.attributes
                                          ?.PresentacionesDisponibles[0].precio
                                      )}{" "}
                                    </p>
                                    <span>{`- ${product.attributes?.PresentacionesDisponibles[0].descuento} % descuento`}</span>
                                  </div>
                                  <p>
                                    {formatMoney(
                                      product.attributes
                                        ?.PresentacionesDisponibles[0].precio -
                                        (product.attributes
                                          ?.PresentacionesDisponibles[0]
                                          .precio *
                                          product.attributes
                                            ?.PresentacionesDisponibles[0]
                                            .descuento) /
                                          100
                                    )}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="product-card__body__product-price">
                                <p>
                                  {formatMoney(
                                    product.attributes
                                      ?.PresentacionesDisponibles[0].precio
                                  )}
                                </p>
                              </div>
                            </>
                          )
                        ) : (
                          <></>
                        )} */}
                        <div className="product-card__cta mt-auto">
                          {/* <button
                        onClick={() => console.log("Yo this was clicked")}
                        >
                        <span>Añadir a la bolsa </span>
                        <img src={shoppingBagIcon} alt="Añadir a la bolsa" />
                      </button> */}
                          <p
                            // to={`/product/${product.id}`}
                            className="product-card__cta__toProduct"
                          >
                            Ver producto
                          </p>
                          {/* <button
                        onClick={() => console.log("Yo this was clicked")}
                        >
                        <span> Agregar a mi lista </span>
                        <img src={favoriteIcon} alt="Agregar a mi lista" />
                      </button> */}
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default CatalogueCategory;
