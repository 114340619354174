import { useParams } from "react-router";
import { Link } from "react-router-dom";

// Custom styles
import "../scss/pages/confirmation.styles.scss";

// Assets
import successIcon from "../assets/img/success-icon.svg";

function Confirmation() {
  const { id } = useParams();
  return (
    <section className="confirmation__wrapper">
      <div className="confirmation__container">
        <img src={successIcon} alt="Dammann" />
        <h4>¡Hemos recibido tu orden!</h4>
        <p>Orden: #{id}</p>
        <p>
          Te hemos mandado un correo electrónico con los detalles de tu orden.
          Por favor, revisa la bandeja de correo no deseado.
        </p>
        <p className="mb-3" style={{ fontWeight: "bolder" }}>
          Tu producto se entregará dentro de 5 a 7 días hábiles.
        </p>
        <Link to="/">Regresar</Link>
      </div>
    </section>
  );
}

export default Confirmation;
