import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Context
import { AuthProvider } from "./contexts/AuthContext";
import { CartProvider } from "./contexts/CartContext";
import { AlertProvider } from "./contexts/AlertContext";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Custom CSS
import "./scss/main.scss";

// Custom components
import CustomNav from "./components/CustomNav/CustomNav";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import CookieBanner from "./components/CookieBanner/CookieBanner";

// Pages
import Home from "./pages/Home";
import Catalogue from "./pages/Catalogue";
import Cart from "./pages/Cart";
import Confirmation from "./pages/Confirmation";
import Guest from "./pages/Guest";
import Login from "./pages/Login";
import Product from "./pages/Product";
import Register from "./pages/Register";
import Checkout from "./pages/Checkout";
import Profile from "./pages/Profile";
import CatalogueCategory from "./pages/CatalogueCategory";
import Alert from "./components/Alert/Alert";
import RecoverPassword from "./pages/RecoverPassword";
import ForgotPassword from "./pages/ForgotPassword";
import Cookies from "./pages/Cookies";
import Policies from "./pages/Policies";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import Terms from "./pages/Terms";
import NotFound from "./pages/404";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init("163003709890317", null, options);
ReactPixel.pageView();

ReactGA.initialize("UA-258722355-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <AlertProvider>
          <Alert />
          <Router>
            <header>
              <CustomNav />
            </header>
            <ScrollToTop />
            <Switch>
              <main>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/catalogue/:slug">
                  <CatalogueCategory />
                </Route>
                <Route exact path="/catalogue/c/:slug">
                  <Catalogue />
                </Route>
                <Route exact path="/cart">
                  <NotFound />
                  {/* <Cart /> */}
                </Route>
                <Route exact path="/confirmation/:id">
                  <NotFound />
                  {/* <Confirmation /> */}
                </Route>
                <Route exact path="/cookies-notice">
                  <Cookies />
                </Route>
                <Route exact path="/checkout">
                  <NotFound />
                  {/* <Checkout /> */}
                </Route>
                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route exact path="/guest">
                  <Guest />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/policy">
                  <Policies />
                </Route>
                <Route exact path="/terms">
                  <Terms />
                </Route>
                <Route exact path="/profile">
                  <Profile />
                </Route>
                <Route exact path="/product/:id">
                  <Product />
                </Route>
                <Route exact path="/reset-password">
                  <RecoverPassword />
                </Route>
                <Route exact path="/register">
                  <Register />
                </Route>
              </main>
            </Switch>
            <Footer />
            <CookieBanner expires={3} />
          </Router>
        </AlertProvider>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
