import { Link } from "react-router-dom";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

// Custom styles
import "./footer.styles.scss";

// Assets
import giftSetIcon from "../../assets/img/gift-set-icon.svg";
import secureIcon from "../../assets/img/secure-icon.svg";
import customerServiceSetIcon from "../../assets/img/customer-service-icon.svg";
import logoBlack from "../../assets/img/logo_paris--black.png";
import igSocialIcon from "../../assets/img/ig-bg-grey-icon.svg";
import fbSocialIcon from "../../assets/img/fb-bg-grey-icon.svg";

import ReactGA from "react-ga";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__perks">
        <Container>
          <Row className="text-center">
            <Col xs="12" lg="4">
              <img src={giftSetIcon} alt="Dammann" />
              <h4>kits de regalo</h4>
            </Col>
            <Col xs="12" lg="4" className="mt-4 mt-lg-0">
              <img src={secureIcon} alt="Dammann" />
              <h4>visa, mastercard, american express</h4>
              <p>Pago seguro</p>
            </Col>
            <Col xs="12" lg="4" className="mt-4 mt-lg-0">
              <img src={customerServiceSetIcon} alt="Dammann" />
              <h4>servicio al cliente</h4>
              <p>Horario Lun-Vie: 8:00 - 17:00</p>
              <a
                href="tel:+525536402250"
                onClick={() => {
                  ReactGA.event({
                    category: "telefono",
                    action: "Llamada",
                    label: "llamada",
                  });
                }}
              >
                (55)-36402250
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer__container">
        <Container>
          <Row>
            <Col xs="12" lg="3">
              <Link to="/">
                <img src={logoBlack} alt="Dammann" />
              </Link>
            </Col>
            <Col xs="6" lg="3" className="mt-3 mt-lg-0">
              <h6>Mapa del sitio</h6>
              <ul>
                <li>
                  <Link to="/">Inicio</Link>
                </li>
                <li>
                  <Link to="/catalogue/tes">Tés</Link>
                </li>
                <li>
                  <Link to="/catalogue/tisanas">Tisanas</Link>
                </li>
                <li>
                  <Link to="/catalogue/accesorios">Accesorios</Link>
                </li>
              </ul>
            </Col>
            <Col xs="6" lg="3" className="mt-3 mt-lg-0">
              <h6>Legal</h6>
              <ul>
                <li>
                  <Link to="/terms">Política de envíos y devolución</Link>
                </li>
                <li>
                  <Link to="/cookies-notice">Política de cookies</Link>
                </li>
                <li>
                  <Link to="/policy">
                    Aviso de privacidad y políticas de servicio
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs="6" lg="3" className="text-lg-center mt-3 mt-lg-0">
              <h6>Síguenos</h6>
              <div className="d-flex mt-2 justify-content-lg-center">
                <a
                  href="https://www.instagram.com/dammann_mexique/"
                  target="_blank"
                  rel="noreferrer"
                  className="me-3"
                >
                  <img src={igSocialIcon} alt="Dammann" />
                </a>
                <a
                  href="https://www.facebook.com/DAMMANNMEXIQUE"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbSocialIcon} alt="Dammann" />
                </a>
              </div>
            </Col>
          </Row>
          <div className="footer__container__bottom">
            <p>Dammann 2024. Todos los derechos reservados.</p>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
