import { useState, useContext, useRef } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

// Context
import AlertContext from "../contexts/AlertContext";

// API
import { forgotPassword } from "../api/auth";

// Custom components
import ReCAPTCHA from "react-google-recaptcha";

// Custom styles
import "../scss/pages/forgotPassword.scss";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

const initialValues = {
  email: "",
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
});

function ForgotPassword() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const captcha = useRef(null);
  const alert = useContext(AlertContext);

  const onChange = (value) => {
    setError(false);
    // console.log("Captcha value:", value);
  };

  // useEffect(() => {
  //   captcha.current.reset();
  // }, []);

  return (
    <section className="forgot-password__wrapper">
      <Container>
        <div className="recover-password__container">
          <h4>Recuperación de contraseña</h4>
          <p className="mb-3 my-lg-2">
            Para recuperar tu contraseña ingresa a continuación el correo
            electrónico de tu registro:{" "}
          </p>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              if (!captcha.current.getValue()) {
                alert.error("Por favor completa el captcha.");
                return;
              } else {
                const response = await forgotPassword(values);
                if (response.error?.status === 403) {
                  alert.error(
                    "Error en la solicitud. Por favor intenta de nuevo"
                  );
                } else if (response.error?.status === 400) {
                  alert.error(
                    "No se reconoce el email enviado. Por favor verifica que sea el correcto."
                  );
                } else if (response.ok === true) {
                  alert.success(
                    "Un email ha sido enviado a tu correo. Por favor verifica la bandeja de correo no deseado."
                  );
                }
                resetForm(initialValues);
                captcha.current.reset();
              }
              // if (captcha.current.getValue()) {
              //   setResetPasswordLoading(true);
              //   const response = await resetPassword(
              //     oobCode,
              //     values.newPassword
              //   );
              //   // console.log(response);
              //   if (response.status === 200) {
              //     history.push("/login");
              //     alert.success(
              //       "Contraseña restablecida. Por favor inicia sesión."
              //     );
              //     setResetPasswordLoading(false);
              //     resetForm({
              //       newPassword: "",
              //     });
              //     captcha.current.reset();
              //     return;
              //   } else {
              //     response.json().then((json) => {
              //       // alert.error(json);
              //       if (json.error === "INVALID_OOB_CODE") {
              //         alert.error(
              //           "El código de recuperación ya expiró. Vuelve a solicitar el cambio de contraseña."
              //         );
              //       } else {
              //         alert.error(
              //           "Hubo un error en tu solicitud. Vuelve a solicitar el cambio de contraseña."
              //         );
              //       }
              //     });
              //     // console.log("error");
              //     // alert.error("Error en tu solicitud.");
              //     setResetPasswordLoading(false);
              //     resetForm({
              //       newPassword: "",
              //     });
              //     captcha.current.reset();
              //     return;
              //   }
              // } else {
              //   alert.error("Por favor completa el captcha");
              // }
            }}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mt-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Escribe aquí tu correo electrónico"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email || touched.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="re-captcha text-center mt-3">
                  <ReCAPTCHA
                    sitekey="6Le97hwjAAAAABgehJBi8UGDYphi-P9GeKGT7QsG"
                    onChange={onChange}
                    ref={captcha}
                  />
                </div>
                <button disabled={loading} type="submit">
                  {loading ? "Validando..." : "Enviar link"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
}

export default ForgotPassword;
