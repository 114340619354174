import { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";

// Context
import AlertContext from "../contexts/AlertContext";

// Bootstrap components
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

// Custom styles
import "../scss/pages/register.styles.scss";

// API
import { register } from "../api/auth";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

const initialValues = {
  username: "",
  phoneNumber: "",
  dateOfBirth: "",
  email: "",
  password: "",
  terms: false,
};

const today = new Date();

const schema = yup.object().shape({
  username: yup.string().required("Este campo es requerido"),
  phoneNumber: yup.number().required("Este campo es requerido"),
  dateOfBirth: yup
    .date()
    .max(
      today,
      "La fecha seleccionada es después de la fecha actual. Selecciona una fecha válida"
    )
    // TO-DO: validate birthday Date to be above legal age
    // .when(
    //   today,
    //   (startDate, schema) => (
    //      && schema.min(startDate)),
    // )
    .required("Este campo es requerido"),
  email: yup
    .string()
    .email("Ingresa un email válido")
    .required("Este campo es requerido"),
  password: yup
    .string()
    .min(8, "La contraseña debe ser igual o mayor a 8 caracteres")
    .required("Este campo es requerido"),
  terms: yup
    .bool()
    .required()
    .oneOf(
      [true],
      "Los términos y condiciones deben ser aceptados para continuar"
    ),
});

function Register() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const alert = useContext(AlertContext);

  return (
    <section className="register__wrapper">
      <div className={`overlay ${loading && "show"}`}></div>
      <div className="register__container">
        <h3>Registro</h3>
        <p className="mt-2">
          Llena los campos a continuación para registrarte en nuestra plataforma
          y recibir grandes beneficios:
        </p>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            const response = await register(values);
            if (response.jwt) {
              ReactPixel.trackCustom("Lead", { content_name: "SignUp" });
              ReactGA.event({
                category: "formulario",
                action: "SignUp",
                label: "SignUp",
              });
              alert.success(
                `¡Registro exitoso! Por favor, inicia sesión con tus datos.`
              );
              history.push("/login");
              resetForm(initialValues);
            } else {
              alert.error("Error al registrar usuario.");
              setLoading(false);
              resetForm(initialValues);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="formRegisterName" className="mb-3">
                <Form.Label>Nombre completo:*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe tu nombre"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.username && touched.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username || touched.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRegisterPhone" className="mb-3">
                <Form.Label>Número de teléfono:*</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Escribe tu número de teléfono"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber || touched.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRegisterDateOfBirth" className="mb-3">
                <Form.Label>Fecha de nacimiento:*</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Escribe tu fecha de nacimiento"
                  name="dateOfBirth"
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.dateOfBirth && touched.dateOfBirth}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dateOfBirth || touched.dateOfBirth}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRegisterEmail">
                <Form.Label>Email:*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Escribe tu correo electrónico"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email || touched.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formRegisterPassword">
                <Form.Label>Contraseña:*</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Escribe una contraseña"
                  name="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={!!errors.password && touched.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password || touched.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    isValid={false}
                    name="terms"
                    onChange={handleChange}
                    isInvalid={!!errors.terms && touched.terms}
                    feedback={errors.terms}
                    // feedbackType="invalid"
                  />
                  <Form.Check.Label>
                    Acepta las <Link to="/policy">políticas de servicio</Link>
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.terms}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
              <button type="submit">
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Registrarme ahora"
                )}
              </button>
              <p>
                ¿Ya tienes cuenta? <Link to="/login">Inicia sesión aquí</Link>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default Register;
