import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

// Custom styles
import "../scss/pages/profile.styles.scss";

// Context
import { useAuth } from "../contexts/AuthContext";

// API
import {
  getUserWishlist,
  getUserOrdersApi,
  deleteItemWishlist,
} from "../api/auth";

// Utils
import formatMoney from "../utils/formatMoney";

// Assets
// import productTest from "../assets/img/product1.jpg";
// import heartFilled from "../assets/img/heart-icon--filled.svg";
import deleteIcon from "../assets/img/delete-icon--grey.svg";

function Profile() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [historyOrders, setHistoryOrders] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (currentUser.jwt) {
        const response = await getUserWishlist(
          currentUser.user.id,
          currentUser.jwt
        );
        if (response.data) {
          setWishlist(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    })();
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (currentUser.jwt) {
        const response = await getUserOrdersApi(
          currentUser.user.id,
          currentUser.jwt
        );
        if (response.data) {
          setHistoryOrders(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    })();
  }, [currentUser]);

  return (
    <section className="profile__wrapper">
      <div className="profile__hero">
        <h3>tu perfil</h3>
      </div>
      <div className="profile__container">
        <Container fluid>
          <Tabs defaultActiveKey="wishlist" id="profile__container-tabs">
            <Tab eventKey="wishlist" title="Lista de deseos">
              <Row>
                {loading ? (
                  <p className="py-3">Cargando lista...</p>
                ) : wishlist?.length > 0 ? (
                  wishlist?.map((listItem, key) => (
                    <Col
                      xs="12"
                      md="6"
                      lg="4"
                      xxl="3"
                      key={key}
                      className="mt-3"
                    >
                      <div className="wishlist-item">
                        <div className="wishlist-item__media">
                          <img
                            // src={
                            //   listItem.attributes?.producto.data.attributes
                            //     .imagenes.data[0].attributes.url
                            // }
                            src={`${listItem.attributes.producto.data.attributes.imagenes.data[0].attributes.url}`}
                            alt={
                              listItem.attributes.producto.data.attributes
                                .nombre
                            }
                          />
                        </div>
                        <div className="wishlist-item__info">
                          <p>
                            {
                              listItem.attributes.producto.data.attributes
                                .nombre
                            }
                          </p>
                          <p>
                            {listItem.attributes.producto.data.attributes.PresentacionesDisponibles.find(
                              (currentPresentation) =>
                                currentPresentation.Presentacion ===
                                listItem.attributes.selectedPresentation
                            )?.precio > 0
                              ? formatMoney(
                                  listItem.attributes.producto.data.attributes.PresentacionesDisponibles.find(
                                    (currentPresentation) =>
                                      currentPresentation.Presentacion ===
                                      listItem.attributes.selectedPresentation
                                  ).precio
                                )
                              : "Presentación fuera de stock"}
                          </p>
                          {listItem.attributes.producto.data.attributes
                            .tipoProducto === "Té" ? (
                            <p>
                              <span>Tipo de té:</span>{" "}
                              {
                                listItem.attributes.producto.data.attributes
                                  .subcategoria.data.attributes.nombre
                              }
                            </p>
                          ) : (
                            <></>
                          )}
                          {listItem.attributes.producto.data.attributes
                            .tipoProducto === "Tisana" ? (
                            <p>
                              <span>Tipo de tisana:</span>{" "}
                              {
                                listItem.attributes.producto.data.attributes
                                  .subcategoria.data.attributes.nombre
                              }
                            </p>
                          ) : (
                            <></>
                          )}
                          <p>
                            <span>Presentación:</span>{" "}
                            {/* {listItem.attributes.selectedPresentation} */}
                            {listItem.attributes.selectedPresentation ===
                              "Sachet20" && "Sachet 20 piezas"}
                            {listItem.attributes.selectedPresentation ===
                              "Sachet" && "Sachet 24 piezas"}
                            {listItem.attributes.selectedPresentation ===
                              "Sachet25" && "Sachet 25 piezas"}
                            {listItem.attributes.selectedPresentation ===
                              "Lata" && "Lata 100 gramos"}
                            {listItem.attributes.selectedPresentation ===
                              "Lata20" && "Lata 20 gramos"}
                            {listItem.attributes.selectedPresentation ===
                              "Lata45" && "Lata 45 gramos"}
                            {listItem.attributes.selectedPresentation ===
                              "Lata35" && "Lata 35 gramos"}
                            {listItem.attributes.selectedPresentation ===
                              "Lata60" && "Lata 60 gramos"}
                            {listItem.attributes.selectedPresentation ===
                              "Lata90" && "Lata 90 gramos"}
                          </p>
                          {/* <img
                            src={heartFilled}
                            alt={
                              listItem.attributes.producto.data.attributes
                                .nombre
                            }
                          /> */}
                          <div className="d-flex align-items-center justify-content-between mt-2">
                            <Link
                              to={`/product/${listItem.attributes.producto.data.attributes.slug}`}
                            >
                              Ver producto
                            </Link>
                            <button
                              onClick={() => {
                                setLoading(true);
                                if (currentUser.jwt) {
                                  deleteItemWishlist(
                                    currentUser.jwt,
                                    listItem.id
                                  );
                                  setWishlist((prev) =>
                                    prev.filter(
                                      (item) => item.id !== listItem.id
                                    )
                                  );
                                  setLoading(false);
                                }
                              }}
                            >
                              <img
                                src={deleteIcon}
                                alt={
                                  listItem.attributes.producto.data.attributes
                                    .nombre
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  // wishlist.map((list, key) =>
                  //   list.attributes?.productos.data.map((item, key) => (
                  //     <Col xs="12" md="6" lg="4" xxl="2" key={key}>
                  //       <div className="wishlist-item">
                  //         <div className="wishlist-item__media">
                  //           <img
                  //             src={`${process.env.REACT_APP_API_URL}${item.attributes.imagenes.data[0].attributes.url}`}
                  //             alt={item.attributes.nombre}
                  //           />
                  //         </div>
                  //         <div className="wishlist-item__info">
                  //           <p>{item.attributes.nombre}</p>
                  //           {/* <p>{formatMoney(product.productPrice)}</p> */}
                  //           {/* {product.productType === "tea" ? (
                  //           <p>
                  //             <span>Tipo de té:</span> {product.typeOfTea}
                  //           </p>
                  //         ) : (
                  //           <></>
                  //         )} */}
                  //           {/* <img src={heartFilled} alt={product.productName} /> */}
                  //           {/* <Link to={`/product/${product.id}`}>
                  //           Ver producto
                  //         </Link> */}
                  //         </div>
                  //       </div>
                  //     </Col>
                  //   ))
                  // )

                  <p className="py-3">No tienes elementos en tu lista.</p>
                )}
              </Row>
            </Tab>
            <Tab eventKey="history" title="Historial de pedidos">
              <Row>
                {/* <p className="py-3">No tienes ordenes en tu historial</p> */}
                {historyOrders?.length > 0 ? (
                  historyOrders.map((orderItem, key) => (
                    <Col
                      xs="12"
                      md="6"
                      lg="4"
                      xl="3"
                      xxl="2"
                      key={key}
                      className="mt-3"
                    >
                      <div className="order-item">
                        <div>
                          <h6>Id de orden:</h6>
                          <p>#{orderItem.attributes.orderId}</p>
                        </div>
                        <div>
                          <h6>Cantidad de productos:</h6>
                          <p>{orderItem.attributes.orderItems.length}</p>
                        </div>
                        <div>
                          <h6>Total de orden:</h6>
                          <p>{formatMoney(orderItem.attributes.total)}</p>
                        </div>
                        <div>
                          <h6>Productos:</h6>
                          <ul>
                            {orderItem.attributes.orderItems.map(
                              (product, key) => (
                                <li key={key}>
                                  {
                                    product.attributes.productos.data[0]
                                      .attributes.nombre
                                  }
                                  <span>
                                    {" "}
                                    ({product.attributes.selectedPresentation})
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <p className="py-3">No tienes ordenes en tu historial.</p>
                )}
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </div>
    </section>
  );
}

export default Profile;
