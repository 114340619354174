import { size, remove } from "lodash";

/*
  @route    POST REACT_APP_API_URL/api/auth/local/register
  @desc     Register a new user
  @access   Public
*/

export async function register(formData) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/auth/local/register`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    POST REACT_APP_API_URL/api/auth/local
  @desc     Login existing user
  @access   Public
*/

export async function login(formData) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/auth/local`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    GET REACT_APP_API_URL/api/cart-itemsfilters[usuario][id]=${userId}&populate[productos][populate]=*&publicationState=preview&filters[productos][id]=${productId}
  @desc     Check if an item is in cart
  @access   Private
*/

export async function isCartItemApi(userId, productId, token) {
  // &usuario=7&productos=4
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/cart-items?filters[usuario][id]=${userId}&populate[productos][populate]=*&publicationState=preview&filters[productos][id]=${productId}`;
    // const url = `${process.env.REACT_APP_API_URL}/api/cart-items?filters[usuario][id]=${userId}&populate[producto][populate]=*&publicationState=preview&filters[producto][id]=${productId}`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET REACT_APP_API_URL/api/wishlists?filters[usuario][id]=4&populate[productos][populate]=*&publicationState=preview
  @desc     Check if an item is in cart
  @access   Private
*/

export async function isItemInWishlistApi(userId, productId, token) {
  // &usuario=7&productos=4
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/wishlists?filters[usuario][id]=${userId}&populate[producto][populate]=*&publicationState=preview&filters[producto][id]=${productId}`;
    // const url = `${process.env.REACT_APP_API_URL}/api/wishlists?filters[usuario][id]=${userId}&populate[productos][populate]=*&publicationState=preview`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    POST REACT_APP_API_URL/api/cart-items
  @desc     Add product to user's cart
  @access   Private
*/
export async function addProductCart(
  idUser,
  product,
  quantity,
  token,
  selectedPresentation
  // selectedPresentationPrice
) {
  // delete product.attributes.createdAt;
  // delete product.attributes.updatedAt;
  // delete product.attributes.publishedAt;
  const { id: productId } = product;
  // console.log(quantity);
  // console.log(product, "ADD PRODUCT CART");
  const productData = {
    data: {
      productos: [productId],
      cantidad: quantity,
      usuario: idUser,
      selectedPresentation,
      // selectedPresentationPrice,
    },
  };
  try {
    const dataFound = await isCartItemApi(idUser, productId, token);
    // console.log(dataFound);
    if (size(dataFound.data) > 0 || !dataFound) {
      // console.log(dataFound.data);
      // console.log(
      //   dataFound.data.findIndex(
      //     (product) =>
      //       product.attributes.selectedPresentation === selectedPresentation
      //   )
      // );
      // console.log(
      //   dataFound.data.find(
      //     (product) =>
      //       product.attributes.selectedPresentation === selectedPresentation
      //   )
      // );
      if (
        dataFound.data.find(
          (product) =>
            product.attributes.selectedPresentation === selectedPresentation
        ) !== undefined
        // Object.keys(
        //   dataFound.data.find(
        //     (product) =>
        //       product.attributes.selectedPresentation === selectedPresentation
        //   )
        // ).length !== 0
        // dataFound.data[0].attributes.selectedPresentation ===
        // selectedPresentation
      ) {
        const url = `${process.env.REACT_APP_API_URL}/api/cart-items/${
          dataFound.data[
            dataFound.data.findIndex(
              (product) =>
                product.attributes.selectedPresentation === selectedPresentation
            )
          ].id
        }`;
        const params = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: {
              productos: [productId],
              cantidad:
                quantity +
                dataFound.data[
                  dataFound.data.findIndex(
                    (product) =>
                      product.attributes.selectedPresentation ===
                      selectedPresentation
                  )
                ].attributes.cantidad,
              usuario: idUser,
            },
          }),
        };
        const result = await fetch(url, params);
        // console.log("Producto añadido al carrito");
        // TO-DO: ALERT CONTEXT
        // console.log("La presentacion es igual y se agregó");
        return result;
      } else {
        const data = JSON.stringify(productData);
        const url = `${process.env.REACT_APP_API_URL}/api/cart-items`;
        const params = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: data,
        };
        const result = await fetch(url, params);
        // console.log("La presentacion es diferente y se creó");
        // console.log("Producto añadido al carrito");
        return result;
      }
    } else {
      const data = JSON.stringify(productData);
      const url = `${process.env.REACT_APP_API_URL}/api/cart-items`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      };
      const result = await fetch(url, params);
      // console.log("El producto es nuevo y se creó");
      // console.log("Producto añadido al carrito");
      return result;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET REACT_APP_API_URL/api/carti-items?filters[usuario][id]=${userId}&populate[productos][populate]=*&publicationState=preview&filters[productos][id]=${productId}
  @desc     GET all items in registered user's cart
  @access   Private
*/

export async function getRegisteredUserCart(userId, token) {
  // &usuario=7&productos=4
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/cart-items?sort=createdAt:desc&filters[usuario][id]=${userId}&publicationState=preview&populate[productos][populate]=*`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    PUT REACT_APP_API_URL/api/cart-items
  @desc     Change product's quantity in user's cart
  @access   Private
*/

export async function quantityChangeProductCart(
  userId,
  quantity,
  productId,
  token,
  cartItemId
) {
  const productData = {
    data: {
      productos: [productId],
      cantidad: quantity,
      usuario: userId,
    },
  };
  const data = JSON.stringify(productData);
  try {
    const dataFound = await isCartItemApi(userId, productId, token);
    // console.log(dataFound);
    if (size(dataFound.data) > 0) {
      const url = `${process.env.REACT_APP_API_URL}/api/cart-items/${
        dataFound.data[
          dataFound.data.findIndex((product) => product.id === cartItemId)
        ]?.id
      }`;
      const params = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      };
      const response = await fetch(url, params);
      const result = await response.json();
      return result;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    DELETE REACT_APP_API_URL/api/cart-item/ID
  @desc     DELETE a product in user's cart
  @access   Private
*/

export async function deleteCartItemApi(userId, productId, token, cartItemId) {
  try {
    const dataFound = await isCartItemApi(userId, productId, token);
    if (size(dataFound.data) > 0) {
      const url = `${process.env.REACT_APP_API_URL}/api/cart-items/${
        dataFound.data[
          dataFound.data.findIndex((product) => product.id === cartItemId)
        ]?.id
      }`;
      const params = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      return result;
    }
  } catch (error) {}
}

/*
  @route    DELETE REACT_APP_API_URL/api/cart-item/ID
  @desc     DELETE a product in user's cart
  @access   Private
*/

export async function emptyCartItemApi(cartItemId, token) {
  // try {
  //   const dataFound = await isCartItemApi(userId, productId, token);
  //   if (size(dataFound.data) > 0) {
  const url = `${process.env.REACT_APP_API_URL}/api/cart-items/${cartItemId}`;
  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(url, params);
  const result = await response.json();
  return result;
  //   }
  // } catch (error) {}
}

/*
  @route    PUT REACT_APP_API_URL/api/wishlists/WISHLISTID
  @desc     Add item to wishlist if it's not saved yet
  @access   Private
*/

export async function addItemWishlist(
  userId,
  product,
  token,
  selectedPresentation
) {
  try {
    const dataFound = await isItemInWishlistApi(userId, product.id, token);
    // console.log(product.id);
    // console.log(dataFound);
    if (size(dataFound.data) > 0 || !dataFound) {
      if (
        dataFound.data.find(
          (currentItem) =>
            currentItem.attributes.producto.data.id === product.id &&
            currentItem.attributes.selectedPresentation === selectedPresentation
        )
        // dataFound.data[0].attributes.productos.data.find(
        //   (currentProduct) => currentProduct.id === product.id
        // )
      ) {
        return { error: "El producto ya existe en tu lista de favoritos." };

        //     const url = `${process.env.REACT_APP_API_URL}/api/wishlists/${dataFound.data[0].id}`;
        //     const params = {
        //       method: "PUT",
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${token}`,
        //       },
        //       body: JSON.stringify({
        //         data: {
        //           productos: [
        //             ...dataFound.data[0].attributes.productos.data.map(
        //               (currentProduct) => currentProduct.id
        //             ),
        //             product.id,
        //           ],
        //           usuario: userId,
        //         },
        //       }),
        //     };
        //     const result = await fetch(url, params);
        //     return result;
      }
    } else {
      const url = `${process.env.REACT_APP_API_URL}/api/wishlists/`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            producto: [product.id],
            usuario: userId,
            selectedPresentation,
          },
        }),
      };
      const result = await fetch(url, params);
      return result;
    }
    // } else {

    // }
  } catch (error) {
    console.log(error);
    return error;
  }
}
/*
  @route    DELETE REACT_APP_API_URL/api/wishlists/WISHLISTID
  @desc     DELETE item to wishlist if it's not saved yet
  @access   Private
*/

export async function deleteItemWishlist(token, productId) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/wishlists/${productId}`;
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await fetch(url, params);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
}

/*
  @route    GET REACT_APP_API_URL/api/wishlists?filters[usuario][id]=USERID&populate[productos][populate]=*&publicationState=preview
  @desc     GET all items in user´s wishlist
  @access   Private
*/
export async function getUserWishlist(userId, token) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/wishlists?filters[usuario][id]=${userId}&populate[producto][populate]=*&publicationState=preview`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET REACT_APP_API_URL/api/auth/forgot-password
  @desc     POST a new code to recover password for user
  @access   PUBLIC
*/
export async function forgotPassword(formData) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/auth/forgot-password`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET REACT_APP_API_URL/api/auth/reset-password
  @desc     POST a new code to recover password for user
  @access   PUBLIC
*/
export async function resetPassword(formData) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/auth/reset-password`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET REACT_APP_API_URL/api/ordenes
  @desc    GET ORDERS FROM A USER
  @access   Private
*/

export async function getUserOrdersApi(userId, token) {
  // try {
  //   const dataFound = await isCartItemApi(userId, productId, token);
  //   if (size(dataFound.data) > 0) {
  const url = `${process.env.REACT_APP_API_URL}/api/ordenes?filters[usuario][id]=${userId}&populate[orderItems][populate]=*`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(url, params);
  const result = await response.json();
  return result;
  //   }
  // } catch (error) {}
}
