import React from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/policy.styles.scss";

function Terms() {
  return (
    <section className="policy__wrapper">
      <div className="policy__hero">
        <Container fluid>
          <h3>Política de envíos y devolución</h3>
        </Container>
      </div>
      <div className="policy__container">
        <Container>
          <ol>
            <li>
              <h4>Política de envío:</h4>
              <p>El tiempo de entrega es de 5-7 días hábiles.</p>
              <p>El costo de envío nacional es de $250 MXN.</p>
              <p>El costo de envío en la Ciudad de México es de $120 MXN.</p>
              <p>
                Ofrecemos envío gratis en compras de más de $800 MXN en la
                Ciudad de México.
              </p>
            </li>
            <li>
              <h4>Política de devolución:</h4>
              <p>
                Aceptamos devoluciones dentro de los 30 días posteriores a la
                compra.
              </p>
              <p>
                Los productos deben estar en su estado original, sin abrir y sin
                uso.
              </p>
              <p>
                Los gastos de envío de devolución corren por cuenta del
                comprador, excepto en el caso de productos defectuosos o
                incorrectos. En ese caso, cubriremos los gastos de envío de
                devolución.
              </p>
              <p>
                El reembolso se realizará en un plazo de 5-7 días hábiles
                después de recibir el producto devuelto.
              </p>
            </li>
            <li>
              Escríbenos a:
              <a
                href="mailto:info@dammannmexique.com"
                style={{ marginLeft: "10px" }}
              >
                info@dammannmexique.com
              </a>
            </li>
          </ol>
        </Container>
      </div>
    </section>
  );
}

export default Terms;
