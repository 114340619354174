import { Link } from "react-router-dom";

// Components
import CookieConsent from "react-cookie-consent";

// Styles
import "./cookieBanner.styles.scss";

function CookieBanner() {
  return (
    <CookieConsent
      containerClasses="cookie-consent-banner"
      buttonText="Acepto"
      // debug={true}
    >
      <div className="cookie-consent-banner__header">
        <h5>Esta página web utiliza cookies</h5>
      </div>
      <div className="cookie-consent-banner__description">
        <p>
          Uitilizamos cookies para mejorar la experiencia del usuario
          personalizando contenidos, así como para que esta misma funcione con
          eficacia. Si continúas navegando, consideramos que aceptas su uso. Si
          deseas leer más al respecto puedes hacerlo
          <Link to="/cookies-notice"> aquí.</Link>
        </p>
      </div>
    </CookieConsent>
  );
}

export default CookieBanner;
