import { createContext, useContext, useState, useEffect } from "react";

import {
  getCart,
  addProductCart,
  getRegisteredUserCart,
  quantityChangeProductCart,
  deleteCartItemApi,
  emptyCartItemApi,
} from "../api/auth";

import { useAuth } from "./AuthContext";

// Utils
import { v4 as uuidv4 } from "uuid";

export const CartContext = createContext();

export const useCart = () => {
  const context = useContext(CartContext);
  return context;
};

export function CartProvider({ children }) {
  // const user = {
  //   login: false,
  // };

  const { currentUser } = useAuth();

  // ------------ INITIAL STATE ------------
  const [cartItems, setCartItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPriceCart, setTotalPriceCart] = useState(0);
  const [reloadCart, setReloadCart] = useState(false);
  const [hasAchievedCDMXPromotion, setHasAchievedCDMXPromotion] =
    useState(false);
  const [hasAchievedNationalPromotion, setHasAchievedNationalPromotion] =
    useState(false);
  const [hasAppliedCoupon, setHasAppliedCoupon] = useState(false);
  const [originalPriceCart, setOriginalPriceCart] = useState(0);

  // ------------ REGISTERED USERS FUNCTIONS ------------
  const addProductToCart = async (
    idUser,
    product,
    quantity,
    token,
    selectedPresentation
    // selectedPresentationPrice
  ) => {
    // console.log(cartItems);
    if (token) {
      try {
        setReloadCart(true);
        const response = await addProductCart(
          idUser,
          product,
          quantity,
          token,
          selectedPresentation
          // selectedPresentationPrice
        );
        // console.log(response);
        if (response.status === 200) {
          response.json().then(async (json) => {
            // console.log("json", json);
            // if (localStorage.getItem("cartItems") === null) {
            //
            try {
              const response = await getRegisteredUserCart(idUser, token);
              // console.log(response);
              if (response.data.length > 0) {
                // console.log(response);
                setCartItems(response.data);
                // const token = response;
                localStorage.setItem(
                  "cartItems",
                  JSON.stringify(response.data)
                );
                // if (token) {
                // }
                return response;
              }
            } catch (error) {
              console.log(error);
            }
            // EL BUG ES QUE SI ENCUENTRA 1, ACTUALIZA TODOS EN VEZ DE SOLO ACTUALIZAR EL DE LA MISMA PPT
            if (
              cartItems.find((item) => item.id === json.data.id) !== undefined
            ) {
              console.log("ya estaba, se actualiza state");
              let updatedCartItems = cartItems.map((item) => {
                if (item.id === json.data.id) {
                  // console.log("Se va a modificar");
                  // console.log(quantity);
                  // console.log(item);
                  // console.log(json.data.id);
                  // console.log(
                  //   (cartItems[
                  //     cartItems.findIndex((i) => i.id === json.data.id)
                  //   ].attributes.cantidad += quantity)
                  // );
                  // return (cartItems[
                  //   cartItems.findIndex((i) => i.id === json.data.id)
                  // ].attributes.cantidad += quantity);
                  return {
                    ...item,
                    attributes: {
                      ...item.attributes,
                      cantidad: item.attributes.cantidad + quantity,
                    },
                  };
                }
                return item;
              });
              // console.log(updatedCartItems);
              setCartItems(updatedCartItems);
              // setCartItems([
              //   ...cartItems,
              //   {
              //     ...cartItems[
              //       cartItems.findIndex((item) => item.id === json.data.id)
              //     ],
              //     attributes: {
              //       ...cantidad: quantity,
              //     },
              //   },
              // ]);
            } else if (cartItems[0].id === 0) {
              // console.log("despues de guest");
              setCartItems([
                // {
                //   ...cartItems[
                //     cartItems.findIndex((item) => item.id === json.data.id)
                //   ],
                //   attributes: {
                //     cantidad: quantity,
                //   },
                // },
                {
                  id: json.data.id,
                  attributes: {
                    selectedPresentation,
                    // selectedPresentationPrice,
                    cantidad: quantity,
                    productos: { data: [product] },
                  },
                },
              ]);
            } else {
              // console.log("se va a crear");
              setCartItems([
                ...cartItems,
                // {
                //   ...cartItems[
                //     cartItems.findIndex((item) => item.id === json.data.id)
                //   ],
                //   attributes: {
                //     cantidad: quantity,
                //   },
                // },
                {
                  id: json.data.id,
                  attributes: {
                    selectedPresentation,
                    // selectedPresentationPrice,
                    cantidad: quantity,
                    productos: { data: [product] },
                  },
                },
              ]);
            }
            // }
            // setCartItems([
            //   ...cartItems,
            //   {
            //     ...cartItems[
            //       cartItems.findIndex((item) => item.id === json.data.id)
            //     ],
            //     attributes: {
            //       cantidad: quantity,
            //     },
            //   },
            //   // {
            //   //   id: json.data.id,
            //   //   attributes: {
            //   //     selectedPresentation,
            //   //     // selectedPresentationPrice,
            //   //     cantidad: quantity,
            //   //     productos: { data: [product] },
            //   //   },
            //   // },
            // ]);

            // console.log(json.data.id);
            // // console.log(
            // //   cartItems.findIndex((item) => item.id === json.data.id)
            // // );
            // console.log(
            //   cartItems.findIndex((item) => item.id === json.data.id)
            // );
          });
          setReloadCart(false);
        }
        return response;
      } catch (error) {
        setReloadCart(false);
        console.log(error);
      }
    }
  };

  const authQuantityChangeProduct = async (
    userId,
    quantity,
    product,
    productId,
    token,
    cartItemId
  ) => {
    setReloadCart(true);
    try {
      const response = await quantityChangeProductCart(
        userId,
        quantity,
        product,
        productId,
        token,
        cartItemId
      );
      const foundIndex = cartItems.findIndex(
        (cartItem) => cartItem.id === response.data.id
      );
      cartItems[foundIndex].attributes.cantidad = quantity;
      setCartItems(cartItems);
      setTotalItems(
        cartItems.reduce(
          (total, product) => total + product.attributes.cantidad,
          0
        )
      );
      setOriginalPriceCart(
        cartItems.reduce(function (tally, cartItem) {
          return (
            tally +
            cartItem.attributes.cantidad *
              (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                (currentPresentation) =>
                  currentPresentation.Presentacion ===
                  cartItem.attributes.selectedPresentation
              ).precio -
                (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                  (currentPresentation) =>
                    currentPresentation.Presentacion ===
                    cartItem.attributes.selectedPresentation
                ).precio *
                  cartItem.attributes.productos.data[0].attributes
                    .PresentacionesDisponibles[
                    cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                      (presentation) =>
                        presentation.Presentacion ===
                        cartItem.attributes.selectedPresentation
                    )
                  ].descuento) /
                  100)
          );
        }, 0)
      );
      setTotalPriceCart(
        cartItems.reduce(function (tally, cartItem) {
          return (
            tally +
            cartItem.attributes.cantidad *
              (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                (currentPresentation) =>
                  currentPresentation.Presentacion ===
                  cartItem.attributes.selectedPresentation
              ).precio -
                (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                  (currentPresentation) =>
                    currentPresentation.Presentacion ===
                    cartItem.attributes.selectedPresentation
                ).precio *
                  cartItem.attributes.productos.data[0].attributes
                    .PresentacionesDisponibles[
                    cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                      (presentation) =>
                        presentation.Presentacion ===
                        cartItem.attributes.selectedPresentation
                    )
                  ].descuento) /
                  100)
          );
        }, 0)
      );
      setReloadCart(false);
      return response;
    } catch (error) {
      console.log(error);
      setReloadCart(false);
    }
  };

  const authRemoveProduct = async (userId, productId, auth, cartItemId) => {
    setReloadCart(true);
    try {
      const response = await deleteCartItemApi(
        userId,
        productId,
        auth,
        cartItemId
      );
      // if (response.data) {
      //   setTotalItems(
      //     cartItems.reduce(
      //       (total, product) => total + product.attributes.cantidad,
      //       0
      //     )
      //   );
      //   // setTotalPriceCart(
      //   //   cartItems.reduce(
      //   //     (tally, cartItem) =>
      //   //       tally +
      //   //       cartItem.attributes.cantidad *
      //   //         cartItem.attributes.productos.data[0]?.attributes.precio
      //   //   )
      //   // );
      // }
      setReloadCart(false);
      return response;
    } catch (error) {
      console.log(error);
      setReloadCart(false);
    }
  };

  const authEmptyCart = async (userId, productId, auth, cartItemId) => {
    setReloadCart(true);
    try {
      const response = await emptyCartItemApi(
        userId,
        productId,
        auth,
        cartItemId
      );
      // if (response.data) {
      //   setTotalItems(
      //     cartItems.reduce(
      //       (total, product) => total + product.attributes.cantidad,
      //       0
      //     )
      //   );
      //   // setTotalPriceCart(
      //   //   cartItems.reduce(
      //   //     (tally, cartItem) =>
      //   //       tally +
      //   //       cartItem.attributes.cantidad *
      //   //         cartItem.attributes.productos.data[0]?.attributes.precio
      //   //   )
      //   // );
      // }
      setReloadCart(false);
      emptyUserCart();
      return response;
    } catch (error) {
      console.log(error);
      setReloadCart(false);
    }
  };

  // ------------ END OF REGISTERED USERS FUNCTIONS ------------

  const guestAddFuProductToCart = (
    cartItem,
    id,
    selectedPresentation,
    quantity
  ) => {
    // console.log(cartItem);
    // console.log(cartItems);
    // if (cartItems !== undefined) {
    const skuId = uuidv4().slice(0, 7);
    // console.log(quantity);
    // console.log(selectedPresentation);
    if (cartItems.length >= 1 && cartItems !== undefined) {
      // console.log("ya había");
      // setCartItems([
      //   ...cartItems,
      //   {
      //     attributes: {
      //       cantidad: quantity,
      //       selectedPresentation,
      //       productos: { data: [cartItem] },
      //       skuId,
      //     },
      //   },
      // ]);
      // cartItems.push({ cartItem });
      // console.log(
      //   !!cartItems.find(
      //     (item) =>
      //       item.attributes.productos.data[0].attributes.nombre ===
      //         cartItem.attributes.nombre &&
      //       item.attributes.selectedPresentation === selectedPresentation
      //   )
      // );
      if (
        !!cartItems.find(
          (item) =>
            item.attributes.productos.data[0].attributes.nombre ===
              cartItem.attributes.nombre &&
            item.attributes.selectedPresentation === selectedPresentation
        )
      ) {
        // console.log("ya había");
        let updatedCartItems = cartItems.map((item) => {
          if (
            item.attributes.productos.data[0].attributes.nombre ===
              cartItem.attributes.nombre &&
            item.attributes.selectedPresentation === selectedPresentation
          ) {
            // console.log("Se va a modificar");
            // console.log(quantity);
            return {
              ...item,
              attributes: {
                ...item.attributes,
                cantidad: item.attributes.cantidad + quantity,
              },
            };
          }
          return item;
        });
        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      } else {
        // console.log("no vacio pero producto nuevo");
        localStorage.setItem(
          "cartItems",
          JSON.stringify([
            ...cartItems,
            {
              attributes: {
                cantidad: quantity,
                selectedPresentation,
                productos: { data: [cartItem] },
                skuId,
              },
            },
          ])
        );
      }
      // cartItems.push({ ...cartItem });
    } else {
      // console.log("no había");
      localStorage.setItem(
        "cartItems",
        JSON.stringify([
          {
            attributes: {
              cantidad: quantity,
              selectedPresentation,
              productos: { data: [cartItem] },
              skuId,
            },
          },
        ])
      );
    }
    localStorage.setItem("user", JSON.stringify({ guest: true }));
    setCartItems(JSON.parse(localStorage.getItem("cartItems")));
    // console.log(localStorage.length);
    // console.log(localStorage.getItem("cartItems"));
    // cartItems.push({ ...cartItem });
    // console.log(cartItems);
  };

  const guestRemoveProduct = (payload) => {
    // console.log(payload);
    setCartItems([
      ...cartItems.filter(
        (item) => item.attributes.skuId !== payload.attributes.skuId
      ),
    ]);
    localStorage.setItem(
      "cartItems",
      JSON.stringify([
        ...cartItems.filter(
          (item) => item.attributes.skuId !== payload.attributes.skuId
        ),
      ])
    );
    // dispatch({ type: 'REMOVE_ITEM', payload });
  };

  const guestIncrease = (payload, count, selectedPresentation) => {
    // console.log(payload);
    // console.log(count);
    // console.log([
    //   (cartItems[
    //     cartItems.findIndex(
    //       (item) => item.attributes.productos.data[0].id === payload
    //     )
    //   ].attributes.cantidad += count),
    // ]);
    // setCartItems([
    //   ...cartItems[cartItems.findIndex((item) =>  item.attributes.productos.data[0].id === payload) ]
    // ])
    cartItems[
      cartItems.findIndex(
        (item) =>
          item.attributes.productos.data[0].id === payload &&
          item.attributes.selectedPresentation === selectedPresentation
      )
    ].attributes.cantidad += count;
    setCartItems([...cartItems]);
    localStorage.setItem("cartItems", JSON.stringify([...cartItems]));

    // setCartItems([
    //   ...cartItems[cartItems.findIndex((item) =>  item.attributes.productos.data[0].id === payload) ]
    // ])

    // productId !== undefined
    //   ? (state.cartItems[
    //       state.cartItems.findIndex(
    //         // (item) => item.productId === productId
    //         (item) => item.productId === productId && item.color === color
    //       )
    //     ].quantity += count)
    //   : (state.cartItems[
    //       state.cartItems.findIndex(
    //         // (item) => item.productId === productId
    //         (item) => item.productId === payload.id && item.color === color
    //       )
    //     ].quantity += count);

    // setState({
    //   ...state,
    //   ...sumItems(state.cartItems),
    //   cartItems: [...state.cartItems],
    // });
    // dispatch({ type: 'INCREASE', payload });
  };

  const guestDecrease = (payload, count, selectedPresentation) => {
    cartItems[
      cartItems.findIndex(
        (item) =>
          item.attributes.productos.data[0].id === payload &&
          item.attributes.selectedPresentation === selectedPresentation
      )
    ].attributes.cantidad -= count;
    setCartItems([...cartItems]);
    localStorage.setItem("cartItems", JSON.stringify([...cartItems]));
  };

  const emptyUserCart = () => {
    setCartItems([]);
    localStorage.removeItem("cartItems");
  };

  const guestEmptyCart = () => {
    setCartItems([]);
    localStorage.removeItem("cartItems");
    localStorage.removeItem("user");
    // dispatch({ type: 'REMOVE_ITEM', payload });
  };

  // const getUserCart = async (userId) => {
  //   try {
  //     const response = await getCart(userId);
  //     if (response.status === 200) {
  //       response.json().then((json) => {
  //         setCartItems(json);
  //         const token = json;
  //         if (token) {
  //           localStorage.setItem("cartItems", JSON.stringify(json));
  //         }
  //       });
  //       return response;
  //     }

  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const logout = () => {
  //   localStorage.removeItem("user");
  //   setUser({});
  // };

  // const isAuthenticated = () => {
  //   const currentUser = localStorage.getItem("user");
  //   if (!currentUser) {
  //     return {};
  //   }
  //   return JSON.parse(currentUser);
  // };

  const getTotalPriceCart = (cartItems) => {
    const amount = cartItems?.reduce(function (tally, cartItem) {
      // console.log(cartItem);
      return tally + cartItem.Quantity * cartItem.Sale;
    }, 0);
    return amount;
  };

  useEffect(() => {
    const getUserCart = async () => {
      // console.log("c0rr9+0");
      // if (Object.keys(currentUser).length !== 0) {
      if (currentUser.jwt) {
        // console.log("entró");
        try {
          const response = await getRegisteredUserCart(
            currentUser.user.id,
            currentUser.jwt
          );
          // console.log(response);
          if (response.data.length > 0) {
            // console.log(response);
            setCartItems(response.data);
            // const token = response;
            localStorage.setItem("cartItems", JSON.stringify(response.data));
            // if (token) {
            // }
            return response;
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (currentUser.guest === true) {
        // console.log(localStorage.getItem("cartItems"));
        setCartItems(JSON.parse(localStorage.getItem("cartItems")));
      }
    };
    getUserCart();
  }, [currentUser]);

  useEffect(() => {
    setTotalItems(
      cartItems.reduce(
        (total, product) => total + product.attributes.cantidad,
        0
      )
    );
    setTotalPriceCart(
      cartItems.reduce(function (tally, cartItem) {
        return (
          tally +
          cartItem.attributes.cantidad *
            (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
              (currentPresentation) =>
                currentPresentation.Presentacion ===
                cartItem.attributes.selectedPresentation
            )?.precio -
              (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                (currentPresentation) =>
                  currentPresentation.Presentacion ===
                  cartItem.attributes.selectedPresentation
              )?.precio *
                cartItem.attributes.productos.data[0].attributes
                  .PresentacionesDisponibles[
                  cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                    (presentation) =>
                      presentation.Presentacion ===
                      cartItem.attributes.selectedPresentation
                  )
                ]?.descuento) /
                100)
        );
      }, 0)
    );
    setOriginalPriceCart(
      cartItems.reduce(function (tally, cartItem) {
        return (
          tally +
          cartItem.attributes.cantidad *
            (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
              (currentPresentation) =>
                currentPresentation.Presentacion ===
                cartItem.attributes.selectedPresentation
            )?.precio -
              (cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.find(
                (currentPresentation) =>
                  currentPresentation.Presentacion ===
                  cartItem.attributes.selectedPresentation
              )?.precio *
                cartItem.attributes.productos.data[0].attributes
                  .PresentacionesDisponibles[
                  cartItem.attributes.productos.data[0].attributes.PresentacionesDisponibles.findIndex(
                    (presentation) =>
                      presentation.Presentacion ===
                      cartItem.attributes.selectedPresentation
                  )
                ]?.descuento) /
                100)
        );
      }, 0)
    );
  }, [cartItems]);

  useEffect(() => {
    if (originalPriceCart >= 1300) {
      setHasAchievedNationalPromotion(true);
    } else {
      setHasAchievedNationalPromotion(false);
    }
  }, [originalPriceCart, setHasAchievedNationalPromotion, cartItems]);

  // console.log("currentUser", currentUser);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        getTotalPriceCart,
        addProductToCart,
        guestAddFuProductToCart,
        guestRemoveProduct,
        guestEmptyCart,
        guestIncrease,
        guestDecrease,
        emptyUserCart,
        totalItems,
        totalPriceCart,
        authQuantityChangeProduct,
        authRemoveProduct,
        authEmptyCart,
        setTotalPriceCart,
        hasAchievedNationalPromotion,
        setHasAchievedNationalPromotion,
        hasAppliedCoupon,
        setHasAppliedCoupon,
        originalPriceCart,
        setOriginalPriceCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
