import { useContext, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

// Context
import { useAuth } from "../../contexts/AuthContext";
import { useCart } from "../../contexts/CartContext";
import AlertContext from "../../contexts/AlertContext";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

// Hooks
// import useFetch from "../../hooks/useFetch";

// Custom components
import Slider from "react-slick";

// Custom styles
import "./customNav.styles.scss";

// API
import { getCategories, getSubCategories } from "../../api/product";

// Utils
import { v4 as uuidv4 } from "uuid";

// Assets
import logo from "../../assets/img/logo_paris.svg";
import accountIcon from "../../assets/img/acount-icon.svg";
// import searchIcon from "../../assets/img/search-icon.svg";
import shoppingBagIcon from "../../assets/img/shopping-bag-icon.svg";

// Slick Carousel CSS files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CustomNav() {
  const [loading, setLoading] = useState(false);
  const { currentUser, setUser } = useAuth();
  const { cartItems, setCartItems, totalItems } = useCart();
  const [categories, setCategories] = useState([]);
  const [categoriesFormatted, setCategoriesFormatted] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [subcategoriesFormatted, setSubCategoriesFormatted] = useState([]);

  const formatSubCategories = (subcategories, categories) => {
    const formattedSubCategories = {};
    const productCategories = [];

    subcategories.sort(
      (firstSub, secondSub) => (firstSub.id > secondSub.id && 1) || -1
    );

    subcategories.forEach((category) => {
      // console.log(category);
      const subcategoryCategory = categories.find(
        (currentCategory) =>
          currentCategory.id === category.attributes.categoria.data.id
      );
      const subcategoryCategoryName = subcategoryCategory?.attributes.nombre;
      // console.log(subcategoryCategoryName);

      if (productCategories.indexOf(subcategoryCategoryName) === -1) {
        productCategories.push(subcategoryCategoryName);
        formattedSubCategories[subcategoryCategoryName] = [];
      }
      // console.log(productCategories);
      formattedSubCategories[subcategoryCategoryName].push(category);
    });
    return { formattedSubCategories, productCategories };
  };

  const alert = useContext(AlertContext);

  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("cartItems");
    alert.success(`Saliste de tu cuenta.`);

    setUser({});
    setCartItems([]);
    history.push("/");
  };

  const guestLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("cartItems");
    alert.success(`Saliste del modo invitado.`);

    setUser({});
    setCartItems([]);
    history.push("/");
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getCategories();
      if (response.data) {
        setCategories(response.data);
        setLoading(false);
      } else {
        setCategories([]);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getSubCategories();
      if (response.data) {
        setSubCategories(response.data);
        setLoading(false);
      } else {
        setSubCategories([]);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (categories.length > 0 && subcategories.length > 0) {
      const { formattedSubCategories, productCategories } = formatSubCategories(
        subcategories,
        categories
      );
      setCategoriesFormatted(formattedSubCategories);
      setSubCategoriesFormatted(productCategories);
    }
  }, [categories.length, subcategories.length]);

  const settings = {
    fade: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 4000,
    cssEase: "ease-in",
  };

  return (
    <>
      <div className="custom-nav__top">
        <div className="slick-container">
          <Slider {...settings}>
            <div>
              <p>envíos gratis en compras de $800 en CDMX</p>
            </div>
            <div>
              <p>envíos nacionales gratis en compras de $1300</p>
            </div>
          </Slider>
        </div>
      </div>
      <Navbar collapseOnSelect className="custom-nav" expand="lg">
        <Container>
          <Navbar.Brand as={NavLink} to="/">
            <img src={logo} alt="Dammann" />
          </Navbar.Brand>
          <div className="custom-nav__actions--mobile">
            {Object.keys(currentUser).length === 0 ||
            currentUser.guest === true ? (
              <Nav.Link
                as={NavLink}
                to="/login"
                eventKey={4}
                className="profile-cta mx-lg-3"
              >
                <img src={accountIcon} alt="Dammann" />
              </Nav.Link>
            ) : (
              <Nav.Link
                as={NavLink}
                to="/profile"
                eventKey={uuidv4().slice(0, 8)}
                className="profile-cta mx-lg-3"
              >
                <img src={accountIcon} alt="Dammann" />
              </Nav.Link>
            )}
            {/* <Nav.Link as={NavLink} to="/profile" eventKey={4} className="mx-3">
              <img src={accountIcon} alt="Dammann" />
            </Nav.Link> */}
            <Nav.Link
              as={NavLink}
              to="/cart"
              eventKey={4}
              className="custom-nav__actions__cart"
            >
              <img src={shoppingBagIcon} alt="Dammann" />
              {/* <span>{totalItems}</span> */}
            </Nav.Link>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* <div className="navbar__search">
            <Form
              className="d-flex"
              onSubmit={(e) => {
                e.preventDefault(); // prevents default form action
                history.push(`/results/${data.slug}`); // navigate
                setData({ slug: "" });
              }}
            >
              <FormControl
                type="search"
                placeholder="Buscar producto..."
                aria-label="Search"
                value={data.slug}
                onChange={(e) => setData({ ...data, slug: e.target.value })}
              />
              <div
                className={`navbar__search__results 
                show
                `}
              >
                <div className="navbar__search__results__container">
                  {data.slug !== "" &&
                    data.results?.Names?.map((result, key) => (
                      // data.results?.Names?.slice(0, 5).map((result, key) => (
                      <Link
                        key={key}
                        to={`/product/${data.results?.ID[key]}`}
                        onClick={() => {
                          setData({ slug: "" });
                        }}
                      >
                        <img
                          src={`https://gamerscode.mx/img/products/${data.results?.ID[key]}.jpg`}
                          alt={result}
                        />
                        {result}
                      </Link>
                    ))}
                  {data.slug !== "" && data.results?.Names?.length === 0 && (
                    <h6>No hay productos</h6>
                  )}
                </div>
              </div>
            </Form>
          </div> */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link as={NavLink} to="/" eventKey={1}>
                inicio
              </Nav.Link>
              {/* TO-DO:
              Los siguientes links son dropdowns: Sabores y Accesorios */}
              {/* <Nav.Link as={NavLink} to="/catalogue" eventKey={2}>
                Catalogue
              </Nav.Link> */}
              <NavDropdown title="Sabores" id="basic-nav-dropdown">
                <Row>
                  {!loading &&
                    subcategoriesFormatted.map((category, key) => (
                      <Col xs="12" md="6" key={key}>
                        <h5>{category}</h5>
                        {/* {console.log(
                          categoriesFormatted[category].find(
                            (subcategory) =>
                              subcategory.attributes.slug !== "tes-blancos"
                          )
                        )} */}
                        {categoriesFormatted[category]
                          ?.sort((a, b) =>
                            a.attributes.nombre.localeCompare(
                              b.attributes.nombre
                            )
                          )
                          // filter(
                          //   (subcategory) =>
                          //     subcategory.attributes.slug !== "tes-blancos"
                          // )

                          // .find(
                          //   (subcategory) =>
                          //     subcategory.attributes.slug !== "tes-blancos"
                          // )
                          .map((subcategory, key) => (
                            <NavDropdown.Item
                              as={NavLink}
                              to={`/catalogue/c/${subcategory.attributes.slug}`}
                              key={key}
                              eventKey={uuidv4().slice(0, 8)}
                            >
                              {subcategory.attributes.nombre}
                            </NavDropdown.Item>
                          ))}
                        <NavDropdown.Item
                          as={NavLink}
                          to={`/catalogue/${category
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .toLowerCase()}`}
                          eventKey={uuidv4().slice(0, 8)}
                        >
                          Ver todos
                        </NavDropdown.Item>
                      </Col>
                    ))}
                  {/* <Col xs="12" md="6">
                    <h5>Tés</h5>
                    <NavDropdown.Item as={NavLink} to="/catalogue/c/te-verde">
                      Tés verdes
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/catalogue/c/te-oolong">
                      Tés Oolongs
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Rooibos
                    </NavDropdown.Item>
                  </Col>
                  <Col xs="12" md="6" className="mt-2 mt-lg-0">
                    <h5>Tisanas</h5>
                    <NavDropdown.Item href="#action/3.1">
                      Action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                  </Col> */}
                </Row>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/catalogue/accesorios" eventKey={3}>
                Accesorios
              </Nav.Link>
              {currentUser.jwt && (
                <p className="d-block d-lg-none" onClick={logout} eventKey={3}>
                  Cerrar sesión
                </p>
              )}
              {currentUser.guest === true && (
                <p
                  className="d-block d-lg-none"
                  onClick={guestLogout}
                  eventKey={3}
                >
                  Salir modo invitado
                </p>
              )}
              {/* <NavDropdown title="Accesorios" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
              </NavDropdown> */}
              {/* <Nav.Link as={NavLink} to="/product/45" eventKey={3}>
                Product
              </Nav.Link> */}

              {/* <Nav.Link as={NavLink} to="/login" eventKey={5}>
                Login
              </Nav.Link>
              <Nav.Link as={NavLink} to="/register" eventKey={6}>
                Register
              </Nav.Link> */}
            </Nav>
            <div className="custom-nav__actions">
              {Object.keys(currentUser).length === 0 ||
              currentUser.guest === true ? (
                <Nav.Link
                  as={NavLink}
                  to="/login"
                  eventKey={4}
                  className="mx-3"
                >
                  <img src={accountIcon} alt="Dammann" />
                </Nav.Link>
              ) : (
                <Nav.Link
                  as={NavLink}
                  to="/profile"
                  eventKey={4}
                  className="mx-3"
                >
                  <img src={accountIcon} alt="Dammann" />
                </Nav.Link>
              )}
              <Nav.Link
                as={NavLink}
                to="/cart"
                eventKey={4}
                className="custom-nav__actions__cart"
              >
                <img src={shoppingBagIcon} alt="Dammann" />
                {/* <span>{totalItems}</span> */}
              </Nav.Link>
              {/* {Object.keys(currentUser).length !== 0 && (
                <p onClick={logout}>Cerrar sesión</p>
              )} */}
              {currentUser.jwt && <p onClick={logout}>Cerrar sesión</p>}
              {currentUser.guest === true && (
                <p onClick={guestLogout}>Salir modo invitado</p>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default CustomNav;
