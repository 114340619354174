import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";

// Custom components
// import { ProductCarousel } from "../components/ProductCarousel/ProductCarousel";

// Custom styles
import "../scss/pages/product.styles.scss";

// Context
import { useAuth } from "../contexts/AuthContext";
import { useCart } from "../contexts/CartContext";
import AlertContext from "../contexts/AlertContext";

// API
import { getProduct } from "../api/product";
import { addItemWishlist } from "../api/auth";

// Utils
import formatMoney from "../utils/formatMoney";
import Slider from "react-slick";

// Slick Carousel CSS files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
// import productTest from "../assets/img/product1.jpg";
// import canIcon from "../assets/img/product1.jpg";
// import teaIcon from "../assets/img/product1.jpg";
// import favoriteIcon from "../assets/img/favorite-icon.svg";
// import favoriteIconBlack from "../assets/img/favorite-icon--black.svg";
import shoppingBagIcon from "../assets/img/shopping-bag-icon.svg";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

const settings = {
  arrows: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Product() {
  const { id } = useParams();
  const { currentUser, setUser } = useAuth();
  const history = useHistory();
  const { addProductToCart, guestAddFuProductToCart, setCartItems } = useCart();

  const [loading, setLoading] = useState(false);
  const [addToCartLoading, seAddToCartLoading] = useState(false);
  const [addToFavorites, setAddToFavorites] = useState(false);
  const [product, setProduct] = useState({});

  const [quantity, setQuantity] = useState(1);
  const [stock, setStock] = useState(0);

  const [sachetChecked, setSachetChecked] = useState(true);
  const [sachetSecondChecked, setSachetSecondChecked] = useState(true);
  const [sachet20Checked, setSachet20Checked] = useState(false);
  const [sachet25Checked, setSachet25Checked] = useState(false);
  const [canChecked, setCanChecked] = useState(false);
  const [can20Checked, setCan20Checked] = useState(false);
  const [can45Checked, setCan45Checked] = useState(false);

  const [can35Checked, setCan35Checked] = useState(false);
  const [can60Checked, setCan60Checked] = useState(false);
  const [can90Checked, setCan90Checked] = useState(false);

  const [selectedPresentation2, setSelectedPresentation2] = useState("");

  const [productPrice, setProductPrice] = useState(0);
  const [productDiscount, setProductDiscount] = useState(0);

  // const [wasClicked, setWasClicked] = useState(false);
  const alert = useContext(AlertContext);

  function decrease() {
    if (quantity === 1) {
      return;
    }
    setQuantity(quantity - 1);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getProduct(id);
      if (response.data) {
        setProduct(response.data[0]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (canChecked) {
      setCanChecked(true);
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata"
          )
        ]?.precio
      );
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata"
          )
        ]?.descuento
      );
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata"
          )
        ]?.stock
      );
      setSelectedPresentation2("Lata");
      setSachetChecked(false);
      setSachet20Checked(false);
      setSachet25Checked(false);
      setCan20Checked(false);
      setCan45Checked(false);
      setCan35Checked(false);
      setCan60Checked(false);
      setCan90Checked(false);
    } else if (can20Checked) {
      setCan20Checked(true);
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata20"
          )
        ]?.precio
      );
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata20"
          )
        ]?.descuento
      );
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata20"
          )
        ]?.stock
      );
      setSelectedPresentation2("Lata20");
      setCanChecked(false);
      setCan45Checked(false);
      setSachetChecked(false);
      setSachet25Checked(false);
      setCan35Checked(false);
      setCan60Checked(false);
      setCan90Checked(false);
    } else if (can45Checked) {
      setCan45Checked(true);
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata45"
          )
        ]?.precio
      );
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata45"
          )
        ]?.descuento
      );
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata45"
          )
        ]?.stock
      );
      setSelectedPresentation2("Lata45");
      setCanChecked(false);
      setCan20Checked(false);
      setSachetChecked(false);
      setSachet20Checked(false);
      setSachet25Checked(false);
      setCan35Checked(false);
      setCan60Checked(false);
      setCan90Checked(false);
    } else if (
      // Nos quedamos en adaptar el código para que funcione con propiedad contenido para que funcione dinámicamente
      product.attributes?.PresentacionesDisponibles[
        product.attributes?.PresentacionesDisponibles.findIndex(
          (item) => item.Presentacion === "Sachet"
        )
      ]?.stock > 0 &&
      sachetChecked === true
    ) {
      setSachetChecked(true);
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet"
          )
        ]?.stock
      );
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet"
          )
        ]?.precio
      );
      setSelectedPresentation2("Sachet");
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet"
          )
        ]?.descuento
      );
      setCanChecked(false);
      setCan20Checked(false);
      setCan45Checked(false);
      setSachet20Checked(false);
      setSachet25Checked(false);
      setCan35Checked(false);
      setCan60Checked(false);
      setCan90Checked(false);
    } else if (
      product.attributes?.PresentacionesDisponibles[
        product.attributes?.PresentacionesDisponibles.findIndex(
          (item) => item.Presentacion === "Sachet25"
        )
      ]?.stock > 0 &&
      sachet25Checked === true
    ) {
      setSachet25Checked(true);
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet25"
          )
        ]?.stock
      );
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet25"
          )
        ]?.precio
      );
      setSelectedPresentation2("Sachet25");
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet25"
          )
        ]?.descuento
      );
      setCanChecked(false);
      setCan20Checked(false);
      setCan45Checked(false);
      setSachet20Checked(false);
      setSachetChecked(false);
      setCan35Checked(false);
      setCan60Checked(false);
      setCan90Checked(false);
    } else if (sachet20Checked) {
      setSachet20Checked(true);
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet20"
          )
        ]?.precio
      );
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet20"
          )
        ]?.descuento
      );
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Sachet20"
          )
        ]?.stock
      );
      setSelectedPresentation2("Sachet20");
      setCanChecked(false);
      setCan20Checked(false);
      setCan45Checked(false);
      setSachetChecked(false);
      setSachet25Checked(false);
      setCan35Checked(false);
      setCan60Checked(false);
      setCan90Checked(false);
    }
    // New can presentation implementation
    else if (can35Checked) {
      setCan35Checked(true);
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata35"
          )
        ]?.precio
      );
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata35"
          )
        ]?.descuento
      );
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata35"
          )
        ]?.stock
      );
      setSelectedPresentation2("Lata35");
      setCanChecked(false);
      setCan20Checked(false);
      setCan45Checked(false);
      setSachetChecked(false);
      setSachet25Checked(false);
      setCan60Checked(false);
      setCan90Checked(false);
    } else if (can60Checked) {
      setCan60Checked(true);
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata60"
          )
        ]?.precio
      );
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata60"
          )
        ]?.descuento
      );
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata60"
          )
        ]?.stock
      );
      setSelectedPresentation2("Lata60");
      setCanChecked(false);
      setCan20Checked(false);
      setCan45Checked(false);
      setSachetChecked(false);
      setSachet25Checked(false);
      setCan35Checked(false);
      setCan90Checked(false);
    } else if (can90Checked) {
      setCan90Checked(true);
      setProductPrice(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata90"
          )
        ]?.precio
      );
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata90"
          )
        ]?.descuento
      );
      setStock(
        product.attributes?.PresentacionesDisponibles[
          product.attributes?.PresentacionesDisponibles.findIndex(
            (item) => item.Presentacion === "Lata90"
          )
        ]?.stock
      );
      setSelectedPresentation2("Lata90");
      setCanChecked(false);
      setCan20Checked(false);
      setCan45Checked(false);
      setSachetChecked(false);
      setSachet25Checked(false);
      setCan60Checked(false);
      setCan35Checked(false);
    }
    // End of new can presentation implementation
    else if (product.attributes?.tipoProducto === "Accesorio") {
      setProductPrice(product.attributes?.PresentacionesDisponibles[0]?.precio);
      setProductDiscount(
        product.attributes?.PresentacionesDisponibles[0]?.descuento
      );
      setStock(product.attributes?.PresentacionesDisponibles[0]?.stock);
      setSelectedPresentation2("Accesorio");
    } else if (setCanChecked === false && setSachetChecked === false) {
      setProductPrice(0);
    }
  }, [
    canChecked,
    can20Checked,
    can45Checked,
    sachet25Checked,
    sachetChecked,
    sachet20Checked,
    product,
    selectedPresentation2,
    can90Checked,
    can60Checked,
    can35Checked,
  ]);

  if (loading) {
    return (
      <section className="product-individual__wrapper">
        <div className="product-individual__container">
          <p className="p-3 text-center">
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>{" "}
            Cargando producto
          </p>
        </div>
      </section>
    );
  }

  return (
    <section className="product-individual__wrapper">
      <div
        className={`overlay ${
          addToCartLoading || addToFavorites ? "show" : ""
        }`}
      ></div>
      <div className="product-individual__container">
        <div className="product-individual__media">
          {/* {console.log(product.attributes?.imagenes.data)} */}
          {product.attributes?.imagenes.data.length > 0 ? (
            <Slider {...settings}>
              {product.attributes?.imagenes.data?.map((image, key) => (
                <img
                  key={key}
                  // src={image.attributes?.url}
                  src={`${image.attributes?.url}`}
                  alt={product.attributes?.nombre}
                />
              ))}
            </Slider>
          ) : (
            // <ProductCarousel
            //   productName={product?.attributes.nombre}
            //   productImages={product?.attributes.imagenes.data}
            // />
            <img
              // src={product.attributes?.imagenes.data[0].attributes.url}
              src={`${process.env.REACT_APP_API_URL}${product.attributes?.imagenes.data[0].attributes.url}`}
              alt={product.attributes?.nombre}
            />
          )}
        </div>
        <div className="product-individual__info">
          <div className="product-individual__info__header">
            <div className="d-flex flex-column">
              <p>{product?.attributes?.nombre}</p>
              {product.attributes?.descuento > 0 ? (
                // <span>Producto en descuento</span>
                <></>
              ) : (
                ""
              )}
            </div>
            <div
              className="product-individual__info__header__favorites"
              onClick={async () => {
                let selectedPresentation = "";
                if (
                  product.attributes.tipoProducto === "Té" ||
                  product.attributes.tipoProducto === "Tisana"
                ) {
                  selectedPresentation = sachetChecked ? "Sachet" : "Lata";
                } else {
                  selectedPresentation = "Accesorio";
                }
                if (currentUser.jwt) {
                  // setAddToFavorites(true);
                  const response = await addItemWishlist(
                    currentUser.user.id,
                    product,
                    currentUser.jwt,
                    selectedPresentation2
                  );
                  // console.log(response);
                  if (response.error) {
                    alert.error(response.error);
                    // setAddToFavorites(false);
                    // setWasClicked(true);
                    // setTimeout(() => {
                    //   setWasClicked(false);
                    // }, 10000);
                    return;
                  }
                  if (response.status === 200) {
                    alert.success("Producto añadido a tu lista de favoritos.");
                    // setAddToFavorites(false);
                    // setWasClicked(true);
                    // setTimeout(() => {
                    //   setWasClicked(false);
                    // }, 10000);
                  }
                } else if (currentUser.guest === true) {
                  history.push("/login");
                } else {
                  history.push("/login");
                }
              }}
            >
              <span className="product-individual-favorite"></span>
              <p>Agregar a mi lista</p>{" "}
            </div>
          </div>
          <p className="my-2 my-lg-1">{product.attributes?.descripcionLarga}</p>
          <div className="product-individual__info__details">
            {product.attributes?.tipoProducto === "Té" ||
            product.attributes?.tipoProducto === "Tisana" ? (
              <>
                <div>
                  <span>Notas dominantes:</span>
                  <p>{product.attributes.InformacionTe?.NotasDominantes}</p>
                </div>
                <div>
                  <span>Sabor principal:</span>
                  <p>{product.attributes.InformacionTe?.SaborPrincipal}</p>
                </div>
                <div>
                  <span>Tipo de té:</span>
                  <p>
                    {product.attributes.subcategoria.data.attributes.nombre}
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {product.attributes?.tipoProducto === "Accesorio" && stock === 0 ? (
            <p className="no-stock">
              Actualmente no hay stock de este producto.
            </p>
          ) : null}
          {/* <div className="product-individual__info__quantity">
            {stock === 0 ? null : (
              <>
                <p>cantidad:</p>
                <div>
                  <button onClick={decrease} disabled={quantity === 1}>
                    -
                  </button>
                  <span>{quantity}</span>
                  <button
                    onClick={() => {
                      if (quantity < stock) {
                        setQuantity(quantity + 1);
                      }
                    }}
                    disabled={stock === quantity}
                  >
                    +
                  </button>
                </div>
              </>
            )}
          </div> */}
          {/* <div className="product-individual__info__price">
            {productPrice === 0 ? (
              <p className="no-price">
                Por favor selecciona una presentación de producto para ver el
                precio y también agregarlo a tus favoritos.
              </p>
            ) : product.attributes?.tipoProducto === "Té" ||
              product.attributes?.tipoProducto === "Tisana" ? (
              product.attributes?.PresentacionesDisponibles[
                product.attributes?.PresentacionesDisponibles.findIndex(
                  (presentation) =>
                    presentation.Presentacion === selectedPresentation2
                )
              ].descuento > 0 ? (
                <>
                  <div className="product-individual__info__price-prices">
                    <div className="d-flex flex-row align-items-center">
                      <p
                        style={{
                          textDecoration: "line-through",
                          width: "max-content",
                        }}
                        className="text-start old-price"
                      >
                        {formatMoney(productPrice)}{" "}
                      </p>
                      <span>{`- ${
                        product.attributes?.PresentacionesDisponibles[
                          product.attributes?.PresentacionesDisponibles.findIndex(
                            (presentation) =>
                              presentation.Presentacion ===
                              selectedPresentation2
                          )
                        ].descuento
                      } % descuento`}</span>
                    </div>
                    <p className="text-start">
                      {formatMoney(
                        product.attributes?.PresentacionesDisponibles[
                          product.attributes?.PresentacionesDisponibles.findIndex(
                            (presentation) =>
                              presentation.Presentacion ===
                              selectedPresentation2
                          )
                        ].precio -
                          (product.attributes?.PresentacionesDisponibles[
                            product.attributes?.PresentacionesDisponibles.findIndex(
                              (presentation) =>
                                presentation.Presentacion ===
                                selectedPresentation2
                            )
                          ].precio *
                            product.attributes?.PresentacionesDisponibles[
                              product.attributes?.PresentacionesDisponibles.findIndex(
                                (presentation) =>
                                  presentation.Presentacion ===
                                  selectedPresentation2
                              )
                            ].descuento) /
                            100
                      )}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="product-individual__info__price">
                    <p>
                      {formatMoney(
                        product.attributes?.PresentacionesDisponibles[
                          product.attributes?.PresentacionesDisponibles.findIndex(
                            (item) =>
                              item.Presentacion === selectedPresentation2
                          )
                        ]?.precio
                      )}
                    </p>
                  </div>
                </>
              )
            ) : (
              <></>
            )}
            {product.attributes?.tipoProducto === "Accesorio" ? (
              product.attributes.descuento > 0 ? (
                <>
                  <div className="product-individual__info__price-prices">
                    <div className="d-flex flex-row align-items-center">
                      <p
                        style={{
                          textDecoration: "line-through",
                          width: "max-content",
                        }}
                        className="text-start old-price"
                      >
                        {formatMoney(productPrice)}{" "}
                      </p>
                      <span>{`- ${product.attributes?.PresentacionesDisponibles[0].descuento} % descuento`}</span>
                    </div>
                    <p className="text-start">
                      {formatMoney(
                        product.attributes?.PresentacionesDisponibles[0]
                          .precio -
                          (product.attributes?.PresentacionesDisponibles[0]
                            .precio *
                            product.attributes?.PresentacionesDisponibles[0]
                              .descuento) /
                            100
                      )}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="product-card__body__product-price">
                    <p>
                      {formatMoney(
                        product.attributes?.PresentacionesDisponibles[0].precio
                      )}
                    </p>
                  </div>
                </>
              )
            ) : (
              <></>
            )}
          </div> */}
          {/* <div className="product-individual__info__cta">
            <button
              disabled={productPrice === 0 || stock === 0}
              onClick={async () => {
                let selectedPresentation = "";
                if (
                  product.attributes.tipoProducto === "Té" ||
                  product.attributes.tipoProducto === "Tisana"
                ) {
                  selectedPresentation = sachetChecked ? "Sachet" : "Lata";
                } else {
                  selectedPresentation = "Accesorio";
                }
                ReactPixel.trackCustom("AddToCart", {
                  content_name: "Add to Cart",
                });
                ReactGA.event({
                  category: "Ecommerce",
                  action: "Add to Cart",
                  label: "add_to_cart",
                });
                if (currentUser.jwt) {
                  seAddToCartLoading(true);
                  const response = await addProductToCart(
                    currentUser.user.id,
                    product,
                    quantity,
                    currentUser.jwt,
                    selectedPresentation2
                  );
                  if (response.status === 200) {
                    alert.success("Producto añadido a tu bolsa de compra");
                    seAddToCartLoading(false);
                  } else {
                    alert.error(
                      "El producto no pudo ser añadido a tu bolsa de compra"
                    );
                    seAddToCartLoading(false);
                  }
                } else if (currentUser.guest === true) {
                  seAddToCartLoading(true);
                  guestAddFuProductToCart(
                    product,
                    id,
                    selectedPresentation2,
                    quantity
                  );
                  alert.success("Producto añadido a tu bolsa de compra");
                  seAddToCartLoading(false);
                } else {
                  seAddToCartLoading(true);
                  setCartItems([
                    {
                      attributes: {
                        productos: { data: [product] },
                        skuId: id,
                        selectedPresentation: selectedPresentation2,
                        cantidad: quantity,
                      },
                      id: 0,
                    },
                  ]);
                  localStorage.setItem(
                    "cartItems",
                    JSON.stringify([
                      {
                        attributes: {
                          cantidad: quantity,
                          selectedPresentation: selectedPresentation2,
                          productos: { data: [product] },
                          skuId: id,
                        },
                        id: 0,
                      },
                    ])
                  );
                  seAddToCartLoading(false);
                  history.push("/guest");
                }
                setQuantity(1);
              }}
              className={addToCartLoading ? "zIndex" : ""}
            >
              {addToCartLoading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Cargando...</span>
                </Spinner>
              ) : (
                <>
                  <span>Añadir a la bolsa </span>{" "}
                  <img src={shoppingBagIcon} alt="Añadir a la bolsa" />
                </>
              )}
            </button>
            <button
              className={addToFavorites ? "zIndex" : ""}
              disabled={productPrice === 0 || stock === 0}
              onClick={async () => {
                if (currentUser.jwt) {
                  setAddToFavorites(true);
                  const response = await addProductToCart(
                    currentUser.user.id,
                    product,
                    quantity,
                    currentUser.jwt,
                    selectedPresentation2
                  );
                  if (response.status === 200) {
                    alert.success("Producto añadido a tu bolsa de compra");
                    history.push("/checkout");
                    setAddToFavorites(false);
                  } else {
                    alert.error(
                      "El producto no pudo ser añadido a tu bolsa de compra"
                    );
                    setAddToFavorites(false);
                  }
                } else if (currentUser.guest === true) {
                  setAddToFavorites(true);
                  guestAddFuProductToCart(
                    product,
                    id,
                    selectedPresentation2,
                    quantity
                  );
                  alert.success("Producto añadido a tu bolsa de compra");
                  history.push("/checkout");
                  setAddToFavorites(false);
                } else {
                  setAddToFavorites(true);
                  setCartItems([
                    {
                      attributes: {
                        productos: { data: [product] },
                        skuId: id,
                        selectedPresentation: selectedPresentation2,
                        cantidad: quantity,
                      },
                      id: 0,
                    },
                  ]);
                  localStorage.setItem(
                    "cartItems",
                    JSON.stringify([
                      {
                        attributes: {
                          cantidad: quantity,
                          selectedPresentation: selectedPresentation2,
                          productos: { data: [product] },
                          skuId: id,
                        },
                        id: 0,
                      },
                    ])
                  );
                  setAddToFavorites(false);
                  history.push("/guest");
                }
                setQuantity(1);
              }}
            >
              {addToFavorites ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Cargando...</span>
                </Spinner>
              ) : (
                <>
                  <span>Comprar ahora</span>
                </>
              )}
            </button>
          </div> */}
          <p className="keep-shopping" onClick={() => history.goBack()}>
            Regresar
          </p>
        </div>
      </div>
    </section>
  );
}

export default Product;
