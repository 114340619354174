import { Link } from "react-router-dom";

// Custom styles
import "./productCard.styles.scss";

// Bootstrap components
import Col from "react-bootstrap/Col";

// Utils
import formatMoney from "../../utils/formatMoney";
import Slider from "react-slick";

// Assets
import productTest from "../../assets/img/product1.jpg";
import favoriteIcon from "../../assets/img/favorite-icon.svg";
import shoppingBagIcon from "../../assets/img/shopping-bag-icon.svg";

const settings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function ProductCard({ product }) {
  return (
    <Col className="h-100">
      <div className="product-card">
        {product.attributes?.producto.data.attributes.descuento > 0 ? (
          // <span className="product-card__discount-label">
          //   producto en descuento
          // </span>
          <></>
        ) : (
          <></>
        )}
        <Link
          to={`/product/${product.attributes?.producto?.data?.attributes.slug}`}
        >
          <div className="product-card__media">
            <Slider {...settings}>
              {product.attributes?.producto?.data?.attributes.imagenes.data?.map(
                (image, key) => (
                  // <img
                  //   key={key}
                  //   src={image.attributes?.url}
                  //   // src={`${process.env.REACT_APP_API_URL}${image.attributes?.url}`}
                  //   alt={product.attributes?.nombre}
                  // />
                  <img
                    key={key}
                    src={image.attributes?.url}
                    // src={`${product.attributes?.imagenes.data.attributes.url}`}
                    alt={image.attributes?.nombre}
                  />
                )
              )}
            </Slider>
            {/* <img
              // src={
              //   product.attributes?.producto.data.attributes.imagenes.data[0]
              //     .attributes.url
              // }
              src={`${product.attributes?.producto.data.attributes.imagenes.data[0].attributes.url}`}
              alt={product.attributes?.producto.data.attributes.nombre}
            /> */}
          </div>
          <div className="product-card__body">
            <h5 className="product-card__body__product-name">
              {product.attributes?.producto.data.attributes.nombre}
            </h5>
            <p className="product-card__body__product-description">
              {product.attributes?.producto.data.attributes.descripcion}
            </p>
            {product.attributes?.producto.data.attributes.tipoProducto !==
              "Accesorio" &&
            product.attributes?.producto.data.attributes?.InformacionTe
              ?.NotasDominantes !== null &&
            product.attributes?.producto.data.attributes?.InformacionTe
              ?.SaborPrincipal !== null &&
            product.attributes?.producto.data.attributes?.InformacionTe !==
              null ? (
              <div className="product-card__body__product-details">
                <p>
                  <span>Notas dominantes:</span>
                  {
                    product.attributes?.producto.data.attributes.InformacionTe
                      .NotasDominantes
                  }
                </p>
                <p>
                  <span>
                    {product.attributes?.producto.data.attributes
                      .tipoProducto !== "Té"
                      ? "Tipo de tisana:"
                      : "Tipo de té:"}
                  </span>
                  {
                    product.attributes?.producto.data.attributes.subcategoria
                      .data.attributes.nombre
                  }
                </p>
                <p>
                  <span>Sabor principal:</span>
                  {
                    product.attributes?.producto.data.attributes.InformacionTe
                      .SaborPrincipal
                  }
                </p>
              </div>
            ) : null}
            {/* <div className="product-card__body__product-price">
              {product.attributes?.producto.data.attributes?.descuento > 0 &&
              product.attributes?.producto.data.attributes
                .PresentacionesDisponibles[0].descuento > 0 ? (
                <div className="product-card__body__product-price-prices">
                  <div className="d-flex">
                    <p style={{ textDecoration: "line-through" }}>
                      {formatMoney(
                        product.attributes?.producto.data.attributes
                          .PresentacionesDisponibles[0]?.precio
                      )}{" "}
                    </p>
                    {product.attributes?.producto.data.attributes
                      .tipoProducto !== "Accesorio" ? (
                      <span>{`- ${
                        product.attributes?.producto.data.attributes
                          ?.PresentacionesDisponibles[
                          product.attributes?.producto.data.attributes?.PresentacionesDisponibles?.findIndex(
                            (presentation) =>
                              presentation.Presentacion === "Sachet" ||
                              presentation.Presentacion === "Sachet25" ||
                              presentation.Presentacion === "Sachet20"
                          )
                        ]?.descuento
                      } % descuento`}</span>
                    ) : (
                      <span>{`- ${
                        product.attributes?.producto.data.attributes
                          ?.PresentacionesDisponibles[
                          product.attributes?.producto.data.attributes?.PresentacionesDisponibles?.findIndex(
                            (presentation) =>
                              presentation.Presentacion === "Accesorio"
                          )
                        ]?.descuento
                      } % descuento`}</span>
                    )}
                  </div>
                  <p className="mt-lg-2">
                    {product.attributes?.producto.data.attributes
                      .tipoProducto !== "Accesorio" ? (
                      <>
                        {formatMoney(
                          product.attributes?.producto.data.attributes
                            ?.PresentacionesDisponibles[0].precio -
                            (product.attributes?.producto.data.attributes
                              ?.PresentacionesDisponibles[
                              product.attributes?.producto.data.attributes?.PresentacionesDisponibles?.findIndex(
                                (presentation) =>
                                  presentation.Presentacion === "Sachet" ||
                                  presentation.Presentacion === "Sachet25" ||
                                  presentation.Presentacion === "Sachet20"
                              )
                            ]?.precio *
                              product.attributes?.producto.data.attributes
                                ?.PresentacionesDisponibles[
                                product.attributes?.producto.data.attributes?.PresentacionesDisponibles?.findIndex(
                                  (presentation) =>
                                    presentation.Presentacion === "Sachet" ||
                                    presentation.Presentacion === "Sachet25" ||
                                    presentation.Presentacion === "Sachet20"
                                )
                              ]?.descuento) /
                              100
                        )}{" "}
                        <span>
                          {product.attributes?.producto.data.attributes.PresentacionesDisponibles[0].Presentacion.includes(
                            "Lata"
                          )
                            ? product.attributes?.producto.data.attributes
                                .PresentacionesDisponibles[0].Presentacion ===
                              "Lata20"
                              ? "Lata 20 gr."
                              : product.attributes?.producto.data.attributes
                                  .PresentacionesDisponibles[0].Presentacion ===
                                "Lata45"
                              ? "Lata 45 gr."
                              : product.attributes?.producto.data.attributes
                                  .PresentacionesDisponibles[0].Presentacion ===
                                "Lata35"
                              ? "Lata 35 gr."
                              : product.attributes?.producto.data.attributes
                                  .PresentacionesDisponibles[0].Presentacion ===
                                "Lata60"
                              ? "Lata 60 gr."
                              : product.attributes?.producto.data.attributes
                                  .PresentacionesDisponibles[0].Presentacion ===
                                "Lata90"
                              ? "Lata 90 gr."
                              : "Lata 100 gr."
                            : product.attributes?.producto.data.attributes
                                .PresentacionesDisponibles[0].Presentacion ===
                              "Sachet25"
                            ? "25 sachets"
                            : product.attributes?.producto.data.attributes
                                .PresentacionesDisponibles[0].Presentacion ===
                              "Sachet20"
                            ? "20 sachets"
                            : "24 sachets"}
                        </span>
                      </>
                    ) : (
                      formatMoney(
                        product.attributes?.producto.data.attributes
                          ?.PresentacionesDisponibles[0].precio -
                          (product.attributes?.producto.data.attributes
                            ?.PresentacionesDisponibles[0]?.precio *
                            product.attributes?.producto.data.attributes
                              ?.PresentacionesDisponibles[0]?.descuento) /
                            100
                      )
                    )}
                  </p>
                </div>
              ) : (
                <>
                  <p>
                    {" "}
                    {formatMoney(
                      product.attributes?.producto.data.attributes
                        .PresentacionesDisponibles[0].precio
                    )}
                    <span>
                      {" "}
                      {product.attributes?.producto.data.attributes.PresentacionesDisponibles[0].Presentacion.includes(
                        "Lata"
                      )
                        ? product.attributes?.producto.data.attributes
                            .PresentacionesDisponibles[0].Presentacion ===
                          "Lata20"
                          ? "Lata 20 gr."
                          : product.attributes?.producto.data.attributes
                              .PresentacionesDisponibles[0].Presentacion ===
                            "Lata45"
                          ? "Lata 45 gr."
                          : product.attributes?.producto.data.attributes
                              .PresentacionesDisponibles[0].Presentacion ===
                            "Lata35"
                          ? "Lata 35 gr."
                          : product.attributes?.producto.data.attributes
                              .PresentacionesDisponibles[0].Presentacion ===
                            "Lata60"
                          ? "Lata 60 gr."
                          : product.attributes?.producto.data.attributes
                              .PresentacionesDisponibles[0].Presentacion ===
                            "Lata90"
                          ? "Lata 90 gr."
                          : "Lata 100 gr."
                        : product.attributes?.producto.data.attributes
                            .PresentacionesDisponibles[0].Presentacion ===
                          "Sachet25"
                        ? "25 sachets"
                        : product.attributes?.producto.data.attributes
                            .PresentacionesDisponibles[0].Presentacion ===
                          "Sachet20"
                        ? "20 sachets"
                        : "24 sachets"}
                    </span>
                  </p>
                </>
              )}
            </div> */}
            {/* {formatMoney(
                product.attributes?.producto.data.attributes
                  .PresentacionesDisponibles[0].precio
              )} */}
            {/* <p>
              {formatMoney(
                product.attributes?.producto.data.attributes
                  ?.PresentacionesDisponibles[0].precio -
                  (product.attributes?.producto.data.attributes
                    ?.PresentacionesDisponibles[
                    product.attributes?.producto.data.attributes?.PresentacionesDisponibles?.findIndex(
                      (presentation) => presentation.Presentacion === "Sachet"
                    )
                  ].precio *
                    product.attributes?.producto.data.attributes
                      ?.PresentacionesDisponibles[
                      product.attributes?.producto.data.attributes?.PresentacionesDisponibles?.findIndex(
                        (presentation) => presentation.Presentacion === "Sachet"
                      )
                    ].descuento) /
                    100
              )}
              <span>24 sachets</span>
            </p> */}
            {/* {
                product.attributes?.producto.data.attributes
                  .PresentacionesDisponibles[0].Presentacion
              } */}
          </div>
          <div className="product-card__cta mt-auto mt-lg-2">
            {/* <button onClick={() => console.log("Yo this was clicked")}>
            <span>Añadir a la bolsa </span>
            <img src={shoppingBagIcon} alt="Añadir a la bolsa" />
          </button>
          <button onClick={() => console.log("Yo this was clicked")}>
            <span> Agregar a mi lista </span>
            <img src={favoriteIcon} alt="Agregar a mi lista" />
          </button> */}
            <p className="product-card__cta__toProduct">Ver producto</p>
          </div>
        </Link>
      </div>
    </Col>
  );
}

export default ProductCard;
