import React from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

// Custom styles
import "../scss/pages/cookies.styles.scss";

function Cookies() {
  return (
    <section className="cookies__wrapper">
      <div className="cookies__hero">
        <Container fluid>
          <h3>Política de cookies</h3>
        </Container>
      </div>
      <div className="cookies__container">
        <Container>
          <p>
            CKN de México, S.A de C.V., con domicilio en Cerrada de
            Constituyentes 25 Col. Lomas Altas C.P 1950 es el responsable del
            uso y protección de sus datos personales, y al respecto le
            informamos lo siguiente: ¿Para qué fines utilizaremos sus datos
            personales? Los datos personales que recabamos de usted, los
            utilizaremos para las siguientes finalidades que son necesarias para
            el servicio que solicita:
          </p>
          <ul>
            <li>
              Conocer al Cliente, proveedores, socios para entender sus
              necesidades y dar seguimiento a sus consultas e intereses.
            </li>
            <li>
              Informar sobre productos, servicios, promociones y cualquier
              imponderable que de lo anterior pueda surgir.
            </li>
            <li>
              Monitorear la satisfacción del cliente o las partes interesadas
              que hayan solicitado información o finalizado un contrato de
              compra-venta con la empresa y sus asociados.
            </li>
          </ul>
          <p>
            De manera adicional, utilizaremos su información personal para las
            siguientes finalidades que no son necesarias para el servicio
            solicitado, pero que nos permiten y facilitan brindarle una mejor
            atención:
          </p>
          <ul>
            <li>Conocimiento del mercado</li>
            <li>Mejoramiento de proceso de calidad</li>
          </ul>
          <p>
            En caso de que no desee que sus datos personales sean tratados para
            estos fines adicionales, desde este momento usted nos puede
            comunicar lo anterior, si la parte interesada con nuestra compañía
            decide no compartir ningún tipo de información personal, nuestros
            agentes asi como los empleados de nuetra compañía no podrán
            solicitar ni condicionar nuestros servicios a cambio de ningún tipo
            de negociación. Asimismo, si la parte interesada decidió compartir
            información personal, también podrá exigir que su información no sea
            publicada por ninguno de nuestros medios ni mecanismos de
            comunicación interna ni externa. La negativa para el uso de sus
            datos personales para estas finalidades no podrá ser un motivo para
            que le neguemos los servicios y productos que solicita o contrata
            con nosotros.
          </p>
          <h4>¿QUÉ DATOS PERSONALES UTILIZAREMOS PARA ESTOS FINES? </h4>
          <p>
            Para llevar a cabo las finalidades descritas en el presente aviso de
            privacidad, utilizaremos los siguientes datos personales:
          </p>
          <ul>
            <li>Nombre</li>
            <li>Teléfono</li>
            <li>Correo electrónico</li>
            <li>Código postal</li>
          </ul>

          <p>
            Además de los datos personales mencionados anteriormente, para las
            finalidades informadas en el presente aviso de privacidad
            utilizaremos los siguientes datos personales considerados como
            sensibles, que requieren de especial protección: ocupación y niveles
            de ingreso.
          </p>
          <h4>
            ¿CON QUIÉN COMPARTIMOS SU INFORMACIÓN PERSONAL Y PARA QUÉ FINES?{" "}
          </h4>
          <p>
            Le informamos que sus datos personales son compartidos dentro y
            fuera del país con las siguientes personas, empresas, organizaciones
            y autoridades distintas a nosotros, para los siguientes fines:
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Destinatario de los datos personales </th>
                <th>País(opcional) </th>
                <th>Finalidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CKN de México, S.A de C.V </td>
                <td>México</td>
                <td>Mercadotecnia, servicios y calidad. </td>
              </tr>
            </tbody>
          </Table>
          <p>
            Le informamos que para las transferencias indicadas con un asterisco
            (*) requerimos obtener su consentimiento. Si usted no manifiesta su
            negativa para dichas transferencias, entenderemos que nos lo ha
            otorgado. Ésto sólo aplica para consentimiento tácito.
          </p>
          <h4>
            ¿CÓMO PUEDE ACCEDER, RECTIFICAR O CANCELAR SUS DATOS PERSONALES, U
            OPONERSE A SU USO?{" "}
          </h4>
          <p>
            Usted tiene derecho a conocer qué datos personales tenemos de usted,
            para qué los utilizamos y las condiciones del uso que les damos
            (Acceso). Asimismo, es su derecho solicitar la corrección de su
            información personal en caso de que esté desactualizada, sea
            inexacta o incompleta (Rectificación); que la eliminemos de nuestros
            registros o bases de datos cuando considere que la misma no está
            siendo utilizada conforme a los principios, deberes y obligaciones
            previstas en la normativa (Cancelación); así como oponerse al uso de
            sus datos personales para fines específicos (Oposición). Estos
            derechos se conocen como derechos ARCO. Para el ejercicio de
            cualquiera de los derechos ARCO, usted deberá presentar la solicitud
            respectiva en nuestras oficinas, vía telefónica o vía correo
            institucional. Para conocer el procedimiento y requisitos para el
            ejercicio de los derechos ARCO, usted podrá llamar al siguiente
            número telefónico 5536402250; ingresar a nuestro sitio de
            Internet https://www.dammannmexique.com, o bien ponerse en contacto
            con nuestro Departamento de Privacidad, que dará trámite a las
            solicitudes para el ejercicio de estos derechos, y atenderá
            cualquier duda que pudiera tener respecto al tratamiento de su
            información. Los datos de contacto del Departamento de Privacidad
            son los siguientes: Domicilio, teléfono y correo electrónico.
          </p>
          <h4>
            ¿CÓMO PUEDE REVOCAR SU CONSENTIMIENTO PARA EL USO DE SUS DATOS
            PERSONALES?{" "}
          </h4>
          <p>
            Usted puede revocar el consentimiento que, en su caso, nos haya
            otorgado para el tratamiento de sus datos personales. Sin embargo,
            es importante que tenga en cuenta que no en todos los casos podremos
            atender su solicitud o concluir el uso de forma inmediata, ya que es
            posible que por alguna obligación legal requiramos seguir tratando
            sus datos personales. Asimismo, usted deberá considerar que para
            ciertos fines, la revocación de su consentimiento implicará que no
            le podamos seguir prestando el servicio que nos solicitó, o la
            conclusión de su relación con nosotros. Para revocar su
            consentimiento deberá presentar su solicitud en nuestras oficinas o
            por escrito en correo postal o correo electrónico
            institucional. Para conocer el procedimiento y requisitos para la
            revocación del consentimiento, usted podrá llamar al siguiente
            número telefónico 5536402250; ingresar a nuestro sitio de Internet
            https://www.dammannmexique.com bien ponerse en contacto con nuestro
            Departamento de Privacidad.
          </p>
          <h4>
            ¿CÓMO PUEDE LIMITAR EL USO O DIVULGACIÓN DE SU INFORMACIÓN PERSONAL?{" "}
          </h4>
          <p>
            Con objeto de que usted pueda limitar el uso y divulgación de su
            información personal, le ofrecemos los siguientes medios:
          </p>
          <ul>
            <li>
              Su inscripción en el Registro Público para Evitar Publicidad, que
              está a cargo de la Procuraduría Federal del Consumidor, con la
              finalidad de que sus datos personales no sean utilizados para
              recibir publicidad o promociones de empresas de bienes o
              servicios. Para mayor información sobre este registro, usted puede
              consultar el portal de Internet de la PROFECO, o bien ponerse en
              contacto directo con ésta.{" "}
            </li>
            <li>
              Su registro puede integrarse en el listado de exclusión, a fin de
              que sus datos personales no sean tratados para fines
              mercadotécnicos, publicitarios o de prospección comercial por
              nuestra parte. Para mayor información llamar al número telefónico
              5536402250 enviar un correo electrónico a la siguiente dirección{" "}
              <a href="mailto:info@dammannmexique.com">
                info@dammannmexique.com
              </a>{" "}
              o bien, consultar nuestra página de Internet
              https://www.dammannmexique.com{" "}
            </li>
          </ul>
          <h4>
            EL USO DE TECNOLOGÍAS DE RASTREO EN NUESTRO PORTAL DE INTERNET{" "}
          </h4>
          <p>
            Le informamos que en nuestra página de Internet utilizamos cookies,
            web beacons y otras tecnologías a través de las cuales es posible
            monitorear su comportamiento como usuario de Internet, así como
            brindarle un mejor servicio y experiencia de usuario al navegar en
            nuestra página. Los datos personales que obtenemos de estas
            tecnologías de rastreo son los siguientes: dirección electrónica,
            mismos que utilizamos para informar sobre nuestro productos,
            servicios y promociones. Asimismo, le informamos que compartiremos
            estos datos con:
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Destinatario de los datos personales </th>
                <th>País(opcional) </th>
                <th>Finalidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CKN de México, S.A de C.V </td>
                <td>México</td>
                <td>Mercadotecnia, servicios y calidad. </td>
              </tr>
            </tbody>
          </Table>
          <p>
            Estas tecnologías podrán deshabilitarse siguiendo los siguientes
            pasos: negar el uso de cookies o borrándolas desde su navegador. 
          </p>
          <h4>¿CÓMO PUEDE CONOCER LOS CAMBIOS A ESTE AVISO DE PRIVACIDAD? </h4>
          <p>
            El presente aviso de privacidad puede sufrir modificaciones, cambios
            o actualizaciones derivadas de nuevos requerimientos legales; de
            nuestras propias necesidades por los productos o servicios que
            ofrecemos; de nuestras prácticas de privacidad; de cambios en
            nuestro modelo de negocio, o por otras causas. Nos comprometemos a
            mantenerlo informado sobre los cambios que pueda sufrir el presente
            aviso de privacidad, a través de nuestra página internet o
            solicitándola por correo electrónico al personal autorizado de
            nuestra institución. El procedimiento a través del cual se llevarán
            a cabo las notificaciones sobre cambios o actualizaciones al
            presente aviso de privacidad es el siguiente: actualización de la
            página de internet, solicitud por correo electrónico o consultándola
            directamente en nuestras oficinas ubicadas en la dirección antes
            mencionada.{" "}
          </p>
        </Container>
      </div>
    </section>
  );
}

export default Cookies;
